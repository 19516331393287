var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "ivu-mt", attrs: { align: "middle", gutter: 20 } },
    _vm._l(_vm.cardLists, function (item, index) {
      return _c(
        "el-col",
        {
          key: index,
          staticClass: "ivu-mb mb20",
          attrs: { xl: 6, lg: 6, md: 12, sm: 12, xs: 24 },
        },
        [
          _c("div", { staticClass: "card_box" }, [
            _c("div", { staticClass: "card_box_cir", class: item.class }, [
              item.icon
                ? _c("span", {
                    staticClass: "iconfont",
                    class: item.icon,
                    style: { color: item.color },
                  })
                : _c("i", {
                    staticClass: "el-icon-edit",
                    staticStyle: { color: "#fff" },
                  }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card_box_txt" }, [
              _c("span", {
                staticClass: "sp2",
                domProps: { textContent: _vm._s(item.name) },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sp1" }, [
                _vm._v(
                  " \n          " +
                    _vm._s(
                      parseFloat("" + item.count)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }