var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "divBox" },
      [
        _c(
          "el-row",
          { staticClass: "baseInfo", attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
              [
                _c(
                  "el-card",
                  { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                  [
                    _c("div", { staticClass: "acea-row row-between-wrapper" }, [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("div", { staticClass: "main_badge" }, [
                          _c("span", { staticClass: "iconfont iconxiaoshoue" }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v("订单总数"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "span",
                        { staticClass: "content-number spBlock my15" },
                        [_vm._v(_vm._s(_vm.orderCount))]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
              [
                _c(
                  "el-card",
                  { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                  [
                    _c("div", { staticClass: "acea-row row-between-wrapper" }, [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("div", { staticClass: "main_badge" }, [
                          _c("span", {
                            staticClass: "iconfont iconxinzengyonghu",
                          }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v("当前在线人数"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "span",
                        { staticClass: "content-number spBlock my15" },
                        [_vm._v(_vm._s(_vm.userOnLineCount))]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
              [
                _c(
                  "el-card",
                  { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                  [
                    _c("div", { staticClass: "acea-row row-between-wrapper" }, [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("div", { staticClass: "main_badge" }, [
                          _c("span", {
                            staticClass: "iconfont iconxinzengyonghu",
                          }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v("日活跃人数"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "span",
                        { staticClass: "content-number spBlock my15" },
                        [_vm._v(_vm._s(_vm.dayUserCount))]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
              [
                _c(
                  "el-card",
                  { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                  [
                    _c("div", { staticClass: "acea-row row-between-wrapper" }, [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("div", { staticClass: "main_badge" }, [
                          _c("span", {
                            staticClass: "iconfont iconxinzengyonghu",
                          }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v("总人数"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "span",
                        { staticClass: "content-number spBlock my15" },
                        [_vm._v(_vm._s(_vm.userCount))]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "divBox" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { san: "24" } },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "dashboard-console-visit",
                    attrs: { bordered: false },
                  },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c(
                        "div",
                        { staticClass: "acea-row row-between-wrapper" },
                        [
                          _c("div", { staticClass: "acea-row row-middle" }, [
                            _c("div", { staticClass: "header_title" }, [
                              _vm._v("新增订单统计"),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("echarts-from", {
                      ref: "visitChart",
                      attrs: {
                        yAxisData: _vm.yAxisData,
                        seriesData: _vm.series,
                        xAxis: _vm.xAxis,
                        legendData: _vm.legendData,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "divBox" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { staticClass: "ivu-mb mb10 dashboard-console-visit" },
              [
                _c(
                  "el-card",
                  { attrs: { bordered: false, "dis-hover": "" } },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("div", { staticClass: "acea-row row-middle" }, [
                        _c("div", { staticClass: "header_title" }, [
                          _vm._v("新增用户统计"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("echarts-from", {
                      ref: "userChart",
                      attrs: {
                        echartsTitle: _vm.line,
                        xAxis: _vm.userXAxis,
                        seriesData: _vm.userSeries,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }