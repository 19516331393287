var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "from",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.from,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "账户/用户名：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入账户/用户名",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.from.userName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "userName", $$v)
                                      },
                                      expression: "from.userName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "合约：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.from.contractId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.from, "contractId", $$v)
                                        },
                                        expression: "from.contractId",
                                      },
                                    },
                                    _vm._l(_vm.options, function (item) {
                                      return _c("el-option", {
                                        key: item.contractName,
                                        attrs: {
                                          label: item.contractName,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "账户类型：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth w_220",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.from.accountType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.from, "accountType", $$v)
                                        },
                                        expression: "from.accountType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.accountTypeDict,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.dictCode,
                                          attrs: {
                                            label: item.dictLabel,
                                            value: item.dictValue,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "状态：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择类型",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.from.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.from, "status", $$v)
                                        },
                                        expression: "from.status",
                                      },
                                    },
                                    _vm._l(_vm.tradeOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.dictValue,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.dictValue,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-right from",
                              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: { size: "small", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.search()
                                        },
                                      },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset()
                                        },
                                      },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn_bt" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.cancelTradeBtns },
                    },
                    [_vm._v("一键撤单")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
              on: { select: _vm.selctTable, "select-all": _vm.slectTableAll },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  align: "center",
                  label: "全选",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "dataFactoryName",
                  label: "数据场",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "accountType",
                  label: "账户类型",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.selectDictType(scope.row.accountType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: "账户/用户名",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "orderId",
                  label: "委托单号",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "contractName",
                  label: "合约名称",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "symbol", label: "合约", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "direction", label: "方向", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.direction == 1
                          ? _c("span", { staticClass: "r" }, [_vm._v("买")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.direction == 2
                          ? _c("span", { staticClass: "g" }, [_vm._v("卖")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "volume",
                  label: "委托数量",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "dealVolume",
                  label: "成交数量",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "添加时间",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.selectDictType2(scope.row.status))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "0" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              disabled: scope.row.status == 0 ? false : true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.cancelTradeBtn(scope.row)
                              },
                            },
                          },
                          [_vm._v("撤单")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.from.limit,
                  "current-page": _vm.from.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }