import Layout from '@/layout';
var agentRouter = {
  path: '/agent',
  component: Layout,
  redirect: '/agent',
  name: 'Agent',
  meta: {
    title: '代理管理',
    icon: 's-check',
    roles: ['admin']
  },
  children: [{
    path: 'warehouse',
    name: 'warehouse',
    component: function component() {
      return import('@/views/agent/warehouse/index');
    },
    meta: {
      title: '正常补仓模板'
    }
  }, {
    path: 'commission',
    name: 'commission',
    component: function component() {
      return import('@/views/agent/commission/index');
    },
    meta: {
      title: '服务费模板'
    }
  }, {
    path: 'risk',
    name: 'risk',
    component: function component() {
      return import('@/views/agent/risk/index');
    },
    meta: {
      title: '风控指标'
    }
  }, {
    path: 'commodityrisk',
    name: 'commodityrisk',
    component: function component() {
      return import('@/views/agent/commodityrisk/index');
    },
    meta: {
      title: '定时强平'
    }
  }, {
    path: 'holidaystemplate',
    name: 'holidaystemplate',
    component: function component() {
      return import('@/views/agent/holidaystemplate/index');
    },
    meta: {
      title: '节假日模板'
    }
  }, {
    path: 'brokeragetemplate',
    name: 'brokeragetemplate',
    component: function component() {
      return import('@/views/agent/brokeragetemplate/index');
    },
    meta: {
      title: '佣金模板'
    }
  }, {
    path: 'traderconfigtemplate',
    name: 'traderconfigtemplate',
    component: function component() {
      return import('@/views/agent/traderconfigtemplate/index');
    },
    meta: {
      title: '交易员综合配置模版'
    }
  }, {
    path: 'emailtemplate',
    name: 'emailtemplate',
    component: function component() {
      return import('@/views/agent/emailtemplate/index');
    },
    meta: {
      title: '邮件模版'
    }
  }, {
    path: 'noticetemplate',
    name: 'noticetemplate',
    component: function component() {
      return import('@/views/agent/noticetemplate/index');
    },
    meta: {
      title: '通知模版'
    }
  }, {
    path: 'smstemplate',
    name: 'smstemplate',
    component: function component() {
      return import('@/views/agent/smstemplate/index');
    },
    meta: {
      title: '短信模版'
    }
  }]
};
export default agentRouter;