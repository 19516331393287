//
//
//
//
//
//

export default {
  name: 'App',
  computed: {
    routePath: function routePath() {
      return this.$route.path;
    }
  },
  watch: {
    routePath: function routePath(newVal) {
      if (newVal == '/login') {
        window.parent.postMessage({
          action: 'SET_LOGIN_SCREEN'
        }, '*');
      } else {
        window.parent.postMessage({
          action: 'SET_FULL_SCREEN'
        }, '*');
      }
    }
  }
};