var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "userFrom",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.userFrom,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 20, xl: 20 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "数据场：" } },
                                [
                                  _c("UserSearchable", {
                                    staticClass: "w_220",
                                    attrs: { type: "datafieldList" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "dropdownTitle",
                                        fn: function () {
                                          return [
                                            _c("span", [_vm._v("数据场名称")]),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("负责人")]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "dropdown",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(item.name)),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(item.principal)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.userFrom.dataFactoryId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userFrom,
                                          "dataFactoryId",
                                          $$v
                                        )
                                      },
                                      expression: "userFrom.dataFactoryId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "推荐人：" } },
                                [
                                  _c("UserSearchable", {
                                    model: {
                                      value: _vm.userFrom.spreadUid,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userFrom, "spreadUid", $$v)
                                      },
                                      expression: "userFrom.spreadUid",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户搜索：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth w-235px",
                                    attrs: {
                                      placeholder:
                                        "请输入ID或姓名或手机号或邮箱",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.userFrom.keywords,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userFrom, "keywords", $$v)
                                      },
                                      expression: "userFrom.keywords",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户分组：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.userFrom.groupId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userFrom, "groupId", $$v)
                                        },
                                        expression: "userFrom.groupId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.groupList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            value: item.id,
                                            label: item.groupName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户标签：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                        filterable: "",
                                        multiple: "",
                                      },
                                      model: {
                                        value: _vm.labelData,
                                        callback: function ($$v) {
                                          _vm.labelData = $$v
                                        },
                                        expression: "labelData",
                                      },
                                    },
                                    _vm._l(
                                      _vm.labelLists,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            value: item.id,
                                            label: item.name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-right userFrom",
                              attrs: { xs: 24, sm: 24, md: 24, lg: 4, xl: 4 },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15 color_blue",
                                      attrs: {
                                        type: "primary",
                                        icon: "ios-search",
                                        label: "default",
                                        size: "mini",
                                      },
                                      on: { click: _vm.userSearchs },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-search mar6",
                                      }),
                                      _vm._v("搜索"),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "ResetSearch mr10 hover_button",
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset("userFrom")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-refresh mar6",
                                      }),
                                      _vm._v("重置"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn_color_blue",
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.allBlack },
                        },
                        [_vm._v("拉黑")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "row-style": _vm.rowStyle,
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.onSelectTab },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c("el-form-item", { attrs: { label: "身份：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterIsPromoter")(
                                      props.row.isPromoter
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "首次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.createTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "近次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.lastLoginTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "手机号：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.phone)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "分组：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.groupName)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "标签：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.tagName)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "地址：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.addres)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-right": "10px",
                                },
                                attrs: { label: "备注：" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.mark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "uid", label: "ID", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.avatar,
                                "preview-src-list": [scope.row.avatar],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "昵称", "min-width": "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.nickname))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "dataFactoryName",
                  label: "数据场",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "spreadNickname",
                  label: "推荐人",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "spreadUid",
                  label: "推荐人ID",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.phone))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "eMail", label: "邮箱", "min-width": "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "spreadCode",
                  label: "推荐码",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "walletAddress",
                  label: "钱包地址",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "blackList",
                  label: "是否黑名单",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.blackList ? "是" : "否")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "100",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n              更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.checkPermi(["admin:user:topdetail"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onDetails(scope.row.uid)
                                          },
                                        },
                                      },
                                      [_vm._v("账户详情")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:group"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setBatch(
                                              "group",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("设置分组")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:tag"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setBatch(
                                              "label",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("设置标签")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:update:nickname"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setNickName(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改昵称\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:update:phone"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setPhone(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改手机号\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:update:blacklist"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setBlack(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("加入黑名单\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:update:spread"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setExtension(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改上级推广人")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 100, 1000, _vm.tableData.total],
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改推广人",
            visible: _vm.extensionVisible,
            width: "500px",
            "before-close": _vm.handleCloseExtension,
          },
          on: {
            "update:visible": function ($event) {
              _vm.extensionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formExtension",
              staticClass: "formExtension mt20",
              attrs: {
                model: _vm.formExtension,
                rules: _vm.ruleInline,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "推广人：", prop: "spreadUid" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择用户",
                        filterable: "",
                        remote: "",
                        clearable: "",
                        "remote-method": _vm.loadUsers,
                      },
                      model: {
                        value: _vm.formExtension.spreadUid,
                        callback: function ($$v) {
                          _vm.$set(_vm.formExtension, "spreadUid", $$v)
                        },
                        expression: "formExtension.spreadUid",
                      },
                    },
                    _vm._l(_vm.users, function (item) {
                      return _c("el-option", {
                        key: item.uid,
                        attrs: {
                          label:
                            item.uid + "/" + item.nickname + "/" + item.email,
                          value: item.uid,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "迁移类型：", prop: "transferType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formExtension.transferType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formExtension, "transferType", $$v)
                        },
                        expression: "formExtension.transferType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("普通迁移"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("完整迁移"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "bg_c",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubExtension("formExtension")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.userVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("user-list", { on: { getTemplateRow: _vm.getTemplateRow } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "dynamicValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.dynamicValidateForm, "label-width": "100px" },
            },
            [
              _vm.batchName === "group"
                ? _c(
                    "el-form-item",
                    {
                      key: "1",
                      attrs: {
                        prop: "groupId",
                        label: "用户分组",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户分组",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请选择分组", filterable: "" },
                          model: {
                            value: _vm.dynamicValidateForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                            },
                            expression: "dynamicValidateForm.groupId",
                          },
                        },
                        _vm._l(_vm.groupList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.groupName },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "groupId",
                        label: "用户标签",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户标签",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: {
                            filterable: "",
                            "allow-create": "",
                            multiple: "",
                            placeholder: "请选择标签",
                          },
                          model: {
                            value: _vm.dynamicValidateForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                            },
                            expression: "dynamicValidateForm.groupId",
                          },
                        },
                        _vm._l(_vm.labelLists, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("dynamicValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.visible, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("edit-from", {
                attrs: { uid: _vm.uid },
                on: { resetForm: _vm.resetForm },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "积分余额",
            visible: _vm.VisiblePoint,
            width: "500px",
            "close-on-click-modal": false,
            "before-close": _vm.handlePointClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.VisiblePoint = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingPoint,
                  expression: "loadingPoint",
                },
              ],
              ref: "PointValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.PointValidateForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "修改余额", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.PointValidateForm.moneyType,
                        callback: function ($$v) {
                          _vm.$set(_vm.PointValidateForm, "moneyType", $$v)
                        },
                        expression: "PointValidateForm.moneyType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "余额", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      precision: 2,
                      step: 0.1,
                      min: 0,
                      max: 999999,
                    },
                    model: {
                      value: _vm.PointValidateForm.moneyValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.PointValidateForm, "moneyValue", $$v)
                      },
                      expression: "PointValidateForm.moneyValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "修改积分", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.PointValidateForm.integralType,
                        callback: function ($$v) {
                          _vm.$set(_vm.PointValidateForm, "integralType", $$v)
                        },
                        expression: "PointValidateForm.integralType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "积分", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      "step-strictly": "",
                      min: 0,
                      max: 999999,
                    },
                    model: {
                      value: _vm.PointValidateForm.integralValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.PointValidateForm, "integralValue", $$v)
                      },
                      expression: "PointValidateForm.integralValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlePointClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.submitPointForm("PointValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.uid
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "用户详情",
                visible: _vm.Visible,
                width: "60%",
                "before-close": _vm.Close,
                top: "5vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.Visible = $event
                },
              },
            },
            [
              _vm.Visible
                ? _c("user-details", {
                    ref: "userDetails",
                    attrs: { uid: _vm.uid },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.levelVisible,
            width: "600px",
            "before-close": _vm.Close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.levelVisible = $event
            },
          },
        },
        [
          _c("level-edit", {
            attrs: { levelInfo: _vm.levelInfo, levelList: _vm.levelList },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }