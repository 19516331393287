//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import userList from '@/components/userList';
import UserSearchable from "@/components/UserSearchable";
import { userListApi, userblacklistList, userblacklistSave, userblacklistDelete } from '@/api/user';
export default {
  name: 'trader',
  components: {
    userList: userList,
    UserSearchable: UserSearchable
  },
  data: function data() {
    return {
      labelPosition: 'right',
      userVisible: false,
      from: {
        userId: '',
        keywords: "",
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      selection: [],
      dialogVisible: false,
      ruleForm: {
        userId: '',
        remark: ''
      },
      rules: {
        userId: [{
          required: true,
          message: '请选择用户',
          trigger: 'change'
        }],
        remark: [{
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getlist();
  },
  methods: {
    selectUser: function selectUser() {
      this.userVisible = true;
    },
    getTemplateRow: function getTemplateRow(row) {
      this.ruleForm.userId = row.uid;
      this.from.userId = row.uid;
    },
    userclose: function userclose() {
      this.ruleForm.userId = '';
      this.from.userId = '';
      this.userVisible = false;
    },
    usersubmit: function usersubmit() {
      this.userVisible = false;
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        userId: '',
        keywords: "",
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this = this;
      this.loading = true;
      userblacklistList(this.from).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this.loading = false;
      });
    },
    add: function add() {
      this.ruleForm = {
        userId: '',
        remark: ''
      };
      this.dialogVisible = true;
    },
    close: function close() {
      this.ruleForm = {
        userId: '',
        remark: ''
      };
      this.from.userId = '';
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          console.log('新增', _this2.ruleForm);
          userblacklistSave(_this2.ruleForm).then(function (res) {
            console.log(res);
            _this2.close();
            _this2.$message.success('新增成功');
            _this2.getlist();
          }).catch(function (err) {
            console.log(err);
            _this2.$message.error(err);
          });
        } else {
          _this2.$message.error('请填写完整信息');
        }
      });
    },
    // 选择
    onSelectTab: function onSelectTab(selection) {
      var _this3 = this;
      this.selection = [];
      selection.forEach(function (item) {
        _this3.selection.push(item.id);
      });
      console.log(this.selection);
    },
    allDel: function allDel() {
      var _this4 = this;
      if (this.selection.length == 0) {
        return this.$message.error('至少选择一项');
      }
      console.log(this.selection);
      this.$confirm('确认删除？', '提示').then(function (_) {
        userblacklistDelete(_this4.selection).then(function (res) {
          console.log(res);
          _this4.$message.success('删除成功');
          _this4.getlist();
        }).catch(function (err) {
          console.log(err);
          _this4.$message.error(err);
        });
      }).catch(function (_) {});
    },
    handleDel: function handleDel(row) {
      var _this5 = this;
      this.$confirm('确认删除？', '提示').then(function (_) {
        console.log('删除', row);
        userblacklistDelete([row.id]).then(function (res) {
          console.log(res);
          _this5.$message.success('删除成功');
          _this5.getlist();
        }).catch(function (err) {
          console.log(err);
          _this5.$message.error(err);
        });
      }).catch(function (_) {});
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};