"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.brokeragetemplateDelete = brokeragetemplateDelete;
exports.brokeragetemplateExport = brokeragetemplateExport;
exports.brokeragetemplateGetOption = brokeragetemplateGetOption;
exports.brokeragetemplateInfo = brokeragetemplateInfo;
exports.brokeragetemplateList = brokeragetemplateList;
exports.brokeragetemplateSave = brokeragetemplateSave;
exports.brokeragetemplateUpdate = brokeragetemplateUpdate;
exports.commodityriskDelete = commodityriskDelete;
exports.commodityriskExport = commodityriskExport;
exports.commodityriskInfo = commodityriskInfo;
exports.commodityriskList = commodityriskList;
exports.commodityriskSave = commodityriskSave;
exports.commodityriskUpdate = commodityriskUpdate;
exports.deleteEmailtemplate = deleteEmailtemplate;
exports.deleteNormalfilltemplate = deleteNormalfilltemplate;
exports.deleteNoticetemplate = deleteNoticetemplate;
exports.deleteSmstemplate = deleteSmstemplate;
exports.detailNormalfilltemplate = detailNormalfilltemplate;
exports.exchangecommodityGetAll = exchangecommodityGetAll;
exports.exchangefeetemplateDelete = exchangefeetemplateDelete;
exports.exchangefeetemplateExport = exchangefeetemplateExport;
exports.exchangefeetemplateGetOption = exchangefeetemplateGetOption;
exports.exchangefeetemplateInfo = exchangefeetemplateInfo;
exports.exchangefeetemplateList = exchangefeetemplateList;
exports.exchangefeetemplateSave = exchangefeetemplateSave;
exports.exchangefeetemplateUpdate = exchangefeetemplateUpdate;
exports.getEmailtemplate = getEmailtemplate;
exports.getNormalfilltemplate = getNormalfilltemplate;
exports.getNoticetemplate = getNoticetemplate;
exports.getOptionNormalfilltemplate = getOptionNormalfilltemplate;
exports.getSmstemplate = getSmstemplate;
exports.holidaystemplateDelete = holidaystemplateDelete;
exports.holidaystemplateExport = holidaystemplateExport;
exports.holidaystemplateGetOption = holidaystemplateGetOption;
exports.holidaystemplateInfo = holidaystemplateInfo;
exports.holidaystemplateList = holidaystemplateList;
exports.holidaystemplateSave = holidaystemplateSave;
exports.holidaystemplateUpdate = holidaystemplateUpdate;
exports.refreshCommodityRiskCache = refreshCommodityRiskCache;
exports.refreshFeeCache = refreshFeeCache;
exports.refreshHolidaysTemplateCache = refreshHolidaysTemplateCache;
exports.refreshTraderRiskTemplateCache = refreshTraderRiskTemplateCache;
exports.saveEmailtemplate = saveEmailtemplate;
exports.saveNormalfilltemplate = saveNormalfilltemplate;
exports.saveNoticetemplate = saveNoticetemplate;
exports.saveSmstemplate = saveSmstemplate;
exports.traderconfigtemplateDelete = traderconfigtemplateDelete;
exports.traderconfigtemplateExport = traderconfigtemplateExport;
exports.traderconfigtemplateGetOption = traderconfigtemplateGetOption;
exports.traderconfigtemplateInfo = traderconfigtemplateInfo;
exports.traderconfigtemplateList = traderconfigtemplateList;
exports.traderconfigtemplateResetCache = traderconfigtemplateResetCache;
exports.traderconfigtemplateSave = traderconfigtemplateSave;
exports.traderconfigtemplateUpdate = traderconfigtemplateUpdate;
exports.traderrisktemplateDelete = traderrisktemplateDelete;
exports.traderrisktemplateExport = traderrisktemplateExport;
exports.traderrisktemplateGetOption = traderrisktemplateGetOption;
exports.traderrisktemplateInfo = traderrisktemplateInfo;
exports.traderrisktemplateList = traderrisktemplateList;
exports.traderrisktemplateSave = traderrisktemplateSave;
exports.traderrisktemplateUpdate = traderrisktemplateUpdate;
exports.updateEmailtemplate = updateEmailtemplate;
exports.updateNormalfilltemplate = updateNormalfilltemplate;
exports.updateNoticetemplate = updateNoticetemplate;
exports.updateSmstemplate = updateSmstemplate;
exports.yj_brokerage_member_type = yj_brokerage_member_type;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// 手续费模板
/*
 * 品种手续费全列表
 */
function exchangecommodityGetAll(id) {
  return (0, _request.default)({
    url: "/exchangecommodity/getAll/".concat(id),
    method: 'post'
  });
}
/*
 * 交易员手续费模板全列表
 */
function exchangefeetemplateGetOption(data) {
  return (0, _request.default)({
    url: '/exchangefeetemplate/getOption',
    method: 'post',
    data: data
  });
}
/*
 * 手续费模板列表
 */
function exchangefeetemplateList(params) {
  return (0, _request.default)({
    url: '/exchangefeetemplate/list',
    method: 'get',
    params: params
  });
}
/*
 * 手续费模板新增
 */
function exchangefeetemplateSave(data) {
  return (0, _request.default)({
    url: '/exchangefeetemplate/save',
    method: 'post',
    data: data
  });
}
/*
 * 手续费模板详情
 */
function exchangefeetemplateInfo(id) {
  return (0, _request.default)({
    url: "/exchangefeetemplate/info/".concat(id),
    method: 'post'
  });
}
/*
 * 手续费模板编辑
 */
function exchangefeetemplateUpdate(data) {
  return (0, _request.default)({
    url: '/exchangefeetemplate/update',
    method: 'post',
    data: data
  });
}
/*
 * 手续费模板删除
 */
function exchangefeetemplateDelete(data) {
  return (0, _request.default)({
    url: '/exchangefeetemplate/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出手续费模板
 */
function exchangefeetemplateExport(params) {
  return (0, _request.default)({
    url: '/exchangefeetemplate/export',
    method: 'get',
    params: params
  });
}

// 风控模板
/*
 * 交易员风控模板全列表
 */
function traderrisktemplateGetOption(data) {
  return (0, _request.default)({
    url: '/traderrisktemplate/getOption',
    method: 'post',
    data: data
  });
}
/*
 * 风控模板列表
 */
function traderrisktemplateList(params) {
  return (0, _request.default)({
    url: '/traderrisktemplate/list',
    method: 'get',
    params: params
  });
}
/*
 * 风控模板新增
 */
function traderrisktemplateSave(data) {
  return (0, _request.default)({
    url: '/traderrisktemplate/save',
    method: 'post',
    data: data
  });
}
/*
 * 风控模板详情
 */
function traderrisktemplateInfo(id) {
  return (0, _request.default)({
    url: "/traderrisktemplate/info/".concat(id),
    method: 'post'
  });
}
/*
 * 风控模板编辑
 */
function traderrisktemplateUpdate(data) {
  return (0, _request.default)({
    url: '/traderrisktemplate/update',
    method: 'post',
    data: data
  });
}
/*
 * 风控模板删除
 */
function traderrisktemplateDelete(data) {
  return (0, _request.default)({
    url: '/traderrisktemplate/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出风控模板
 */
function traderrisktemplateExport(params) {
  return (0, _request.default)({
    url: '/traderrisktemplate/export',
    method: 'get',
    params: params
  });
}

// 定时强平
/*
 * 定时强平列表
 */
function commodityriskList(params) {
  return (0, _request.default)({
    url: '/commodityrisk/list',
    method: 'get',
    params: params
  });
}
/*
 * 定时强平新增
 */
function commodityriskSave(data) {
  return (0, _request.default)({
    url: '/commodityrisk/save',
    method: 'post',
    data: data
  });
}
/*
 * 定时强平详情
 */
function commodityriskInfo(id) {
  return (0, _request.default)({
    url: "/commodityrisk/info/".concat(id),
    method: 'post'
  });
}
/*
 * 定时强平编辑
 */
function commodityriskUpdate(data) {
  return (0, _request.default)({
    url: '/commodityrisk/update',
    method: 'post',
    data: data
  });
}
/*
 * 定时强平删除
 */
function commodityriskDelete(data) {
  return (0, _request.default)({
    url: '/commodityrisk/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出定时强平
 */
function commodityriskExport(params) {
  return (0, _request.default)({
    url: '/commodityrisk/export',
    method: 'get',
    params: params
  });
}

// 节假日模板管理
/*
 * 节假日模板管理全列表
 */
function holidaystemplateGetOption(params) {
  return (0, _request.default)({
    url: '/holidaystemplate/getOption',
    method: 'get',
    params: params
  });
}
/*
 * 节假日模板管理列表
 */
function holidaystemplateList(params) {
  return (0, _request.default)({
    url: '/holidaystemplate/list',
    method: 'get',
    params: params
  });
}
/*
 * 节假日模板管理新增
 */
function holidaystemplateSave(data) {
  return (0, _request.default)({
    url: '/holidaystemplate/save',
    method: 'post',
    data: data
  });
}
/*
 * 节假日模板管理详情
 */
function holidaystemplateInfo(id) {
  return (0, _request.default)({
    url: "/holidaystemplate/info/".concat(id),
    method: 'post'
  });
}
/*
 * 节假日模板管理编辑
 */
function holidaystemplateUpdate(data) {
  return (0, _request.default)({
    url: '/holidaystemplate/update',
    method: 'post',
    data: data
  });
}
/*
 * 节假日模板管理删除
 */
function holidaystemplateDelete(data) {
  return (0, _request.default)({
    url: '/holidaystemplate/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出节假日模板管理
 */
function holidaystemplateExport(params) {
  return (0, _request.default)({
    url: '/holidaystemplate/export',
    method: 'get',
    params: params
  });
}

// 佣金模板管理
/*
 * 佣金模板添加级别列表
 */
function yj_brokerage_member_type(params) {
  return (0, _request.default)({
    url: '/sys-dict-data/type/yj_brokerage_member_type',
    method: 'get',
    params: params
  });
}
/*
 * 佣金模板全列表
 */
function brokeragetemplateGetOption(data) {
  return (0, _request.default)({
    url: '/brokeragetemplate/getOption',
    method: 'post',
    data: data
  });
}
/*
 * 佣金模板列表
 */
function brokeragetemplateList(params) {
  return (0, _request.default)({
    url: '/brokeragetemplate/list',
    method: 'get',
    params: params
  });
}
/*
 * 佣金模板新增
 */
function brokeragetemplateSave(data) {
  return (0, _request.default)({
    url: '/brokeragetemplate/save',
    method: 'post',
    data: data
  });
}
/*
 * 佣金模板详情
 */
function brokeragetemplateInfo(id) {
  return (0, _request.default)({
    url: "/brokeragetemplate/info/".concat(id),
    method: 'post'
  });
}
/*
 * 佣金模板编辑
 */
function brokeragetemplateUpdate(data) {
  return (0, _request.default)({
    url: '/brokeragetemplate/update',
    method: 'post',
    data: data
  });
}
/*
 * 佣金模板删除
 */
function brokeragetemplateDelete(data) {
  return (0, _request.default)({
    url: '/brokeragetemplate/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出佣金模板
 */
function brokeragetemplateExport(params) {
  return (0, _request.default)({
    url: '/brokeragetemplate/export',
    method: 'get',
    params: params
  });
}

// 交易员综合配置模版
/*
 * 交易员综合配置模版全列表
 */
function traderconfigtemplateGetOption(params) {
  return (0, _request.default)({
    url: '/traderconfigtemplate/getOption',
    method: 'get',
    params: params
  });
}
/*
 * 交易员综合配置模版列表
 */
function traderconfigtemplateList(params) {
  return (0, _request.default)({
    url: '/traderconfigtemplate/list',
    method: 'get',
    params: params
  });
}
/*
 * 交易员综合配置模版新增
 */
function traderconfigtemplateSave(data) {
  return (0, _request.default)({
    url: '/traderconfigtemplate/save',
    method: 'post',
    data: data
  });
}
/*
 * 交易员综合配置模版详情
 */
function traderconfigtemplateInfo(id) {
  return (0, _request.default)({
    url: "/traderconfigtemplate/info/".concat(id),
    method: 'post'
  });
}
/*
 * 交易员综合配置模版编辑
 */
function traderconfigtemplateUpdate(data) {
  return (0, _request.default)({
    url: '/traderconfigtemplate/update',
    method: 'post',
    data: data
  });
}
/*
 * 交易员综合配置模版删除
 */
function traderconfigtemplateDelete(data) {
  return (0, _request.default)({
    url: '/traderconfigtemplate/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出交易员综合配置模版
 */
function traderconfigtemplateExport(params) {
  return (0, _request.default)({
    url: '/traderconfigtemplate/export',
    method: 'get',
    params: params
  });
}

/*
 * 刷新缓存
 */
function traderconfigtemplateResetCache(data) {
  return (0, _request.default)({
    url: '/traderconfigtemplate/resetCache',
    method: 'post',
    data: data
  });
}

// 邮件配置模版
/*
 * 获取邮件
 */
function getEmailtemplate(params) {
  return (0, _request.default)({
    url: '/emailtemplate/list',
    method: 'get',
    params: params
  });
}
/*
 * 添加邮件
 */
function saveEmailtemplate(data) {
  return (0, _request.default)({
    url: '/emailtemplate/save',
    method: 'post',
    data: data
  });
}
/*
 * 修改邮件
 */
function updateEmailtemplate(data) {
  return (0, _request.default)({
    url: '/emailtemplate/update',
    method: 'post',
    data: data
  });
}
/*
 * 删除邮件
 */
function deleteEmailtemplate(data) {
  return (0, _request.default)({
    url: '/emailtemplate/delete',
    method: 'post',
    data: data
  });
}

// 通知配置模版
/*
 * 获取通知
 */
function getNoticetemplate(params) {
  return (0, _request.default)({
    url: '/announcementtemplate/list',
    method: 'get',
    params: params
  });
}
/*
 * 添加通知
 */
function saveNoticetemplate(data) {
  return (0, _request.default)({
    url: '/announcementtemplate/save',
    method: 'post',
    data: data
  });
}
/*
 * 修改通知
 */
function updateNoticetemplate(data) {
  return (0, _request.default)({
    url: '/announcementtemplate/update',
    method: 'post',
    data: data
  });
}
/*
 * 删除通知
 */
function deleteNoticetemplate(data) {
  return (0, _request.default)({
    url: '/announcementtemplate/delete',
    method: 'post',
    data: data
  });
}
// 短信模版
/*
 * 短信模版列表
 */
function getSmstemplate(params) {
  return (0, _request.default)({
    url: '/smstemplate/list',
    method: 'get',
    params: params
  });
}
/*
 * 新增短信模版
 */
function saveSmstemplate(data) {
  return (0, _request.default)({
    url: '/smstemplate/save',
    method: 'post',
    data: data
  });
}
/*
 * 编辑短信模版
 */
function updateSmstemplate(data) {
  return (0, _request.default)({
    url: '/smstemplate/update',
    method: 'post',
    data: data
  });
}
/*
 * 删除短信模版
 */
function deleteSmstemplate(data) {
  return (0, _request.default)({
    url: '/smstemplate/delete',
    method: 'post',
    data: data
  });
}

/*
 * 补仓模版列表
 */
function getNormalfilltemplate(params) {
  return (0, _request.default)({
    url: '/normalfilltemplate/list',
    method: 'GET',
    params: params
  });
}
/*
 * 添加补仓模版列表
 */
function saveNormalfilltemplate(data) {
  return (0, _request.default)({
    url: '/normalfilltemplate/save',
    method: 'post',
    data: data
  });
}
/*
 * 修改补仓模版列表
 */
function updateNormalfilltemplate(data) {
  return (0, _request.default)({
    url: '/normalfilltemplate/update',
    method: 'post',
    data: data
  });
}
/*
 * 补仓详情模版
 */
function detailNormalfilltemplate(id) {
  return (0, _request.default)({
    url: "/normalfilltemplate/info/".concat(id),
    method: 'post'
  });
}
/*
 * 删除补仓模版
 */
function deleteNormalfilltemplate(data) {
  return (0, _request.default)({
    url: '/normalfilltemplate/delete',
    method: 'post',
    data: data
  });
}
/*
 * 补仓模版列表框
 */
function getOptionNormalfilltemplate(params) {
  return (0, _request.default)({
    url: '/normalfilltemplate/getOption',
    method: 'get',
    params: params
  });
}

/*
 * 刷新缓存(服务费模板)
 */
function refreshFeeCache(id) {
  return (0, _request.default)({
    url: "/exchangefeetemplate/resetFeeCache/".concat(id),
    method: 'post'
  });
}

/*
 * 刷新缓存(节假日模板)
 */
function refreshHolidaysTemplateCache(id) {
  return (0, _request.default)({
    url: "/holidaystemplate/resetHolidaysTemplateCache/".concat(id),
    method: 'post'
  });
}

/*
 * 刷新缓存(品种强平模板)
 */
function refreshCommodityRiskCache() {
  return (0, _request.default)({
    url: "/commodityrisk/resetCommodityRiskCache",
    method: 'post'
  });
}

/*
 * 刷新缓存(风控指标模板)
 */
function refreshTraderRiskTemplateCache(id) {
  return (0, _request.default)({
    url: "/traderrisktemplate/resetTraderRiskTemplateCache/".concat(id),
    method: 'post'
  });
}