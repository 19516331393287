"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _transaction = require("@/api/transaction");
var _reportQuery = require("@/api/reportQuery");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      accountTypeDict: [],
      options: [],
      labelPosition: 'right',
      from: {
        accountType: "",
        dataFactoryName: '',
        userName: '',
        contractId: '',
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _transaction.exchangecontractGetOption)().then(function (res) {
      console.log('合约全列表', res);
      _this.options = res;
      // this.getlist()
    }).catch(function (err) {
      console.log(err);
    });
    (0, _transaction.getDictData)('yj_trader_type').then(function (res) {
      console.log('账户类型字典数据：', res);
      _this.accountTypeDict = res;
      _this.from.accountType = res[0].dictValue;
      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });
  },
  methods: {
    exportTable: function exportTable() {
      var _this2 = this;
      (0, _reportQuery.reportUserReportExport)(this.from).then(function (res) {
        console.log('导出表格', res);
        window.open(res.filename);
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err);
      });
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        accountType: this.accountTypeDict[0].dictValue,
        dataFactoryName: '',
        userName: '',
        contractId: '',
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this3 = this;
      this.loading = true;
      (0, _reportQuery.reportGetUserReport)(this.from).then(function (res) {
        console.log(res);
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};