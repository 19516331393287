//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { exchangecontractGetOption, getDictData } from '@/api/transaction';
import { reportGetUserOrder, reportUserOrderExport, cancelTradeOrderApi } from '@/api/reportQuery';
export default {
  data: function data() {
    return {
      options: [],
      labelPosition: 'right',
      from: {
        accountType: "",
        userName: '',
        contractId: '',
        status: '',
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      accountTypeDict: [],
      cancelArr: [],
      tradeOptions: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    exchangecontractGetOption().then(function (res) {
      console.log('合约全列表', res);
      _this.options = res;
      // this.getlist()
    }).catch(function (err) {
      console.log(err);
    });

    //获取字典数据
    getDictData('yj_trader_type').then(function (res) {
      console.log('账户类型字典数据：', res);
      _this.accountTypeDict = res;
      _this.from.accountType = res[0].dictValue;
      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });
    getDictData('trade_status').then(function (res) {
      console.log('交易状态字典数据：', res);
      _this.tradeOptions = res;
    }).catch(function (res) {
      console.log(res);
    });
  },
  methods: {
    //一键撤单
    selctTable: function selctTable(selection) {
      var that = this;
      console.log(selection);
      that.cancelArr = selection;
    },
    slectTableAll: function slectTableAll(selection) {
      var that = this;
      console.log(selection);
      that.cancelArr = selection;
    },
    cancelTradeBtn: function cancelTradeBtn(e) {
      var that = this;
      console.log('当前数据', e);
      // status=0  才可以撤单
      if (e.status == 0) {
        that.$confirm("确认撤单?", "提示").then(function (_) {
          console.log("确定", e);
          cancelTradeOrderApi([e]).then(function (res) {
            console.log(res);
            that.$message.success("撤单成功!");
            that.getlist();
          }).catch(function (err) {
            console.log(err);
          });
        }).catch(function (_) {});
      } else {
        that.$message.warning('不可撤单！');
      }
    },
    cancelTradeBtns: function cancelTradeBtns() {
      var that = this;
      console.log('撤单数组：', that.cancelArr);
      if (that.cancelArr.length < 1) {
        that.$message.warning('请至少选中一个！');
        return;
      }

      // 循环，只拿status=0 可撤单的对象
      var arr = [];
      for (var i = 0; i < that.cancelArr.length; i++) {
        // console.log(that.buttonArr[i].id)
        if (that.cancelArr[i].status == 0) {
          arr.push(that.cancelArr[i]);
        }
      }
      console.log('撤单过滤的数组:', arr);
      if (arr.length < 1) {
        return that.$message.warning('不可撤单！');
      }
      that.$confirm("确认撤单?", "提示").then(function (_) {
        console.log("确定", arr);
        cancelTradeOrderApi(arr).then(function (res) {
          console.log(res);
          that.$message.success("撤单成功!");
          that.getlist();
        }).catch(function (err) {
          console.log(err);
        });
      }).catch(function (_) {});
    },
    selectDictType: function selectDictType(dictValue) {
      var face = this.accountTypeDict.find(function (res) {
        return res.dictValue == JSON.stringify(dictValue);
      });
      if (face) return face.dictLabel;
    },
    selectDictType2: function selectDictType2(dictValue) {
      var face = this.tradeOptions.find(function (res) {
        return res.dictValue == JSON.stringify(dictValue);
      });
      if (face) return face.dictLabel;
    },
    exportTable: function exportTable() {
      var _this2 = this;
      reportUserOrderExport(this.from).then(function (res) {
        console.log('导出表格', res);
        window.open(res.filename);
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err);
      });
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        accountType: this.accountTypeDict[0].dictValue,
        userName: '',
        contractId: '',
        status: '',
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this3 = this;
      this.loading = true;
      reportGetUserOrder(this.from).then(function (res) {
        console.log(res);
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};