import Layout from '@/layout';
var transactionRouter = {
  path: '/transaction',
  component: Layout,
  redirect: '/transaction',
  name: 'Transaction',
  meta: {
    title: '交易参数',
    icon: 's-marketing',
    roles: ['admin']
  },
  children: [{
    path: 'exchange',
    name: 'exchange',
    component: function component() {
      return import('@/views/transaction/exchange/index');
    },
    meta: {
      title: '交易所'
    }
  }, {
    path: 'contractBreed',
    name: 'contractBreed',
    component: function component() {
      return import('@/views/transaction/contractBreed/index');
    },
    meta: {
      title: '品种管理'
    }
  }, {
    path: 'contract',
    name: 'contract',
    component: function component() {
      return import('@/views/transaction/contract/index');
    },
    meta: {
      title: '合约管理'
    }
  }, {
    path: 'currency',
    name: 'currency',
    component: function component() {
      return import('@/views/transaction/currency/index');
    },
    meta: {
      title: '币种设置'
    }
  }, {
    path: 'exchangeRate',
    name: 'exchangeRate',
    component: function component() {
      return import('@/views/transaction/exchangeRate/index');
    },
    meta: {
      title: '汇率设置'
    }
  }, {
    path: 'interface',
    name: 'interface',
    component: function component() {
      return import('@/views/transaction/interface/index');
    },
    meta: {
      title: '接口类型管理'
    }
  }, {
    path: 'forward',
    name: 'forward',
    component: function component() {
      return import('@/views/transaction/forward/index');
    },
    meta: {
      title: '期货商管理'
    }
  }]
};
export default transactionRouter;