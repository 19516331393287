//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { reportTodayBalanceBill, reportTodayBalanceBillExport } from "@/api/reportQuery";
import { getDictData } from "@/api/transaction";
export default {
  data: function data() {
    return {
      labelPosition: "right",
      from: {
        accountType: "",
        userName: "",
        billType: "",
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      accountTypeDict: [],
      balanceBillType: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    // this.getlist()

    //获取字典数据
    getDictData("yj_trader_type").then(function (res) {
      console.log("账户类型字典数据：", res);
      _this.accountTypeDict = res;
      _this.from.accountType = res[0].dictValue;
      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });
    //获取字典数据
    getDictData("balance_bill_type").then(function (res) {
      console.log("类型字典数据：", res);
      _this.balanceBillType = res;
      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });
  },
  methods: {
    exportTable: function exportTable() {
      var _this2 = this;
      reportTodayBalanceBillExport(this.from).then(function (res) {
        console.log("导出表格", res);
        window.open(res.filename);
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err);
      });
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        accountType: this.accountTypeDict[0].dictValue,
        userName: "",
        billType: "",
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this3 = this;
      this.loading = true;
      reportTodayBalanceBill(this.from).then(function (res) {
        console.log(res);
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};