import Layout from '@/layout';
var reportQueryRouter = {
  path: '/reportQuery',
  component: Layout,
  redirect: '/reportQuery',
  name: 'ReportQuery',
  meta: {
    title: '报表查询',
    icon: 's-data',
    roles: ['admin']
  },
  children: [{
    path: 'accountStatistics',
    name: 'accountStatistics',
    component: function component() {
      return import('@/views/reportQuery/accountStatistics/index');
    },
    meta: {
      title: '账户统计'
    }
  }, {
    path: 'financialStatistics',
    name: 'financialStatistics',
    component: function component() {
      return import('@/views/reportQuery/financialStatistics/index');
    },
    meta: {
      title: '资金统计'
    }
  }, {
    path: 'positionList',
    name: 'positionList',
    component: function component() {
      return import('@/views/reportQuery/positionList/index');
    },
    meta: {
      title: '持仓列表'
    }
  }, {
    path: 'transactionList',
    name: 'transactionList',
    component: function component() {
      return import('@/views/reportQuery/transactionList/index');
    },
    meta: {
      title: '成交列表'
    }
  }, {
    path: 'limitChange',
    name: 'limitChange',
    component: function component() {
      return import('@/views/reportQuery/limitChange/index');
    },
    meta: {
      title: '额度变动'
    }
  }, {
    path: 'pingyingList',
    name: 'pingyingList',
    component: function component() {
      return import('@/views/reportQuery/pingyingList/index');
    },
    meta: {
      title: '平盈列表'
    }
  }, {
    path: 'historicalLimitChanges',
    name: 'historicalLimitChanges',
    component: function component() {
      return import('@/views/reportQuery/historicalLimitChanges/index');
    },
    meta: {
      title: '历史额度变动'
    }
  }, {
    path: 'commissionList',
    name: 'commissionList',
    component: function component() {
      return import('@/views/reportQuery/commissionList/index');
    },
    meta: {
      title: '委托列表'
    }
  }, {
    path: 'historicalCommission',
    name: 'historicalCommission',
    component: function component() {
      return import('@/views/reportQuery/historicalCommission/index');
    },
    meta: {
      title: '历史委托'
    }
  }, {
    path: 'takeProfitAndstopLoss',
    name: 'takeProfitAndstopLoss',
    component: function component() {
      return import('@/views/reportQuery/takeProfitAndstopLoss/index');
    },
    meta: {
      title: '止盈止损'
    }
  }]
};
export default reportQueryRouter;