"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tenant = require("@/api/tenant");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "TenantList",
  props: {
    userDialogVisible: {
      required: true,
      type: Boolean
    },
    userTenantId: {
      required: true
    }
  },
  watch: {
    userDialogVisible: function userDialogVisible(newVal) {
      if (newVal) this.getlist();
    }
  },
  data: function data() {
    return {
      labelPosition: "left",
      from: {
        name: "",
        status: 1,
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      selection: [],
      dialogVisible: false,
      ruleForm: {
        id: "",
        name: "",
        status: 1
      },
      rules: {
        name: [{
          required: true,
          message: "请填写租户名称",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    toggleVisible: function toggleVisible() {
      this.$emit('toggleVisible');
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        name: "",
        status: 1,
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this = this;
      this.loading = true;
      var params = _objectSpread({
        userTenantId: this.userTenantId
      }, this.from);
      (0, _tenant.getTenantUserList)(params).then(function (res) {
        console.log(res);
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.loading = false;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    add: function add() {
      this.ruleForm = {
        id: "",
        name: "",
        status: 1
      };
      this.dialogVisible = true;
    },
    close: function close() {
      this.ruleForm = {
        id: "",
        name: "",
        status: 1
      };
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          if (_this2.ruleForm.id) {
            var data = {
              id: _this2.ruleForm.id,
              name: _this2.ruleForm.name,
              status: _this2.ruleForm.status
            };
            (0, _tenant.updateTenantInfo)(data).then(function (res) {
              console.log(res);
              _this2.close();
              _this2.$message.success("编辑成功");
              _this2.getlist();
            }).catch(function (err) {
              console.log(err);
              _this2.$message.error(err);
            });
          } else {
            var _data = {
              name: _this2.ruleForm.name,
              status: _this2.ruleForm.status
            };
            (0, _tenant.addTenantInfo)(_data).then(function (res) {
              _this2.close();
              _this2.$message.success("新增成功");
              _this2.getlist();
            }).catch(function (err) {
              _this2.$message.error(err);
            });
          }
        } else {
          _this2.$message.error("请填写完整信息");
        }
      });
    },
    // 选择
    onSelectTab: function onSelectTab(selection) {
      var _this3 = this;
      this.selection = [];
      selection.forEach(function (item) {
        _this3.selection.push(item.id);
      });
      console.log(this.selection);
    },
    allDel: function allDel() {
      var _this4 = this;
      if (this.selection.length == 0) {
        return this.$message.error("至少选择一项");
      }
      console.log(this.selection);
      this.$confirm("确认删除？").then(function (_) {
        (0, _tenant.deleteBatchTenantInfo)(_this4.selection).then(function (res) {
          console.log(res);
          _this4.$message.success("删除成功");
          _this4.getlist();
        }).catch(function (err) {
          console.log(err);
          _this4.$message.error(err);
        });
      }).catch(function (_) {});
    },
    handleEdit: function handleEdit(row) {
      console.log("编辑", row);
      var ruleForm = JSON.parse(JSON.stringify(row));
      this.ruleForm = ruleForm;
      this.dialogVisible = true;
    },
    handleDel: function handleDel(row) {
      var _this5 = this;
      this.$confirm("确认删除？").then(function (_) {
        (0, _tenant.deleteTenantInfo)(row.id).then(function (res) {
          console.log(res);
          _this5.$message.success("删除成功");
          _this5.getlist();
        }).catch(function (err) {
          console.log(err);
          _this5.$message.error(err);
        });
      }).catch(function (_) {});
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};