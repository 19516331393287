var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selectAble" },
    [
      _c(
        "el-select",
        {
          ref: "select",
          staticClass: "selWidth",
          attrs: {
            filterable: "",
            "reserve-keyword": "",
            clearable: "",
            multiple: _vm.multiple,
            placeholder: "请输入关键词检索",
            "filter-method": _vm.remoteMethod,
            loading: _vm.loading,
            disabled: _vm.disabled,
          },
          on: { change: _vm.selectUser, clear: _vm.remoteMethod },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _vm.type == ""
            ? _c("div", { staticClass: "label dropdown" }, [
                _c("span", [_vm._v("Id")]),
                _vm._v(" "),
                _c("span", [_vm._v("昵称")]),
                _vm._v(" "),
                _c("span", [_vm._v("手机号")]),
                _vm._v(" "),
                _c("span", [_vm._v("邮箱")]),
              ])
            : _c(
                "div",
                { staticClass: "label dropdown" },
                [_vm._t("dropdownTitle")],
                2
              ),
          _vm._v(" "),
          _vm._l(_vm.lists, function (item) {
            return _c(
              "el-option",
              {
                key: item[_vm.listValue],
                attrs: {
                  label: item[_vm.listLabel],
                  value: item[_vm.listValue],
                },
              },
              [
                _vm.type == ""
                  ? _c("div", { staticClass: "dropdown" }, [
                      _c("span", [_vm._v(_vm._s(item.uid))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.nickname))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.phone))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.email))]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dropdown" },
                  [_vm._t("dropdown", null, { item: item })],
                  2
                ),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }