import Layout from '@/layout';
var tradeaccountfillRouter = {
  path: '/tradeaccountfill',
  component: Layout,
  redirect: '/tradeaccountfill',
  name: 'Tradeaccountfill',
  meta: {
    title: '补仓管理',
    icon: 's-platform',
    roles: ['admin']
  },
  children: [{
    path: 'fmanage',
    name: 'fmanage',
    component: function component() {
      return import('@/views/tradeaccountfill/fmanage/index');
    },
    meta: {
      title: '正常补仓'
    }
  }, {
    path: 'smanage',
    name: 'smanage',
    component: function component() {
      return import('@/views/tradeaccountfill/smanage/index');
    },
    meta: {
      title: '爆仓补仓'
    }
  }, {
    path: 'tmanage',
    name: 'tmanage',
    component: function component() {
      return import('@/views/tradeaccountfill/tmanage/index');
    },
    meta: {
      title: '其他补仓'
    }
  }, {
    path: 'cover',
    name: 'cover',
    component: function component() {
      return import('@/views/tradeaccountfill/cover/index');
    },
    meta: {
      title: '补仓列表'
    }
  }]
};
export default tradeaccountfillRouter;