//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { exchangecontractGetOption } from '@/api/transaction';
import { reportGetUserPosition, reportReportExit } from '@/api/reportQuery';
import UserSearchable from "@/components/UserSearchable";
import { getDictData } from "@/api/transaction";
export default {
  components: {
    UserSearchable: UserSearchable
  },
  data: function data() {
    return {
      options: [],
      labelPosition: 'right',
      from: {
        userId: '',
        contractId: '',
        accountType: '',
        page: 1,
        limit: 15
      },
      selection: [],
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      timerId: null,
      accountTypeDict: [],
      realTimeOptions: [{
        value: 1000,
        label: '1秒'
      }, {
        value: 2000,
        label: '2秒'
      }, {
        value: 5000,
        label: '5秒'
      }, {
        value: 10000,
        label: '10秒'
      }, {
        value: 15000,
        label: '15秒'
      }, {
        value: 20000,
        label: '20秒'
      }],
      realTimeValues: "",
      realTimeSwitchValue: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    exchangecontractGetOption().then(function (res) {
      console.log('合约全列表', res);
      _this.options = res;
      // this.getlist()
    }).catch(function (err) {
      console.log(err);
    });

    //获取字典数据
    getDictData('yj_trader_type').then(function (res) {
      console.log('账户类型字典数据：', res);
      _this.accountTypeDict = res;
      _this.from.accountType = res[0].dictValue;
      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });

    // 创建定时器
    var that = this;
    // this.timerId = setInterval(() => {
    //   // 定时器的逻辑
    //   that.getlist()
    // }, 10000);

    // lys
    var timeObj = JSON.parse(localStorage.getItem('realObj2'));
    console.log('时间对象：', timeObj);
    if (timeObj) {
      that.realTimeValues = timeObj.times;
      that.realTimeSwitchValue = timeObj.switch;
      if (that.realTimeSwitchValue == true) {
        // 开启定时器
        that.timerId = setInterval(function () {
          that.getlist();
        }, that.realTimeValues);
      } else {
        // 清除定时器，默认没有实时调度
        if (that.timerId) {
          console.log("销毁定时器");
          clearInterval(that.timerId);
        }
      }
    } else {
      // 清除定时器，默认没有实时调度
      if (that.timerId) {
        console.log("销毁定时器");
        clearInterval(that.timerId);
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    // 清除定时器
    if (this.timerId) {
      console.log('销毁定时器');
      clearInterval(this.timerId);
    }
  },
  methods: {
    realSwitch: function realSwitch(e) {
      var that = this;
      // console.log('组件：',e)
      console.log('绑定的值：', that.realTimeSwitchValue);
      console.log('选择的秒数：', that.realTimeValues);
      if (that.realTimeSwitchValue == false) {
        // 关闭清空秒数
        // that.realTimeValues = ""
        // console.log('清空秒数！')
        localStorage.setItem('realObj2', JSON.stringify({
          switch: that.realTimeSwitchValue,
          times: that.realTimeValues
        }));
        // 销毁定时器
        if (that.timerId) {
          console.log("销毁定时器");
          clearInterval(that.timerId);
        }
      } else {
        // 开启时判断是否选择秒数
        if (that.realTimeValues == "") {
          that.$message.warning('请选择秒数！');
          return that.realTimeSwitchValue = false;
        }
        localStorage.setItem('realObj2', JSON.stringify({
          switch: that.realTimeSwitchValue,
          times: that.realTimeValues
        }));
        // 开启定时器
        that.timerId = setInterval(function () {
          that.getlist();
        }, that.realTimeValues);
      }
    },
    selectTime: function selectTime(e) {
      var that = this;
      console.log('选择的秒数值：', e);

      // 清除定时器
      if (that.timerId) {
        console.log("先销毁定时器再开启");
        clearInterval(that.timerId);
      }
      localStorage.setItem('realObj2', JSON.stringify({
        switch: that.realTimeSwitchValue,
        times: e
      }));
      // 开启定时器
      if (that.realTimeSwitchValue == true) {
        that.timerId = setInterval(function () {
          that.getlist();
        }, e);
      }
    },
    selectDictType: function selectDictType(dictValue) {
      var face = this.accountTypeDict.find(function (res) {
        return res.dictValue == JSON.stringify(dictValue);
      });
      if (face) return face.dictLabel;
    },
    // 选择
    onSelectTab: function onSelectTab(selection) {
      var _this2 = this;
      this.selection = [];
      selection.forEach(function (item) {
        _this2.selection.push(item);
      });
    },
    allClosePosition: function allClosePosition() {
      var _this3 = this;
      if (this.selection.length == 0) {
        return this.$message.error('至少选择一项');
      }
      console.log('勾选数据', this.selection);
      this.$confirm('确认一键平仓？').then(function (_) {
        reportReportExit(_this3.selection).then(function (res) {
          console.log(res);
          _this3.$message.success('平仓成功');
          _this3.getlist();
        }).catch(function (err) {
          console.log(err);
          _this3.$message.error(err);
        });
      }).catch(function (_) {});
    },
    handleClosePosition: function handleClosePosition(row) {
      var _this4 = this;
      console.log(row);
      this.$confirm('确认手动平仓？').then(function (_) {
        reportReportExit([row]).then(function (res) {
          console.log(res);
          _this4.$message.success('平仓成功');
          _this4.getlist();
        }).catch(function (err) {
          console.log(err);
          _this4.$message.error(err);
        });
      }).catch(function (_) {});
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        userId: '',
        contractId: '',
        accountType: this.accountTypeDict[0].dictValue,
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this5 = this;
      // this.loading = true
      reportGetUserPosition(this.from).then(function (res) {
        console.log(res);
        _this5.tableData.data = res.list;
        _this5.tableData.total = res.total;
        // this.loading = false
      }).catch(function (err) {
        console.log(err);
        // this.loading = false
      });
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};