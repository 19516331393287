//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserSearchable from "@/components/UserSearchable";
import { reportStatistics } from "@/api/dataField";
export default {
  data: function data() {
    return {
      grid: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 24
      },
      form: {},
      increaseBalance: "",
      // 资管余额当日增加
      reduceBalance: "",
      // 资管余额当日减少
      totalBalance: "",
      // 资管余额
      glbTotalBalance: "" // 概率宝余额
    };
  },
  components: {
    UserSearchable: UserSearchable
  },
  mounted: function mounted() {
    this.GetReportStatistics();
  },
  methods: {
    GetReportStatistics: function GetReportStatistics() {
      var _this = this;
      reportStatistics({
        dataFactoryId: this.form.dataFactoryId
      }).then(function (res) {
        console.log("数据统计", res);
        _this.increaseBalance = res.increaseBalance;
        _this.reduceBalance = res.reduceBalance;
        _this.totalBalance = res.totalBalance;
        _this.glbTotalBalance = res.glbTotalBalance;
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};