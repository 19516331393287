var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "from",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.from,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 20, xl: 20 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "数据场：" } },
                                [
                                  _c("UserSearchable", {
                                    staticClass: "w_200",
                                    attrs: { type: "datafieldList" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "dropdownTitle",
                                        fn: function () {
                                          return [
                                            _c("span", [_vm._v("数据场名称")]),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("负责人")]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "dropdown",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(item.name)),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(item.principal)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.from.dataFactoryId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "dataFactoryId", $$v)
                                      },
                                      expression: "from.dataFactoryId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "red_start",
                                  attrs: { label: "时间选择：" },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd",
                                      format: "yyyy-MM-dd",
                                      clearable: false,
                                      type: "daterange",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    on: { change: _vm.onchangeTime },
                                    model: {
                                      value: _vm.timeVal,
                                      callback: function ($$v) {
                                        _vm.timeVal = $$v
                                      },
                                      expression: "timeVal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 24, lg: 4, xl: 4 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: {
                                        size: "small",
                                        icon: "el-icon-search",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.search()
                                        },
                                      },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: {
                                        size: "small",
                                        icon: "el-icon-refresh",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset()
                                        },
                                      },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "realName",
                  label: "关联用户",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "身份",
                  prop: "memberType",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(_vm.memberTypeList, function (item, index) {
                        return item.dictValue == scope.row.memberType
                          ? _c(
                              "el-tag",
                              {
                                key: index,
                                staticClass: "mr5",
                                attrs: { size: "small", type: "info" },
                              },
                              [_vm._v(_vm._s(item.dictLabel))]
                            )
                          : _vm._e()
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "dataFactoryName",
                  label: "数据场",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "principal",
                  label: "负责人",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sunCount",
                  label: "总手数",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.sunCount))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "parallelRewardFee",
                  label: "平级奖励",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              parseFloat("" + scope.row.parallelRewardFee)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sunBrokerageFee",
                  label: "总佣金",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.sunBrokerageFee != ""
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  parseFloat("" + scope.row.sunBrokerageFee)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("0.00")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "130",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetails(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleGenerate(scope.row)
                              },
                            },
                          },
                          [_vm._v("生成佣金")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 100, 1000, _vm.tableData.total],
                  "page-size": _vm.from.limit,
                  "current-page": _vm.from.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "交易明细",
            visible: _vm.userVisible,
            width: "1200px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                data: _vm.userData.data,
                "highlight-current-row": "",
                size: "small",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "userName", label: "姓名", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userType",
                  label: "用户类型",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.selectinterfacetype(scope.row.userType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: " brokerageType",
                  label: "分佣方式",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        _vm.brokerageTypeList,
                        function (item, index) {
                          return item.dictValue == scope.row.brokerageType
                            ? _c(
                                "el-tag",
                                {
                                  key: index,
                                  staticClass: "mr5",
                                  attrs: { size: "small", type: "info" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.dictLabel) +
                                      _vm._s(
                                        scope.row.brokerageType == 3
                                          ? "(" +
                                              scope.row.percentReward * 100 +
                                              "%)"
                                          : ""
                                      )
                                  ),
                                ]
                              )
                            : _vm._e()
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "commodityBrokerageVoList",
                  label: "合约",
                  align: "center",
                  "min-width": "500",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              border: "",
                              data: scope.row.commodityBrokerageVoList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "commodityName",
                                label: "合约",
                                "min-width": "100",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commodityCount",
                                label: "手数",
                                "min-width": "100",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commodityBrokeragePrice",
                                label: "手续费/笔",
                                "min-width": "150",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commodityBrokerageFee",
                                label: "总手续费",
                                "min-width": "150",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              parseFloat(
                                                "" +
                                                  scope.row
                                                    .commodityBrokerageFee
                                              )
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userCount",
                  label: "总手数",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brokerageAmount",
                  label: "总手续费",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              parseFloat("" + scope.row.brokerageAmount)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brokerageFee",
                  label: "总佣金",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              parseFloat("" + scope.row.brokerageFee)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.detail.limit,
                  "current-page": _vm.detail.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.userData.total,
                },
                on: {
                  "size-change": _vm.handleUserSizeChange,
                  "current-change": _vm.userPageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "生成佣金",
            visible: _vm.dialogVisible,
            width: "600px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.ruleForm, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "总佣金：", prop: "sunBrokerageFee" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.sunBrokerageFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "sunBrokerageFee", $$v)
                      },
                      expression: "ruleForm.sunBrokerageFee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "结算佣金：", prop: "amount" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { disabled: true },
                      model: {
                        value: _vm.ruleForm.amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "amount", $$v)
                        },
                        expression: "ruleForm.amount",
                      },
                    },
                    [_vm._v(_vm._s(_vm.amount))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "结算类型：",
                    prop: "brokerageSettlementType",
                  },
                },
                [
                  _vm.ruleForm.brokerageSettlementType == 1
                    ? _c("span", [_vm._v("日结")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.brokerageSettlementType == 2
                    ? _c("span", [_vm._v("周结")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.brokerageSettlementType == 3
                    ? _c("span", [_vm._v("月结")])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "结算比率：", prop: "brokerageFee" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { disabled: true },
                      model: {
                        value: _vm.ruleForm.brokerageFee * 100,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "brokerageFee * 100", $$v)
                        },
                        expression: "ruleForm.brokerageFee * 100",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }