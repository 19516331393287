"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.currencyDelete = currencyDelete;
exports.currencyExport = currencyExport;
exports.currencyGetOption = currencyGetOption;
exports.currencyInfo = currencyInfo;
exports.currencyList = currencyList;
exports.currencySave = currencySave;
exports.currencyUpdate = currencyUpdate;
exports.currencyrateDelete = currencyrateDelete;
exports.currencyrateExport = currencyrateExport;
exports.currencyrateInfo = currencyrateInfo;
exports.currencyrateList = currencyrateList;
exports.currencyrateResetCache = currencyrateResetCache;
exports.currencyrateSave = currencyrateSave;
exports.currencyrateUpdate = currencyrateUpdate;
exports.exchangeDelete = exchangeDelete;
exports.exchangeExport = exchangeExport;
exports.exchangeGetOption = exchangeGetOption;
exports.exchangeInfo = exchangeInfo;
exports.exchangeList = exchangeList;
exports.exchangeSave = exchangeSave;
exports.exchangeUpdate = exchangeUpdate;
exports.exchangecommodityDelete = exchangecommodityDelete;
exports.exchangecommodityExport = exchangecommodityExport;
exports.exchangecommodityGetOption = exchangecommodityGetOption;
exports.exchangecommodityGetRiskOption = exchangecommodityGetRiskOption;
exports.exchangecommodityInfo = exchangecommodityInfo;
exports.exchangecommodityList = exchangecommodityList;
exports.exchangecommoditySave = exchangecommoditySave;
exports.exchangecommodityUpdate = exchangecommodityUpdate;
exports.exchangecontractDelete = exchangecontractDelete;
exports.exchangecontractExport = exchangecontractExport;
exports.exchangecontractGetOption = exchangecontractGetOption;
exports.exchangecontractInfo = exchangecontractInfo;
exports.exchangecontractList = exchangecontractList;
exports.exchangecontractSave = exchangecontractSave;
exports.exchangecontractUpdate = exchangecontractUpdate;
exports.futuresmerchantDelete = futuresmerchantDelete;
exports.futuresmerchantExport = futuresmerchantExport;
exports.futuresmerchantInfo = futuresmerchantInfo;
exports.futuresmerchantList = futuresmerchantList;
exports.futuresmerchantSave = futuresmerchantSave;
exports.futuresmerchantUpdate = futuresmerchantUpdate;
exports.getDictData = getDictData;
exports.getRate = getRate;
exports.interfacetypeDelete = interfacetypeDelete;
exports.interfacetypeExport = interfacetypeExport;
exports.interfacetypeGetOption = interfacetypeGetOption;
exports.interfacetypeInfo = interfacetypeInfo;
exports.interfacetypeList = interfacetypeList;
exports.interfacetypeSave = interfacetypeSave;
exports.interfacetypeUpdate = interfacetypeUpdate;
exports.market_source = market_source;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// 交易所
/*
 * 交易所全列表
 */
function exchangeGetOption(data) {
  return (0, _request.default)({
    url: '/exchange/getOption',
    method: 'post',
    data: data
  });
}
/*
 * 交易所列表
 */
function exchangeList(params) {
  return (0, _request.default)({
    url: '/exchange/list',
    method: 'get',
    params: params
  });
}
/*
 * 交易所新增
 */
function exchangeSave(data) {
  return (0, _request.default)({
    url: '/exchange/save',
    method: 'post',
    data: data
  });
}
/*
 * 交易所详情
 */
function exchangeInfo(id) {
  return (0, _request.default)({
    url: "/exchange/info/".concat(id),
    method: 'post'
  });
}
/*
 * 交易所编辑
 */
function exchangeUpdate(data) {
  return (0, _request.default)({
    url: '/exchange/update',
    method: 'post',
    data: data
  });
}
/*
 * 交易所删除
 */
function exchangeDelete(data) {
  return (0, _request.default)({
    url: '/exchange/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出交易所
 */
function exchangeExport(params) {
  return (0, _request.default)({
    url: '/exchange/export',
    method: 'get',
    params: params
  });
}

// 品种管理
/*
 * 品种全列表
 */
function exchangecommodityGetOption(data) {
  return (0, _request.default)({
    url: '/exchangecommodity/getOption',
    method: 'post',
    data: data
  });
}
/*
 * 品种强平列表
 */
function exchangecommodityGetRiskOption(id) {
  return (0, _request.default)({
    url: "/exchangecommodity/getRiskOption/".concat(id),
    method: 'post'
  });
}
/*
 * 品种列表
 */
function exchangecommodityList(params) {
  return (0, _request.default)({
    url: '/exchangecommodity/list',
    method: 'get',
    params: params
  });
}
/*
 * 品种新增
 */
function exchangecommoditySave(data) {
  return (0, _request.default)({
    url: '/exchangecommodity/save',
    method: 'post',
    data: data
  });
}
/*
 * 品种详情
 */
function exchangecommodityInfo(id) {
  return (0, _request.default)({
    url: "/exchangecommodity/info/".concat(id),
    method: 'post'
  });
}
/*
 * 品种编辑
 */
function exchangecommodityUpdate(data) {
  return (0, _request.default)({
    url: '/exchangecommodity/update',
    method: 'post',
    data: data
  });
}
/*
 * 品种删除
 */
function exchangecommodityDelete(data) {
  return (0, _request.default)({
    url: '/exchangecommodity/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出品种
 */
function exchangecommodityExport(params) {
  return (0, _request.default)({
    url: '/exchangecommodity/export',
    method: 'get',
    params: params
  });
}

// 合约管理
/*
 * 合约全列表
 */
function exchangecontractGetOption(data) {
  return (0, _request.default)({
    url: '/exchangecontract/getOption',
    method: 'post',
    data: data
  });
}
/*
 * 行情源列表
 */
function market_source(params) {
  return (0, _request.default)({
    url: '/sys-dict-data/type/market_source',
    method: 'get',
    params: params
  });
}
/*
 * 合约列表
 */
function exchangecontractList(params) {
  return (0, _request.default)({
    url: '/exchangecontract/list',
    method: 'get',
    params: params
  });
}
/*
 * 合约新增
 */
function exchangecontractSave(data) {
  return (0, _request.default)({
    url: '/exchangecontract/save',
    method: 'post',
    data: data
  });
}
/*
 * 合约详情
 */
function exchangecontractInfo(id) {
  return (0, _request.default)({
    url: "/exchangecontract/info/".concat(id),
    method: 'post'
  });
}
/*
 * 合约编辑
 */
function exchangecontractUpdate(data) {
  return (0, _request.default)({
    url: '/exchangecontract/update',
    method: 'post',
    data: data
  });
}
/*
 * 合约删除
 */
function exchangecontractDelete(data) {
  return (0, _request.default)({
    url: '/exchangecontract/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出合约
 */
function exchangecontractExport(params) {
  return (0, _request.default)({
    url: '/exchangecontract/export',
    method: 'get',
    params: params
  });
}

// 币种设置
/*
 * 币种列表
 */
function currencyList(params) {
  return (0, _request.default)({
    url: '/currency/list',
    method: 'get',
    params: params
  });
}
/*
 * 币种新增
 */
function currencySave(data) {
  return (0, _request.default)({
    url: '/currency/save',
    method: 'post',
    data: data
  });
}
/*
 * 币种详情
 */
function currencyInfo(id) {
  return (0, _request.default)({
    url: "/currency/info/".concat(id),
    method: 'post'
  });
}
/*
 * 币种编辑
 */
function currencyUpdate(data) {
  return (0, _request.default)({
    url: '/currency/update',
    method: 'post',
    data: data
  });
}
/*
 * 币种删除
 */
function currencyDelete(data) {
  return (0, _request.default)({
    url: '/currency/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出币种
 */
function currencyExport(params) {
  return (0, _request.default)({
    url: '/currency/export',
    method: 'get',
    params: params
  });
}

/*
 * 币种全列表
 */
function currencyGetOption(data) {
  return (0, _request.default)({
    url: '/currency/getOption',
    method: 'post',
    data: data
  });
}
/*
 * 获取汇率
 */
function getRate(data) {
  return (0, _request.default)({
    url: '/currency/getRate',
    method: 'post',
    data: data
  });
}

// 汇率设置
/*
 * 汇率列表
 */
function currencyrateList(params) {
  return (0, _request.default)({
    url: '/currencyrate/list',
    method: 'get',
    params: params
  });
}
/*
 * 汇率新增
 */
function currencyrateSave(data) {
  return (0, _request.default)({
    url: '/currencyrate/save',
    method: 'post',
    data: data
  });
}
/*
 * 汇率详情
 */
function currencyrateInfo(id) {
  return (0, _request.default)({
    url: "/currencyrate/info/".concat(id),
    method: 'post'
  });
}
/*
 * 汇率编辑
 */
function currencyrateUpdate(data) {
  return (0, _request.default)({
    url: '/currencyrate/update',
    method: 'post',
    data: data
  });
}
/*
 * 汇率删除
 */
function currencyrateDelete(data) {
  return (0, _request.default)({
    url: '/currencyrate/delete',
    method: 'post',
    data: data
  });
}

/**
 * 刷新汇率缓存
 */

function currencyrateResetCache(data) {
  return (0, _request.default)({
    url: '/currencyrate/resetCache',
    method: 'post',
    data: data
  });
}

/*
 * 导出汇率
 */
function currencyrateExport(params) {
  return (0, _request.default)({
    url: '/currencyrate/export',
    method: 'get',
    params: params
  });
}

// 接口类型管理
/*
 * 接口类型管理全列表
 */
function interfacetypeGetOption(params) {
  return (0, _request.default)({
    url: '/sys-dict-data/type/interface_type',
    method: 'get',
    params: params
  });
}
/*
 * 接口类型管理列表
 */
function interfacetypeList(params) {
  return (0, _request.default)({
    url: '/interfacetype/list',
    method: 'get',
    params: params
  });
}
/*
 * 接口类型管理新增
 */
function interfacetypeSave(data) {
  return (0, _request.default)({
    url: '/interfacetype/save',
    method: 'post',
    data: data
  });
}
/*
 * 接口类型管理详情
 */
function interfacetypeInfo(id) {
  return (0, _request.default)({
    url: "/interfacetype/info/".concat(id),
    method: 'post'
  });
}
/*
 * 接口类型管理编辑
 */
function interfacetypeUpdate(data) {
  return (0, _request.default)({
    url: '/interfacetype/update',
    method: 'post',
    data: data
  });
}
/*
 * 接口类型管理删除
 */
function interfacetypeDelete(data) {
  return (0, _request.default)({
    url: '/interfacetype/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出接口类型管理
 */
function interfacetypeExport(params) {
  return (0, _request.default)({
    url: '/interfacetype/export',
    method: 'get',
    params: params
  });
}

// 期货商管理
/*
 * 期货商列表
 */
function futuresmerchantList(params) {
  return (0, _request.default)({
    url: '/futuresmerchant/list',
    method: 'get',
    params: params
  });
}
/*
 * 期货商新增
 */
function futuresmerchantSave(data) {
  return (0, _request.default)({
    url: '/futuresmerchant/save',
    method: 'post',
    data: data
  });
}
/*
 * 期货商详情
 */
function futuresmerchantInfo(id) {
  return (0, _request.default)({
    url: "/futuresmerchant/info/".concat(id),
    method: 'post'
  });
}
/*
 * 期货商编辑
 */
function futuresmerchantUpdate(data) {
  return (0, _request.default)({
    url: '/futuresmerchant/update',
    method: 'post',
    data: data
  });
}
/*
 * 期货商删除
 */
function futuresmerchantDelete(data) {
  return (0, _request.default)({
    url: '/futuresmerchant/delete',
    method: 'post',
    data: data
  });
}
/*
 * 导出期货商
 */
function futuresmerchantExport(params) {
  return (0, _request.default)({
    url: '/futuresmerchant/export',
    method: 'get',
    params: params
  });
}

/*
 * 根据类型获取字典数据
 */
function getDictData(dictType) {
  return (0, _request.default)({
    url: "/sys-dict-data/type/".concat(dictType),
    method: 'get'
  });
}