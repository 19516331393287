import Layout from '@/layout';
var TrainerAnalyzeRouter = {
  path: '/trainerAnalyze',
  component: Layout,
  redirect: '/trainerAnalyze/list',
  name: 'TrainerAnalyze',
  meta: {
    title: '训练员分析',
    icon: ''
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/trainerAnalyze/list');
    },
    name: 'TrainerAnalyzeList',
    meta: {
      title: '分析列表',
      icon: ''
    }
  }, {
    path: 'detail',
    component: function component() {
      return import('@/views/trainerAnalyze/detail');
    },
    name: 'TrainerAnalyzeDetail',
    meta: {
      title: '分析详情',
      icon: ''
    }
  }]
};
export default TrainerAnalyzeRouter;