import Layout from '@/layout';
var realTimeMonitorRouter = {
  path: '/realTimeMonitor',
  component: Layout,
  redirect: '/realTimeMonitor',
  name: 'RealTimeMonitor',
  meta: {
    title: '实时监控',
    icon: 'video-camera-solid',
    roles: ['admin']
  },
  children: [{
    path: 'monitor',
    name: 'monitor',
    component: function component() {
      return import('@/views/realTimeMonitor/monitor/index');
    },
    meta: {
      title: '实时监控'
    }
  },
  // 添加饼图
  {
    path: 'monitorPie',
    name: 'monitorPie',
    component: function component() {
      return import('@/views/realTimeMonitor/monitor/indexPie');
    },
    meta: {
      title: '监控饼图'
    }
  }]
};
export default realTimeMonitorRouter;