//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Page404',
  data: function data() {
    return {};
  },
  computed: {
    message: function message() {
      return '你不能进入这个页面...';
    }
  },
  methods: {}
};