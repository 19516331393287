//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import parser from '@/components/FormGenerator/components/parser/Parser';
import * as systemGroupDataApi from '@/api/systemGroupData.js';
import * as systemFormConfigApi from '@/api/systemFormConfig.js';
import { Debounce } from '@/utils/validate';
export default {
  // name: "combineEdit"
  components: {
    parser: parser
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Number,
      default: 0 // 0=create 1=edit
    },
    editData: {
      type: Object
    }
  },
  data: function data() {
    return {
      formConf: {
        fields: []
      },
      selfForm: {
        sort: 0,
        status: 0
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetFormConfig();
    this.handlerInitEditData();
  },
  methods: {
    handlerInitEditData: function handlerInitEditData() {
      var _this$editData = this.editData,
        sort = _this$editData.sort,
        status = _this$editData.status;
      this.selfForm.sort = sort;
      this.selfForm.status = status;
    },
    handlerGetFormConfig: function handlerGetFormConfig() {
      var _this = this;
      // 获取表单配置后生成table列
      var _pram = {
        id: this.formData.formId
      };
      systemFormConfigApi.getFormConfigInfo(_pram).then(function (data) {
        _this.formConf = JSON.parse(data.content);
      });
    },
    handlerSubmit: Debounce(function (formValue) {
      this.isCreate === 0 ? this.handlerSave(formValue) : this.handlerEdit(formValue);
    }),
    handlerSave: function handlerSave(formValue) {
      var _this2 = this;
      var _pram = this.buildFormPram(formValue);
      systemGroupDataApi.groupDataSave(_pram).then(function (data) {
        _this2.$message.success('添加数据成功');
        _this2.$emit('hideDialog');
      });
    },
    handlerEdit: function handlerEdit(formValue) {
      var _this3 = this;
      var _pram = this.buildFormPram(formValue);
      systemGroupDataApi.groupDataEdit(_pram, this.editData.id).then(function (data) {
        _this3.$message.success('编辑数据成功');
        _this3.$emit('hideDialog');
      });
    },
    buildFormPram: function buildFormPram(formValue) {
      var _pram = {
        gid: this.formData.id,
        form: {
          fields: [],
          id: this.formData.formId,
          sort: this.selfForm.sort,
          status: this.selfForm.status
        }
      };
      var _fields = [];
      Object.keys(formValue).forEach(function (key) {
        _fields.push({
          name: key,
          title: key,
          value: formValue[key]
        });
      });
      _pram.form.fields = _fields;
      return _pram;
    }
  }
};