var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "from",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.from,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "数据场：" } },
                                [
                                  _c("UserSearchable", {
                                    attrs: { type: "datafieldList" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "dropdownTitle",
                                        fn: function () {
                                          return [
                                            _c("span", [_vm._v("数据场名称")]),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("负责人")]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "dropdown",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(item.name)),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(item.principal)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.from.dataFactoryId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "dataFactoryId", $$v)
                                      },
                                      expression: "from.dataFactoryId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "账户类型：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w_220",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.from.accountType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.from, "accountType", $$v)
                                        },
                                        expression: "from.accountType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.accountTypeDict,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.dictCode,
                                          attrs: {
                                            label: item.dictLabel,
                                            value: item.dictValue,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "开始时间：" } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      type: "datetime",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      placeholder: "选择日期时间",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.from.beginTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "beginTime", $$v)
                                      },
                                      expression: "from.beginTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "结束时间：" } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      type: "datetime",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      placeholder: "选择日期时间",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.from.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "endTime", $$v)
                                      },
                                      expression: "from.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        icon: "el-icon-search",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.search()
                                        },
                                      },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: {
                                        size: "small",
                                        icon: "el-icon-refresh",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset()
                                        },
                                      },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "btn_bt" }, [
                _c(
                  "div",
                  { staticClass: "list" },
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "dashboard-console-grid",
                        attrs: { gutter: 24 },
                      },
                      [
                        _c(
                          "el-col",
                          _vm._b(
                            { staticClass: "ivu-mb" },
                            "el-col",
                            _vm.grid,
                            false
                          ),
                          [
                            _c("el-card", { attrs: { bordered: false } }, [
                              _c("p", { staticClass: "p1" }, [
                                _vm._v("总平仓结果"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(
                                  _vm._s(
                                    parseFloat("" + _vm.exit)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          _vm._b(
                            { staticClass: "ivu-mb" },
                            "el-col",
                            _vm.grid,
                            false
                          ),
                          [
                            _c("el-card", { attrs: { bordered: false } }, [
                              _c("p", { staticClass: "p1" }, [
                                _vm._v("总平仓盈利"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(
                                  _vm._s(
                                    parseFloat("" + _vm.exitProfit)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          _vm._b(
                            { staticClass: "ivu-mb" },
                            "el-col",
                            _vm.grid,
                            false
                          ),
                          [
                            _c("el-card", { attrs: { bordered: false } }, [
                              _c("p", { staticClass: "p1" }, [
                                _vm._v("总平仓亏损"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(
                                  _vm._s(
                                    parseFloat("" + _vm.exitLoss)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          _vm._b(
                            { staticClass: "ivu-mb" },
                            "el-col",
                            _vm.grid,
                            false
                          ),
                          [
                            _c("el-card", { attrs: { bordered: false } }, [
                              _c("p", { staticClass: "p1" }, [
                                _vm._v("总交易手数"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(
                                  _vm._s(
                                    parseFloat("" + _vm.volumeSum)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          _vm._b(
                            { staticClass: "ivu-mb" },
                            "el-col",
                            _vm.grid,
                            false
                          ),
                          [
                            _c("el-card", { attrs: { bordered: false } }, [
                              _c("p", { staticClass: "p1" }, [_vm._v("周期")]),
                              _vm._v(" "),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(_vm._s(_vm.cycle || 0)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          _vm._b(
                            { staticClass: "ivu-mb" },
                            "el-col",
                            _vm.grid,
                            false
                          ),
                          [
                            _c("el-card", { attrs: { bordered: false } }, [
                              _c("p", { staticClass: "p1" }, [
                                _vm._v("总服务费"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(
                                  _vm._s(
                                    parseFloat("" + _vm.feeSum)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          _vm._b(
                            { staticClass: "ivu-mb" },
                            "el-col",
                            _vm.grid,
                            false
                          ),
                          [
                            _c("el-card", { attrs: { bordered: false } }, [
                              _c("p", { staticClass: "p1" }, [
                                _vm._v("净盈亏"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(
                                  _vm._s(
                                    parseFloat("" + _vm.profitAndLoss)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  ) + "\n                "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn_bt" },
                [
                  _c(
                    "el-tabs",
                    {
                      attrs: { type: "card" },
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.active,
                        callback: function ($$v) {
                          _vm.active = $$v
                        },
                        expression: "active",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "总平仓结果", name: "exitImage" } },
                        [
                          _vm.active == "exitImage"
                            ? _c("div", {
                                staticStyle: { width: "100%", height: "400px" },
                                attrs: { id: "exitImage" },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: "总平仓盈利",
                            name: "exitProfitImage",
                          },
                        },
                        [
                          _vm.active == "exitProfitImage"
                            ? _c("div", {
                                staticStyle: { width: "100%", height: "400px" },
                                attrs: { id: "exitProfitImage" },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: { label: "总平仓亏损", name: "exitLossImage" },
                        },
                        [
                          _vm.active == "exitLossImage"
                            ? _c("div", {
                                staticStyle: { width: "100%", height: "400px" },
                                attrs: { id: "exitLossImage" },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: "总交易手数",
                            name: "volumeSumImage",
                          },
                        },
                        [
                          _vm.active == "volumeSumImage"
                            ? _c("div", {
                                staticStyle: { width: "100%", height: "400px" },
                                attrs: { id: "volumeSumImage" },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "dataFactoryName",
                  label: "数据场",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "lines", label: "额度留存", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.lines)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "exit",
                  label: "总平仓结果",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.exit)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "volumeSum",
                  label: "总交易数",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.volumeSum)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "feeSum",
                  label: "总服务费",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.feeSum)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "profitVolumeSum",
                  label: "\t盈利手数",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.profitVolumeSum)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "lossVolumeSum",
                  label: "亏损手数",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.lossVolumeSum)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "winRate", label: "\t胜率", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(Math.floor(scope.row.winRate * 100)) + "%"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "profitSum",
                  label: "\t总盈利",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.profitSum)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "lossSum", label: "总亏损", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.lossSum)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "profitMax",
                  label: "最大盈利",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.profitMax)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "lossMax",
                  label: "最大亏损",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.lossMax)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "profitAverage",
                  label: "平均盈利",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.profitAverage)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "lossAverage",
                  label: "平均亏损",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.lossAverage)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.from.limit,
                  "current-page": _vm.from.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }