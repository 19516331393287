var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "from",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.from,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户：" } },
                                [
                                  _c("UserSearchable", {
                                    model: {
                                      value: _vm.from.userId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "userId", $$v)
                                      },
                                      expression: "from.userId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户搜索：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth w-235px",
                                    attrs: {
                                      placeholder:
                                        "请输入ID或姓名或手机号或邮箱",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.from.keywords,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "keywords", $$v)
                                      },
                                      expression: "from.keywords",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "数据场：" } },
                                [
                                  _c("UserSearchable", {
                                    attrs: { type: "datafieldList" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "dropdownTitle",
                                        fn: function () {
                                          return [
                                            _c("span", [_vm._v("数据场名称")]),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("负责人")]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "dropdown",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(item.name)),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(item.principal)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.from.dataFactoryId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "dataFactoryId", $$v)
                                      },
                                      expression: "from.dataFactoryId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "状态：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.from.approvalStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.from,
                                            "approvalStatus",
                                            $$v
                                          )
                                        },
                                        expression: "from.approvalStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: 1, label: "待审批" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: 2, label: "通过" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: 3, label: "拒绝" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-right from",
                              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        icon: "el-icon-search",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.search()
                                        },
                                      },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: {
                                        size: "small",
                                        icon: "el-icon-refresh",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset()
                                        },
                                      },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: _vm.selected.length == 0,
                                        type: "primary",
                                        size: "small",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.batchCheck()
                                        },
                                      },
                                    },
                                    [_vm._v("批量审批")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  selectable: _vm.selectable,
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "userId", label: "用户UID", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "nickName",
                  label: "用户昵称",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "用户电话", "min-width": "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "email", label: "邮箱", "min-width": "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "dataFactoryName",
                  label: "数据场",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "applyType",
                  label: "审批类型",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.applyType == 1
                          ? _c("el-button", { attrs: { size: "mini" } }, [
                              _vm._v("国际盘"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.applyType == 2
                          ? _c("el-button", { attrs: { size: "mini" } }, [
                              _vm._v("国内盘"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.applyType == 3
                          ? _c("el-button", { attrs: { size: "mini" } }, [
                              _vm._v("国际/国内"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.applyType == 4
                          ? _c("el-button", { attrs: { size: "mini" } }, [
                              _vm._v("实盘"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "approvalStatus",
                  label: "状态",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.approvalStatus == 1
                          ? _c("el-button", { attrs: { size: "mini" } }, [
                              _vm._v("待审批"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.approvalStatus == 2
                          ? _c(
                              "el-button",
                              { attrs: { type: "success", size: "mini" } },
                              [_vm._v("通过")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.approvalStatus == 3
                          ? _c(
                              "el-button",
                              { attrs: { type: "danger", size: "mini" } },
                              [_vm._v("拒绝")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "approvalRemark",
                  label: "审批备注",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "approvalDate",
                  label: "审批时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "200",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.approvalStatus == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleExamine(scope.row)
                                  },
                                },
                              },
                              [_vm._v("审批")]
                            )
                          : scope.row.approvalStatus == 2
                          ? _c("span", [_vm._v("已通过")])
                          : scope.row.approvalStatus == 3
                          ? _c("span", [_vm._v("被拒绝")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.from.limit,
                  "current-page": _vm.from.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.userVisible,
            width: "900px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("user-list", { on: { getTemplateRow: _vm.getTemplateRow } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.userclose()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.usersubmit()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑",
            visible: _vm.dialogVisible,
            width: "800px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: { model: _vm.ruleForm, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.approvalStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "approvalStatus", $$v)
                        },
                        expression: "ruleForm.approvalStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("通过")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("拒绝")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "审批类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.applyType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "applyType", $$v)
                        },
                        expression: "ruleForm.applyType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { disabled: "", label: 1 } }, [
                        _vm._v("国际盘"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { disabled: "", label: 2 } }, [
                        _vm._v("国内盘"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { disabled: "", label: 3 } }, [
                        _vm._v("国际/国内"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { disabled: "", label: 4 } }, [
                        _vm._v("实盘"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注：" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.ruleForm.approvalRemark,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "approvalRemark", $$v)
                      },
                      expression: "ruleForm.approvalRemark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量审批",
            visible: _vm.batchDialogVisible,
            width: "800px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "batchRuleForm",
              attrs: { model: _vm.batchRuleForm, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.batchRuleForm.approvalStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.batchRuleForm, "approvalStatus", $$v)
                        },
                        expression: "batchRuleForm.approvalStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("通过")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("拒绝")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注：" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.batchRuleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.batchRuleForm, "remark", $$v)
                      },
                      expression: "batchRuleForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.batchClose()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.batchSubmit()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }