"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _combineEdit = _interopRequireDefault(require("../../maintain/devconfig/combineEdit"));
var _dataField = require("@/api/dataField");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "carousel",
  components: {
    edit: _combineEdit.default
  },
  data: function data() {
    return {
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      //48 95为移动端轮播图表单ID
      gid: 48,
      formId: 95,
      tableFrom: {
        page: 1,
        limit: 15
      },
      loading: false,
      tableList: [],
      total: 0,
      dialogVisible: false,
      editDataConfig: {}
    };
  },
  mounted: function mounted() {
    this.getlist();
  },
  methods: {
    add: function add() {
      this.ruleForm = {
        id: "",
        version: "",
        // 版本号
        isNew: 1,
        // 是否为线上最新版本（1是 2否）
        platform: 1,
        // 平台类型（1安卓 2苹果 3PC）
        isForce: 1,
        // 是否强制（1强制  2非强制）
        url: "",
        // 下载地址
        info: "" // 升级信息（升级备注）
      };
      this.dialogVisible = true;
    },
    edit: function edit(row, isCreate) {
      this.ruleForm = row;
      this.ruleForm.isCreate = isCreate;
      this.dialogVisible = true;
    },
    handleDel: function handleDel(row) {
      var _this = this;
      this.$confirm("确认删除？", '提示').then(function (_) {
        console.log("删除", row);
        (0, _dataField.bannerDelete)(row).then(function (res) {
          console.log(res);
          _this.$message.success("删除成功");
          _this.getlist();
        }).catch(function (err) {
          console.log(err);
          _this.$message.error(err);
        });
      }).catch(function (_) {});
    },
    close: function close() {
      this.ruleForm = {
        id: "",
        version: "",
        // 版本号
        isNew: "1",
        // 是否为线上最新版本（1是 2否）
        platform: "1",
        // 平台类型（1安卓 2苹果 3PC）
        isForce: "1",
        // 是否强制（1强制  2非强制）
        url: "",
        // 下载地址
        info: "" // 升级信息（升级备注）
      };
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          if (_this2.ruleForm.id) {
            console.log("编辑", _this2.ruleForm);
            (0, _dataField.bannerEdit)(_this2.ruleForm).then(function (res) {
              console.log(res);
              _this2.close();
              _this2.$message.success("编辑成功");
              _this2.getlist();
            }).catch(function (err) {
              console.log(err);
              _this2.$message.error(err);
            });
          } else {
            console.log("新增", _this2.ruleForm);
            var data = {
              version: _this2.ruleForm.version,
              isNew: _this2.ruleForm.isNew,
              platform: _this2.ruleForm.platform,
              isForce: _this2.ruleForm.isForce,
              url: _this2.ruleForm.url,
              info: _this2.ruleForm.info
            };
            (0, _dataField.bannerAdd)(data).then(function (res) {
              console.log(res);
              _this2.close();
              _this2.$message.success("新增成功");
              _this2.getlist();
            }).catch(function (err) {
              console.log(err);
              _this2.$message.error(err);
            });
          }
        } else {
          _this2.$message.error("请填写完整信息");
        }
      });
    },
    // 版本信息列表
    getlist: function getlist() {
      var _this3 = this;
      this.loading = true;
      (0, _dataField.bannerList)(this.tableFrom).then(function (data) {
        console.log("APP版本列表", data.list);
        var _selfList = [];
        data.list.forEach(function (_lItem) {
          _lItem.value = JSON.parse(_lItem.value);
          var _fields = _lItem.value.fields;
          var _rowData = {};
          _fields.map(function (item) {
            _rowData[item.name] = item.value;
          });
          _rowData.id = _lItem.id;
          _rowData.sort = _lItem.sort;
          _rowData.status = _lItem.status;
          _selfList.push(_rowData);
        });
        _this3.tableList = _selfList;
        _this3.total = data.total;
        // this.tableList = res.list
        // .map((item) =>
        //   JSON.parse(item.value).fields.filter(
        //     (field) => field.name === "pic"
        //   )
        // )
        // .flat();

        console.log("🚀🚀🚀 ~ .then ~ this.tableList:", _this3.tableList);
        // this.total = res.total;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getlist();
    },
    changeStatus: function changeStatus(row) {
      var _this4 = this;
      var data = this.buildFormPram(row, row.status);
      (0, _dataField.bannerEdit)(data, {
        id: row.id
      }).then(function (res) {
        console.log(res);
        _this4.$message.success("修改成功");
        _this4.getlist();
      }).catch(function (err) {
        console.log(err);
        _this4.$message.error(err);
      });
    },
    buildFormPram: function buildFormPram(formValue, status) {
      var _pram = {
        gid: this.gid,
        form: {
          fields: [],
          id: this.formId,
          status: status
        }
      };
      var _fields = [];
      Object.keys(formValue).forEach(function (key) {
        _fields.push({
          name: key,
          title: key,
          value: formValue[key]
        });
      });
      _pram.form.fields = _fields;
      return _pram;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getlist();
    },
    handlerHideDia: function handlerHideDia() {
      this.getlist();
      this.dialogVisible = false;
    }
  }
};