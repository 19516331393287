//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import echarts from "echarts";
import { datafieldGetOption, indexIndexList, indexCommodityList } from "@/api/dataField";
import UserSearchable from "@/components/UserSearchable";
import { getDictData } from "@/api/transaction";
export default {
  components: {
    UserSearchable: UserSearchable
  },
  name: "Dashboard",
  data: function data() {
    return {
      accountTypeDict: [],
      labelPosition: "right",
      options: [],
      from: {
        accountType: "",
        dataFactoryId: "",
        beginTime: "",
        endTime: "",
        page: 1,
        limit: 15
      },
      grid: {
        xl: 3,
        lg: 3,
        md: 6,
        sm: 8,
        xs: 8
      },
      active: "exitImage",
      lines: "",
      //额度
      exit: "",
      //	总平仓结果
      exitImage: {},
      // 总平仓结果图
      exitProfit: "",
      // 总平仓盈利
      exitProfitImage: {},
      // 总平仓盈利图
      exitLoss: "",
      // 总平仓亏损
      exitLossImage: {},
      // 总平仓亏损图
      volumeSum: "",
      // 总交易手数
      volumeSumImage: {},
      // 总交易手数图
      cycle: "",
      // 周期
      feeSum: "",
      // 总服务费
      profitAndLoss: "",
      // 净盈亏
      loading: false,
      tableData: {
        data: [],
        total: 0
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    // console.log(888888)
    datafieldGetOption({}).then(function (res) {
      console.log("数据场全列表8888", res);
      // console.log(8888889999999999999999)
      _this.options = res;
    }).catch(function (err) {
      console.log(err);
    });

    //获取字典数据
    getDictData('yj_trader_type').then(function (res) {
      console.log('账户类型字典数据：', res);
      _this.accountTypeDict = res;
      _this.from.accountType = res[0].dictValue;
      _this.getIndexList();
      _this.getCommodityList();
    }).catch(function (res) {
      console.log(res);
    });
  },
  methods: {
    search: function search() {
      console.log(this.from);
      this.from.page = 1;
      this.from.limit = 15;
      this.getIndexList();
      this.getCommodityList();
    },
    reset: function reset() {
      this.from = {
        accountType: this.accountTypeDict[0].dictValue,
        dataFactoryId: "",
        beginTime: "",
        endTime: "",
        page: 1,
        limit: 15
      };
      this.getIndexList();
      this.getCommodityList();
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
      this.getIndexList();
    },
    getIndexList: function getIndexList() {
      var _this2 = this;
      var data = {
        accountType: this.from.accountType,
        dataFactoryId: this.from.dataFactoryId,
        beginTime: this.from.beginTime,
        endTime: this.from.endTime
      };
      indexIndexList(data).then(function (res) {
        console.log("图形化统计", res);
        _this2.exit = res.exit; //	总平仓结果
        _this2.lines = res.lines; //	额度
        _this2.exitImage = res.exitImage; // 总平仓结果图
        _this2.exitProfit = res.exitProfit; // 总平仓盈利
        _this2.exitProfitImage = res.exitProfitImage; // 总平仓盈利图
        _this2.exitLoss = res.exitLoss; // 总平仓亏损
        _this2.exitLossImage = res.exitLossImage; // 总平仓亏损图
        _this2.volumeSum = res.volumeSum; // 总交易手数
        _this2.volumeSumImage = res.volumeSumImage; // 总交易手数图
        _this2.cycle = res.cycle; // 周期
        _this2.feeSum = res.feeSum; // 总服务费
        _this2.profitAndLoss = res.profitAndLoss; // 	净盈亏
        if (_this2.active == "exitImage") {
          var exitImageChart = echarts.init(document.getElementById("exitImage"));
          var exitImageoption = {
            title: {
              text: "总平仓结果图"
            },
            tooltip: {
              trigger: "axis"
            },
            legend: {
              data: ["当日数据", "累计数据"]
            },
            grid: {
              left: "2%",
              right: "2%",
              bottom: "2%",
              containLabel: true
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: _this2.exitImage.timeline
            },
            yAxis: {
              type: "value"
            },
            series: [{
              name: "当日数据",
              type: "line",
              stack: "Total",
              data: _this2.exitImage.dataDay
            }, {
              name: "累计数据",
              type: "line",
              stack: "Total",
              data: _this2.exitImage.dataSum
            }]
          };
          exitImageChart.setOption(exitImageoption);
        } else if (_this2.active == "exitProfitImage") {
          var exitProfitImageChart = echarts.init(document.getElementById("exitProfitImage"));
          var exitProfitImageoption = {
            title: {
              text: "总平仓盈利图"
            },
            tooltip: {
              trigger: "axis"
            },
            legend: {
              data: ["当日数据", "累计数据"]
            },
            grid: {
              left: "2%",
              right: "2%",
              bottom: "2%",
              containLabel: true
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: _this2.exitProfitImage.timeline
            },
            yAxis: {
              type: "value"
            },
            series: [{
              name: "当日数据",
              type: "line",
              stack: "Total",
              data: _this2.exitProfitImage.dataDay
            }, {
              name: "累计数据",
              type: "line",
              stack: "Total",
              data: _this2.exitProfitImage.dataSum
            }]
          };
          exitProfitImageChart.setOption(exitProfitImageoption);
        } else if (_this2.active == "exitLossImage") {
          var exitLossImageChart = echarts.init(document.getElementById("exitLossImage"));
          var exitLossImageoption = {
            title: {
              text: "总平仓亏损图"
            },
            tooltip: {
              trigger: "axis"
            },
            legend: {
              data: ["当日数据", "累计数据"]
            },
            grid: {
              left: "2%",
              right: "2%",
              bottom: "2%",
              containLabel: true
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: _this2.exitLossImage.timeline
            },
            yAxis: {
              type: "value"
            },
            series: [{
              name: "当日数据",
              type: "line",
              stack: "Total",
              data: _this2.exitLossImage.dataDay
            }, {
              name: "累计数据",
              type: "line",
              stack: "Total",
              data: _this2.exitLossImage.dataSum
            }]
          };
          exitLossImageChart.setOption(exitLossImageoption);
        } else if (_this2.active == "volumeSumImage") {
          var volumeSumImageChart = echarts.init(document.getElementById("volumeSumImage"));
          var volumeSumImageoption = {
            title: {
              text: "总交易手数图"
            },
            tooltip: {
              trigger: "axis"
            },
            legend: {
              data: ["当日数据", "累计数据"]
            },
            grid: {
              left: "2%",
              right: "2%",
              bottom: "2%",
              containLabel: true
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: _this2.volumeSumImage.timeline
            },
            yAxis: {
              type: "value"
            },
            series: [{
              name: "当日数据",
              type: "line",
              stack: "Total",
              data: _this2.volumeSumImage.dataDay
            }, {
              name: "累计数据",
              type: "line",
              stack: "Total",
              data: _this2.volumeSumImage.dataSum
            }]
          };
          volumeSumImageChart.setOption(volumeSumImageoption);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getCommodityList: function getCommodityList() {
      var _this3 = this;
      var data = {
        accountType: this.from.accountType,
        dataFactoryId: this.from.dataFactoryId,
        beginTime: this.from.beginTime,
        endTime: this.from.endTime,
        page: this.from.page,
        limit: this.from.limit
      };
      this.loading = true;
      indexCommodityList(data).then(function (res) {
        console.log("数据场列表统计", res);
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getCommodityList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getCommodityList();
    }
  }
};