"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addKefu = addKefu;
exports.addTenantInfo = addTenantInfo;
exports.delKefu = delKefu;
exports.deleteBatchTenantInfo = deleteBatchTenantInfo;
exports.deleteTenantInfo = deleteTenantInfo;
exports.getAdminTenantPageList = getAdminTenantPageList;
exports.getCustomerList = getCustomerList;
exports.getTenantIdList = getTenantIdList;
exports.getTenantList = getTenantList;
exports.getTenantUserList = getTenantUserList;
exports.resetCache = resetCache;
exports.saveAdminTenant = saveAdminTenant;
exports.updateKefu = updateKefu;
exports.updateTenantInfo = updateTenantInfo;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/*
 * 租户列表
 */
function getTenantList(params) {
  return (0, _request.default)({
    url: '/admin/sys/tenant/list',
    method: 'get',
    params: params
  });
}

/*
 * 新增租户
 */
function addTenantInfo(data) {
  return (0, _request.default)({
    url: '/admin/sys/tenant/add',
    method: 'post',
    data: data
  });
}

/*
 * 修改租户
 */
function updateTenantInfo(data) {
  return (0, _request.default)({
    url: '/admin/sys/tenant/edit',
    method: 'post',
    data: data
  });
}

/*
 * 删除租户
 */
function deleteTenantInfo(id) {
  return (0, _request.default)({
    url: "/admin/sys/tenant/delete?id=".concat(id),
    method: 'post'
  });
}

/*
 * 批量删除租户
 */
function deleteBatchTenantInfo(data) {
  return (0, _request.default)({
    url: "/admin/sys/tenant/deleteBatch?ids=".concat(data),
    method: 'delete'
  });
}

/*
 * 租户用户列表
 */
function getTenantUserList(params) {
  return (0, _request.default)({
    url: '/admin/sys/tenant/getTenantUserList',
    method: 'get',
    params: params
  });
}

/*
 * 根数剧场列表
 */
function getAdminTenantPageList(params) {
  return (0, _request.default)({
    url: '/admin/sys/tenant/getAdminTenantPageList',
    method: 'get',
    params: params
  });
}

/*
 * 新增数据场
 */
function saveAdminTenant(data) {
  return (0, _request.default)({
    url: '/admin/sys/tenant/saveAdminTenant',
    method: 'post',
    data: data
  });
}

/*
 * 租户下拉框
 */
function getTenantIdList(data) {
  return (0, _request.default)({
    url: '/admin/sys/tenant/getOption',
    method: 'post',
    data: data
  });
}

/*
 * 刷新缓存
 */
function resetCache(data) {
  return (0, _request.default)({
    url: '/admin/sys/tenant/resetCache',
    method: 'post',
    data: data
  });
}

/*
 * 客服列表
 */
function getCustomerList(data) {
  return (0, _request.default)({
    url: '/admin/customerservice/list',
    method: 'get',
    data: data
  });
}

/*
 * 添加客服
 */
function addKefu(data) {
  return (0, _request.default)({
    url: '/admin/customerservice/save',
    method: 'post',
    data: data
  });
}
/*
 * 修改客服
 */
function updateKefu(data) {
  return (0, _request.default)({
    url: '/admin/customerservice/update',
    method: 'post',
    data: data
  });
}

/*
 * 删除客服
 */
function delKefu(data) {
  return (0, _request.default)({
    url: '/admin/customerservice/delete',
    method: 'post',
    data: data
  });
}