//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { exchangecontractGetOption, getDictData } from '@/api/transaction';
import { reportClosingReport, reportClosingReportExport } from '@/api/reportQuery';
import UserSearchable from "@/components/UserSearchable";
export default {
  components: {
    UserSearchable: UserSearchable
  },
  data: function data() {
    return {
      options: [],
      labelPosition: 'right',
      from: {
        accountType: "",
        userId: "",
        userName: '',
        contractId: '',
        orderId: '',
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      accountTypeDict: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    exchangecontractGetOption().then(function (res) {
      console.log('合约全列表', res);
      _this.options = res;
      // this.getlist()
    }).catch(function (err) {
      console.log(err);
    });

    //获取字典数据
    getDictData('yj_trader_type').then(function (res) {
      console.log('账户类型字典数据：', res);
      _this.accountTypeDict = res;
      _this.from.accountType = res[0].dictValue;
      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });
  },
  methods: {
    selectDictType: function selectDictType(dictValue) {
      var face = this.accountTypeDict.find(function (res) {
        return res.dictValue == JSON.stringify(dictValue);
      });
      if (face) return face.dictLabel;
    },
    exportTable: function exportTable() {
      var _this2 = this;
      reportClosingReportExport(this.from).then(function (res) {
        console.log('导出表格', res);
        window.open(res.filename);
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err);
      });
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        accountType: this.accountTypeDict[0].dictValue,
        userId: "",
        userName: '',
        contractId: '',
        orderId: '',
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this3 = this;
      this.loading = true;
      reportClosingReport(this.from).then(function (res) {
        console.log(res);
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};