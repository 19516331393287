var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "from",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.from,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "数据场：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入数据场",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.from.dataFactoryName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.from,
                                          "dataFactoryName",
                                          $$v
                                        )
                                      },
                                      expression: "from.dataFactoryName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "账户/用户名：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入账户/用户名",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.from.userName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "userName", $$v)
                                      },
                                      expression: "from.userName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "账户类型：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w_220",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.from.accountType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.from, "accountType", $$v)
                                        },
                                        expression: "from.accountType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.accountTypeDict,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.dictCode,
                                          attrs: {
                                            label: item.dictLabel,
                                            value: item.dictValue,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "合约：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.from.contractId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.from, "contractId", $$v)
                                        },
                                        expression: "from.contractId",
                                      },
                                    },
                                    _vm._l(_vm.options, function (item) {
                                      return _c("el-option", {
                                        key: item.contractName,
                                        attrs: {
                                          label: item.contractName,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "开始时间：" } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      type: "datetime",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      placeholder: "选择日期",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.from.beginTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "beginTime", $$v)
                                      },
                                      expression: "from.beginTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "结束时间：" } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      type: "datetime",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      placeholder: "选择日期",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.from.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.from, "endTime", $$v)
                                      },
                                      expression: "from.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-right from",
                              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: {
                                        icon: "el-icon-search",
                                        size: "small",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.search()
                                        },
                                      },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: {
                                        icon: "el-icon-refresh",
                                        size: "small",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset()
                                        },
                                      },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn_bt" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { icon: "el-icon-top", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.exportTable()
                        },
                      },
                    },
                    [_vm._v("导出表格")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "dataFactoryName",
                  label: "数据场",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: "账户/用户名",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "reportUserContractVoList",
                  label: "合约",
                  "min-width": "600",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              border: "",
                              data: scope.row.reportUserContractVoList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "symbol", label: "合约" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "volume", label: "手数" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "exitResult", label: "平仓结果" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.exitResult == 0
                                          ? _c("span", [_vm._v("0")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.exitResult < 0
                                          ? _c("span", { staticClass: "g" }, [
                                              _vm._v(
                                                _vm._s(
                                                  parseFloat(
                                                    "" + scope.row.exitResult
                                                  )
                                                    .toFixed(2)
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.exitResult > 0
                                          ? _c("span", { staticClass: "r" }, [
                                              _vm._v(
                                                _vm._s(
                                                  parseFloat(
                                                    "" + scope.row.exitResult
                                                  )
                                                    .toFixed(2)
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "fee",
                                label: "服务费",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              parseFloat("" + scope.row.fee)
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "beginTime", label: "开始时间" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "endTime", label: "结束时间" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sunVolume",
                  label: "总手数",
                  "min-width": "70",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sunExitResult",
                  label: "总平仓结果",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.sunExitResult == 0
                          ? _c("span", [_vm._v("0.00")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.sunExitResult < 0
                          ? _c("span", { staticClass: "g" }, [
                              _vm._v(
                                _vm._s(
                                  parseFloat("" + scope.row.sunExitResult)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.sunExitResult > 0
                          ? _c("span", { staticClass: "r" }, [
                              _vm._v(
                                _vm._s(
                                  parseFloat("" + scope.row.sunExitResult)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sunFee", label: "总服务费", "min-width": "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              parseFloat("" + scope.row.sunFee)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.from.limit,
                  "current-page": _vm.from.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }