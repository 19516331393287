"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UserSearchable = _interopRequireDefault(require("@/components/UserSearchable"));
var _dataField = require("@/api/dataField");
var _transaction = require("@/api/transaction");
var _user = require("@/api/user");
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "trader",
  components: {
    UserSearchable: _UserSearchable.default
  },
  data: function data() {
    return {
      labelPosition: "right",
      options: [],
      from: {
        name: "",
        principal: "",
        adminId: "",
        status: 1,
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      dictData: [],
      valueDictData: [],
      selection: [],
      users: [],
      currentPage: 1,
      pageSize: 15,
      dialogVisible: false,
      dialogVisible2: false,
      ruleForm: {
        dataFactoryId: "",
        adminId: "",
        uid: ""
      },
      ruleForm2: {
        id: null,
        updateField: 1,
        value: null
      },
      rules: {
        uid: [{
          required: true,
          message: "请选择管理人员",
          trigger: "change"
        }]
      },
      rules2: {
        recharge: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }]
      },
      //
      options2: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value2: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getDict();
    (0, _dataField.adminGetOption)({}).then(function (res) {
      // console.log("获取管理员列表", res);
      _this.options = res;
      _this.getlist();
    }).catch(function (err) {
      console.log(err);
    });
  },
  methods: {
    checkPermi: _permission.checkPermi,
    search: function search() {
      console.log(this.from);
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        name: "",
        principal: "",
        adminId: "",
        status: 1,
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this2 = this;
      this.loading = true;
      (0, _dataField.datafieldList)(this.from).then(function (res) {
        console.log(res);
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this2.loading = false;
      });
    },
    selectValueDictType: function selectValueDictType(dictValue) {
      var face = this.valueDictData.find(function (res) {
        return res.dictValue == JSON.stringify(dictValue);
      });
      if (face) return face.dictLabel;
    },
    // selectDictType(dictValue) {
    //   let face = this.dictData.find((res) => {
    //     return res.dictValue == JSON.stringify(dictValue);
    //   });
    //   if (face) return face.dictLabel;
    // },
    // 字典数据
    getDict: function getDict() {
      var _this3 = this;
      // getDictData("recharge_flow_type")
      //   .then((res) => {
      //     console.log("字典变量数据", res);
      //     this.dictData = res;
      //   })
      //   .catch((res) => {
      //     console.log(res);
      //   });
      (0, _transaction.getDictData)("data_factory_level").then(function (res) {
        console.log("数据场级别字典变量数据", res);
        _this3.valueDictData = res;
      }).catch(function (res) {
        console.log(res);
      });
    },
    handleRechargeChange: function handleRechargeChange(row) {
      console.log('row', row);
      this.ruleForm2.id = row.id;
      this.ruleForm2.value = row.rechargeFlowType + '';
      this.dialogVisible2 = true;

      // datafieldInfo(row.id)
      //   .then((res) => {
      //     console.log("变更入金审批流程", res);
      //     this.ruleForm2 = {
      //       dataFactoryId: res.id,
      //       adminId: res.adminId,
      //       uid: "",
      //     };
      //     console.log(this.ruleForm);

      //     // this.$refs.refreshData.remoteMethod()

      //     this.dialogVisible2 = true;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.$message.error(err);
      //   });
    },
    handleChange: function handleChange(row) {
      var _this4 = this;
      (0, _dataField.datafieldInfo)(row.id).then(function (res) {
        console.log("变更管理人员", res);
        _this4.ruleForm = {
          dataFactoryId: res.id,
          adminId: res.adminId,
          uid: ""
        };
        console.log(_this4.ruleForm);

        // this.$refs.refreshData.remoteMethod()

        _this4.dialogVisible = true;
      }).catch(function (err) {
        console.log(err);
        _this4.$message.error(err);
      });
    },
    close: function close() {
      this.ruleForm = {
        dataFactoryId: "",
        adminId: "",
        uid: ""
      };
      this.dialogVisible = false;
    },
    close2: function close2() {
      this.ruleForm2 = {
        id: null,
        updateField: 1,
        value: null
      };
      this.dialogVisible2 = false;
    },
    loadUsers: function loadUsers(query) {
      if (query !== "") {
        this.currentPage = 1;
        this.users = [];
        this.getuserlist(query);
      } else {
        this.users = [];
      }
    },
    getuserlist: function getuserlist(query) {
      var _this5 = this;
      var data = {
        page: this.currentPage,
        limit: this.pageSize,
        keywords: query
      };
      (0, _user.userListApi)(data).then(function (res) {
        console.log("用户列表", res);
        _this5.users = _this5.users.concat(res.list);
      }).catch(function (err) {
        console.log(err);
      });
    },
    submit: function submit() {
      var _this6 = this;
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          console.log("变更管理员", _this6.ruleForm);
          (0, _dataField.datafieldTransfer)(_this6.ruleForm).then(function (res) {
            console.log(res);
            _this6.close();
            _this6.$message.success("变更成功");
            _this6.getlist();
          }).catch(function (err) {
            console.log(err);
            _this6.$message.error(err);
          });
        } else {
          _this6.$message.error("请填写完整信息");
        }
      });
    },
    submit2: function submit2() {
      var _this7 = this;
      this.$refs["ruleForm2"].validate(function (valid) {
        if (valid) {
          (0, _dataField.updateRechargeFlowType)(_this7.ruleForm2).then(function (res) {
            console.log(res);
            _this7.close2();
            _this7.$message.success("变更成功");
            _this7.getlist();
          }).catch(function (err) {
            console.log(err);
            _this7.$message.error(err);
          });
        } else {
          _this7.$message.error("请填写完整信息");
        }
      });
    },
    // 选择
    onSelectTab: function onSelectTab(selection) {
      var _this8 = this;
      this.selection = [];
      selection.forEach(function (item) {
        _this8.selection.push(item.id);
      });
      console.log(this.selection);
    },
    allDel: function allDel() {
      var _this9 = this;
      if (this.selection.length == 0) {
        return this.$message.error("至少选择一项");
      }
      console.log(this.selection);
      this.$confirm("确认删除？", '提示').then(function (_) {
        (0, _dataField.datafieldDelete)(_this9.selection).then(function (res) {
          console.log(res);
          _this9.$message.success("删除成功");
          _this9.getlist();
        }).catch(function (err) {
          console.log(err);
          _this9.$message.error(err);
        });
      }).catch(function (_) {});
    },
    exportTable: function exportTable() {
      var _this10 = this;
      (0, _dataField.datafieldExport)(this.from).then(function (res) {
        console.log("导出表格", res);
        window.open(res.filename);
      }).catch(function (err) {
        console.log(err);
        _this10.$message.error(err);
      });
    },
    handleDel: function handleDel(row) {
      var _this11 = this;
      this.$confirm("确认删除？", '提示').then(function (_) {
        console.log("删除", row);
        (0, _dataField.datafieldDelete)([row.id]).then(function (res) {
          console.log(res);
          _this11.$message.success("删除成功");
          _this11.getlist();
        }).catch(function (err) {
          console.log(err);
          _this11.$message.error(err);
        });
      }).catch(function (_) {});
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};