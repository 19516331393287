"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _userList = _interopRequireDefault(require("@/components/userList"));
var _UserSearchable = _interopRequireDefault(require("@/components/UserSearchable"));
var _user = require("@/api/user");
var _dataField = require("@/api/dataField");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'accountapply',
  components: {
    userList: _userList.default,
    UserSearchable: _UserSearchable.default
  },
  data: function data() {
    return {
      batchDialogVisible: false,
      selected: [],
      options: [],
      labelPosition: 'right',
      userVisible: false,
      from: {
        userId: '',
        keywords: "",
        dataFactoryId: '',
        approvalStatus: 1,
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      dialogVisible: false,
      ruleForm: {
        id: '',
        userId: '',
        applyType: '',
        approvalStatus: '',
        approvalRemark: ''
      },
      batchRuleForm: {
        accountApplyIds: [],
        approvalStatus: '',
        remark: ''
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dataField.datafieldGetOption)({}).then(function (res) {
      console.log('数据场全列表', res);
      _this.options = res;
    }).catch(function (err) {
      console.log(err);
    });
    this.getlist();
  },
  methods: {
    selectable: function selectable(row) {
      return row.approvalStatus == 1;
    },
    handleSelectionChange: function handleSelectionChange(e) {
      this.selected = e;
    },
    batchCheck: function batchCheck() {
      console.log(this.selected);
      var ids = this.selected.map(function (v) {
        return v.id;
      });
      console.log('ids', ids);
      this.batchRuleForm.accountApplyIds = ids;
      this.batchDialogVisible = true;
    },
    selectUser: function selectUser() {
      this.userVisible = true;
    },
    getTemplateRow: function getTemplateRow(row) {
      this.from.userId = row.uid;
    },
    userclose: function userclose() {
      this.from.userId = '';
      this.userVisible = false;
    },
    usersubmit: function usersubmit() {
      this.userVisible = false;
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        userId: '',
        keywords: "",
        dataFactoryId: '',
        approvalStatus: 1,
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this2 = this;
      this.loading = true;
      (0, _user.accountapplyList)(this.from).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this2.loading = false;
      });
    },
    handleExamine: function handleExamine(row) {
      var _this3 = this;
      (0, _user.accountapplyInfo)(row.id).then(function (res) {
        console.log(res);
        _this3.ruleForm.id = res.id;
        _this3.ruleForm.userId = res.userId;
        _this3.ruleForm.applyType = res.applyType;
        _this3.dialogVisible = true;
      }).catch(function (err) {
        console.log(err);
      });
    },
    batchClose: function batchClose() {
      // this.batchRuleForm = {
      //   id: '',
      //   userId: '',
      //   applyType: '',
      //   approvalStatus: '',
      //   approvalRemark: '',
      // }
      this.batchDialogVisible = false;
    },
    batchSubmit: function batchSubmit() {
      var _this4 = this;
      console.log(this.ruleForm);
      if (!this.batchRuleForm.approvalStatus) {
        return this.$message.error('请选择审批状态');
      }
      (0, _user.accountapplyUpdate)(this.batchRuleForm).then(function (res) {
        _this4.batchClose();
        _this4.$message.success('操作成功');
        _this4.getlist();
      }).catch(function (err) {
        console.log(err);
        _this4.$message.error(err);
      });
    },
    close: function close() {
      this.ruleForm = {
        id: '',
        userId: '',
        applyType: '',
        approvalStatus: '',
        approvalRemark: ''
      };
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this5 = this;
      console.log(this.ruleForm);
      if (!this.ruleForm.approvalStatus) {
        return this.$message.error('请选择审批状态');
      }
      this.ruleForm.accountApplyIds = [this.ruleForm.id];
      (0, _user.accountapplyUpdate)(this.ruleForm).then(function (res) {
        _this5.close();
        _this5.$message.success('操作成功');
        _this5.getlist();
      }).catch(function (err) {
        console.log(err);
        _this5.$message.error(err);
      });
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};