"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tenant = require("@/api/tenant");
var _user = _interopRequireDefault(require("./user.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "TenantList",
  components: {
    user: _user.default
  },
  data: function data() {
    return {
      userTenantId: null,
      userDialogVisible: false,
      labelPosition: "right",
      from: {
        name: "",
        status: 1,
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      selection: [],
      dialogVisible: false,
      ruleForm: {
        id: "",
        name: "",
        status: 1
      },
      rules: {
        name: [{
          required: true,
          message: "请填写租户名称",
          trigger: "blur"
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getlist();
  },
  methods: {
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        name: "",
        status: 1,
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this = this;
      this.loading = true;
      (0, _tenant.getTenantList)(this.from).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.loading = false;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    add: function add() {
      this.ruleForm = {
        id: "",
        name: "",
        status: 1
      };
      this.dialogVisible = true;
    },
    close: function close() {
      this.ruleForm = {
        id: "",
        name: "",
        status: 1
      };
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          if (_this2.ruleForm.id) {
            var data = {
              id: _this2.ruleForm.id,
              name: _this2.ruleForm.name,
              status: _this2.ruleForm.status
            };
            (0, _tenant.updateTenantInfo)(data).then(function (res) {
              console.log(res);
              _this2.close();
              _this2.$message.success("编辑成功");
              _this2.getlist();
            }).catch(function (err) {
              console.log(err);
              _this2.$message.error(err);
            });
          } else {
            var _data = {
              name: _this2.ruleForm.name,
              status: _this2.ruleForm.status
            };
            (0, _tenant.addTenantInfo)(_data).then(function (res) {
              _this2.close();
              _this2.$message.success("新增成功");
              _this2.getlist();
            }).catch(function (err) {
              _this2.$message.error(err);
            });
          }
        } else {
          _this2.$message.error("请填写完整信息");
        }
      });
    },
    // 选择
    onSelectTab: function onSelectTab(selection) {
      var _this3 = this;
      this.selection = [];
      selection.forEach(function (item) {
        _this3.selection.push(item.id);
      });
      console.log(this.selection);
    },
    goResetCache: function goResetCache() {
      var _this4 = this;
      (0, _tenant.resetCache)().then(function (res) {
        console.log(res);
        _this4.$message.success("刷新成功");
        _this4.getlist();
      }).catch(function (err) {
        console.log(err);
        _this4.$message.error(err);
      });
    },
    allDel: function allDel() {
      var _this5 = this;
      if (this.selection.length == 0) {
        return this.$message.error("至少选择一项");
      }
      console.log(this.selection);
      this.$confirm("确认删除？").then(function (_) {
        (0, _tenant.deleteBatchTenantInfo)(_this5.selection).then(function (res) {
          console.log(res);
          _this5.$message.success("删除成功");
          _this5.getlist();
        }).catch(function (err) {
          console.log(err);
          _this5.$message.error(err);
        });
      }).catch(function (_) {});
    },
    handleEdit: function handleEdit(row) {
      console.log("编辑", row);
      var ruleForm = JSON.parse(JSON.stringify(row));
      this.ruleForm = ruleForm;
      this.dialogVisible = true;
    },
    handleDel: function handleDel(row) {
      var _this6 = this;
      this.$confirm("确认删除？").then(function (_) {
        (0, _tenant.deleteTenantInfo)(row.id).then(function (res) {
          console.log(res);
          _this6.$message.success("删除成功");
          _this6.getlist();
        }).catch(function (err) {
          console.log(err);
          _this6.$message.error(err);
        });
      }).catch(function (_) {});
    },
    handleUserInfo: function handleUserInfo(info) {
      this.userTenantId = info.id;
      this.userDialogVisible = true;
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};