//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { reportGetReportUserTrade } from '@/api/dataField';
import echartsFrom from '@/components/echarts/index';
export default {
  components: {
    echartsFrom: echartsFrom
  },
  data: function data() {
    return {
      grid: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 24
      },
      yAxisData: [],
      series: [],
      xAxis: [],
      legendData: [],
      line: 'line',
      userXAxis: [],
      userSeries: [],
      // 订单
      orderCount: '',
      // 订单总数
      addOrderPriceGraph: {},
      // 订单图
      // 交易员
      userOnLineCount: '',
      //当前在线人数
      dayUserCount: '',
      //日活跃人数
      userCount: '',
      // 总人数
      addUserGraph: {}
    };
  },
  mounted: function mounted() {
    this.yAxisData = [{
      type: 'value',
      name: '金额',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        textStyle: {
          color: '#7F8B9C'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#F5F7F9'
        }
      }
    }, {
      type: 'value',
      name: '数量',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        textStyle: {
          color: '#7F8B9C'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#F5F7F9'
        }
      }
    }];
    this.legendData = ['订单金额', '订单数'];
    this.GetReportUserTrade();
  },
  methods: {
    GetReportUserTrade: function GetReportUserTrade() {
      var _this = this;
      reportGetReportUserTrade().then(function (res) {
        console.log('用户统计及交易统计', res);
        _this.orderCount = res.orderCount;
        _this.addOrderPriceGraph = res.addOrderPriceGraph;
        _this.userOnLineCount = res.userOnLineCount;
        _this.dayUserCount = res.dayUserCount;
        _this.userCount = res.userCount;
        _this.addUserGraph = res.addUserGraph;

        // 订单统计
        // 订单数据
        // this.series = [{
        //   name:"订单金额",
        //   type:"bar",
        //   itemStyle:{
        //     normal:{
        //         color:'#5B8FF9',
        //     }
        //   },
        //   data: pices
        // },
        //   {
        //     name:"订单数",
        //     type:"line",
        //     smooth: true,
        //     itemStyle:{
        //       normal:{
        //         color:'#4BCAD5',
        //     }
        //     },
        //     yAxisIndex: 1,
        //     data: qualitys
        //   }]
        // 订单时间
        //this.xAxis = []

        // 用户统计
        _this.userXAxis = _this.addUserGraph.timeline;
        _this.userSeries = [{
          data: _this.addUserGraph.dataDay,
          name: '人数（人）',
          type: 'line',
          tooltip: true,
          smooth: true,
          symbol: 'none',
          areaStyle: {
            normal: {
              opacity: 0.2
            }
          }
        }];
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 监听页面宽度变化，刷新表格
    handleResize: function handleResize() {
      if (this.addOrderPriceGraph) this.$refs.visitChart.handleResize();
      if (this.addUserGraph) this.$refs.userChart.handleResize();
    }
  }
};