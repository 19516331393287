import request from '@/utils/request';

// 地区列表管理
/*
 * 地区全列表
 */
export function langcountryGetOption(params) {
  return request({
    url: '/langcountry/getOption',
    method: 'get',
    params: params
  });
}
/*
 * 地区列表
 */
export function langcountryList(params) {
  return request({
    url: '/langcountry/list',
    method: 'get',
    params: params
  });
}
/*
 * 地区列表新增
 */
export function langcountrySave(data) {
  return request({
    url: '/langcountry/save',
    method: 'post',
    data: data
  });
}
/*
 * 地区列表详情
 */
export function langcountryInfo(id) {
  return request({
    url: "/langcountry/info/".concat(id),
    method: 'post'
  });
}
/*
 * 地区列表编辑
 */
export function langcountryUpdate(data) {
  return request({
    url: '/langcountry/update',
    method: 'post',
    data: data
  });
}
/*
 * 地区列表删除
 */
export function langcountryDelete(data) {
  return request({
    url: '/langcountry/delete',
    method: 'post',
    data: data
  });
}

// 语言列表管理
/*
 * 语言全列表
 */
export function langtypeGetOption(params) {
  return request({
    url: '/langtype/getOption',
    method: 'get',
    params: params
  });
}
/*
 * 语言列表
 */
export function langtypeList(params) {
  return request({
    url: '/langtype/list',
    method: 'get',
    params: params
  });
}
/*
 * 语言列表新增
 */
export function langtypeSaveLanguage(data) {
  return request({
    url: '/langtype/saveLanguage',
    method: 'post',
    data: data
  });
}
/*
 * 语言列表详情
 */
export function langtypeInfo(id) {
  return request({
    url: "/langtype/info/".concat(id),
    method: 'post'
  });
}
/*
 * 语言列表编辑
 */
export function langtypeUpdate(data) {
  return request({
    url: '/langtype/update',
    method: 'post',
    data: data
  });
}
/*
 * 语言列表删除
 */
export function langtypeDelete(data) {
  return request({
    url: '/langtype/delete',
    method: 'post',
    data: data
  });
}

// 语言翻译管理
/*
 * 语言翻译列表
 */
export function langcodeExplainList(params) {
  return request({
    url: '/langcode/explainList',
    method: 'get',
    params: params
  });
}
/*
 * 语言翻译新增
 */
export function langcodeSave(data) {
  return request({
    url: '/langcode/save',
    method: 'post',
    data: data
  });
}
/*
 * 语言翻译
 */
export function langcodeTranslation(data) {
  return request({
    url: '/langcode/translation',
    method: 'post',
    data: data
  });
}
/*
 * 语言翻译详情
 */
export function langcodeInfo(id) {
  return request({
    url: "/langcode/info/".concat(id),
    method: 'post'
  });
}
/*
 * 语言翻译编辑
 */
export function langcodeUpdate(data) {
  return request({
    url: '/langcode/update',
    method: 'post',
    data: data
  });
}
/*
 * 语言翻译删除
 */
export function langcodeDelete(data) {
  return request({
    url: '/langcode/delete',
    method: 'post',
    data: data
  });
}

// 国家管理

/*
 * 获取国家管理列表
 */
export function getCountry(params) {
  return request({
    url: '/country/list',
    method: 'get',
    params: params
  });
}
/*
 * 新增国家管理列表
 */
export function saveCountry(data) {
  return request({
    url: '/country/save',
    method: 'post',
    data: data
  });
}
/*
 * 修改国家管理列表
 */
export function updateCountry(data) {
  return request({
    url: '/country/update',
    method: 'post',
    data: data
  });
}

/*
 * 删除国家管理列表
 */
export function deleteCountry(data) {
  return request({
    url: '/country/delete',
    method: 'post',
    data: data
  });
}