//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { appversionGetIsNewVersion } from '@/api/dataField';
export default {
  data: function data() {
    return {
      loading: true,
      android: '',
      ios: '',
      pc: ''
    };
  },
  mounted: function mounted() {
    this.getversion();
  },
  methods: {
    getversion: function getversion() {
      var _this = this;
      appversionGetIsNewVersion().then(function (res) {
        console.log('三端安装包', res);
        res.forEach(function (item) {
          if (item.appName == 'android') {
            _this.android = item.appVersion.url;
          }
          if (item.appName == 'ios') {
            _this.ios = item.appVersion.url;
          }
          if (item.appName == 'pc') {
            _this.pc = item.appVersion.url;
          }
        });
        _this.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this.loading = false;
      });
    },
    handleto: function handleto(item) {
      console.log(item);
      window.open(item);
    }
  }
};