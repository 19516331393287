var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix flex_wrap",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-form",
                {
                  ref: "tableFrom",
                  attrs: {
                    inline: "",
                    size: "small",
                    model: _vm.tableFrom,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 20, xl: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据场：" } },
                        [
                          _c("UserSearchable", {
                            staticClass: "w_200",
                            attrs: { type: "datafieldList" },
                            scopedSlots: _vm._u([
                              {
                                key: "dropdownTitle",
                                fn: function () {
                                  return [
                                    _c("span", [_vm._v("数据场名称")]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("负责人")]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "dropdown",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.principal)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.tableFrom.dataFactoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "dataFactoryId", $$v)
                              },
                              expression: "tableFrom.dataFactoryId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户：" } },
                        [
                          _c("UserSearchable", {
                            staticClass: "w_200",
                            model: {
                              value: _vm.tableFrom.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "userId", $$v)
                              },
                              expression: "tableFrom.userId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "分组" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w_120",
                              attrs: {
                                placeholder: "请选择分组",
                                clearable: "",
                              },
                              model: {
                                value: _vm.tableFrom.groupId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "groupId", $$v)
                                },
                                expression: "tableFrom.groupId",
                              },
                            },
                            _vm._l(_vm.groupList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  value: item.id,
                                  label: item.groupName,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w_250",
                              attrs: {
                                multiple: "",
                                placeholder: "请选择标签",
                                clearable: "",
                              },
                              model: {
                                value: _vm.searchFrom.tagId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchFrom, "tagId", $$v)
                                },
                                expression: "searchFrom.tagId",
                              },
                            },
                            _vm._l(_vm.labelLists, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { value: item.id, label: item.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "开始时间：" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "selWidth",
                            attrs: {
                              type: "datetime",
                              "default-time": "00:00:00",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              placeholder: "选择日期",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "startTime", $$v)
                              },
                              expression: "tableFrom.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "结束时间：" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "selWidth",
                            attrs: {
                              type: "datetime",
                              "default-time": "00:00:00",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              placeholder: "选择日期",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom.endTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "endTime", $$v)
                              },
                              expression: "tableFrom.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 4, xl: 4 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mr10",
                              attrs: {
                                size: "small",
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.search()
                                },
                              },
                            },
                            [_vm._v("搜索")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "mr10",
                              attrs: { size: "small", icon: "el-icon-refresh" },
                              on: {
                                click: function ($event) {
                                  return _vm.reset()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.adoptAll },
                    },
                    [_vm._v("通过")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "mini" },
                      on: { click: _vm.noAdoptAll },
                    },
                    [_vm._v("拒绝")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
              on: { select: _vm.selctTable, "select-all": _vm.slectTableAll },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  align: "center",
                  "min-width": "100",
                  disabled: "true",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: { src: scope.row.avatar },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "nickName",
                  label: "用户昵称/UID",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.nickName) +
                              "/" +
                              _vm._s(scope.row.userId)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "dataFactoryName",
                  label: "数据场",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "amount", label: "金额", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              parseFloat("" + scope.row.amount)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "approvalStatus",
                  label: "状态",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.approvalStatus == 1
                          ? _c("span", [_vm._v("待审核")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.approvalStatus == 2
                          ? _c("span", [_vm._v("已通过")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.approvalStatus == 3
                          ? _c("span", [_vm._v("已拒绝")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "备注", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "approvalDate",
                  label: "审核时间",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "130",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetails(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        scope.row.approvalStatus == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePass(scope.row)
                                  },
                                },
                              },
                              [_vm._v("通过")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.approvalStatus == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRefuse(scope.row)
                                  },
                                },
                              },
                              [_vm._v("拒绝")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.approvalStatus == 2
                          ? _c(
                              "el-button",
                              { attrs: { type: "text", size: "mini" } },
                              [_vm._v("已通过")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.approvalStatus == 3
                          ? _c(
                              "el-button",
                              { attrs: { type: "text", size: "mini" } },
                              [_vm._v("已拒绝")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 100, 1000, _vm.tableData.total],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "交易明细",
            visible: _vm.userVisible2,
            width: "1200px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                data: _vm.userData.data,
                "highlight-current-row": "",
                size: "small",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "userName", label: "姓名", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userType",
                  label: "用户类型",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.selectinterfacetype(scope.row.userType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: " brokerageType",
                  label: "分佣方式",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        _vm.brokerageTypeList,
                        function (item, index) {
                          return item.dictValue == scope.row.brokerageType
                            ? _c(
                                "el-tag",
                                {
                                  key: index,
                                  staticClass: "mr5",
                                  attrs: { size: "small", type: "info" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.dictLabel) +
                                      _vm._s(
                                        scope.row.brokerageType == 3
                                          ? "(" +
                                              scope.row.percentReward * 100 +
                                              "%)"
                                          : ""
                                      )
                                  ),
                                ]
                              )
                            : _vm._e()
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "commodityBrokerageVoList",
                  label: "合约",
                  align: "center",
                  "min-width": "500",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              border: "",
                              data: scope.row.commodityBrokerageVoList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "commodityName",
                                label: "合约",
                                "min-width": "100",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commodityCount",
                                label: "手数",
                                "min-width": "100",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commodityBrokeragePrice",
                                label: "手续费/笔",
                                "min-width": "150",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commodityBrokerageFee",
                                label: "总手续费",
                                "min-width": "150",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              parseFloat(
                                                "" +
                                                  scope.row
                                                    .commodityBrokerageFee
                                              )
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userCount",
                  label: "总手数",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brokerageAmount",
                  label: "总手续费",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              parseFloat("" + scope.row.brokerageAmount)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brokerageFee",
                  label: "总佣金",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              parseFloat("" + scope.row.brokerageFee)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.detail.limit,
                  "current-page": _vm.detail.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.userData.total,
                },
                on: {
                  "size-change": _vm.handleUserSizeChange,
                  "current-change": _vm.userPageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.userVisible,
            width: "900px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("user-list", { on: { getTemplateRow: _vm.getTemplateRow } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.userclose()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.usersubmit()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          class: _vm.adoptFlag == true ? "adoptdialog" : "noadoptdialog",
          attrs: {
            title: _vm.adoptFlag == true ? "确认通过？" : "确认拒绝？",
            visible: _vm.diagVisible,
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.diagVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "adoptText" },
            [
              _c("div", { staticClass: "w_30" }, [_vm._v("备注:")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w_70",
                attrs: { placeholder: "请输入备注内容(必填)", clearable: "" },
                model: {
                  value: _vm.adoptInput,
                  callback: function ($$v) {
                    _vm.adoptInput = $$v
                  },
                  expression: "adoptInput",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.diagVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.sureAdopt(_vm.singleType)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }