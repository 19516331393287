//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import userList from "@/components/userList";
import UserSearchable from "@/components/UserSearchable";
import { traderconfigtemplateGetOption,
// 交易员综合配置模版全列表
exchangefeetemplateGetOption,
// 交易员手续费模板全列表
traderrisktemplateGetOption,
// 交易员风控模板全列表
getOptionNormalfilltemplate //补仓模版全列表
} from "@/api/agent";
import { datafieldGetOption } from "@/api/dataField";
import { userListApi, traderaccountList, traderaccountSave, traderaccountInfo, traderaccountUpdate, traderaccountDelete, traderaccountExport, sys_dict_dataTypeCurrency, traderaccountInOutType, traderaccountUpdateStorage, positionUpdateStorage } from "@/api/user";
import { getDictData } from "@/api/transaction";
export default {
  name: "trader",
  components: {
    userList: userList,
    UserSearchable: UserSearchable
  },
  data: function data() {
    return {
      accountTypeDict: [],
      options: [],
      labelPosition: "right",
      userVisible: false,
      from: {
        dataFactoryId: "",
        userId: "",
        keywords: "",
        // accountType: accountTypeDict[0].dictValue,
        accountType: "",
        tradeType: "",
        status: 1,
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      selection: [],
      walletResponses: [],
      mform: {
        traderAccountId: "",
        inOutType: "",
        walletResponses: [],
        currencyId: "",
        amount: "",
        remark: ""
      },
      mVisible: false,
      dialogVisible: false,
      exchangeFeeOptions: [],
      traderConfigOptions: [],
      traderriskOptions: [],
      houseOptions: [],
      ruleForm: {
        id: "",
        userId: "",
        traderConfigTemplateId: "",
        exchangeFeeTemplateId: "",
        traderRiskTemplateId: "",
        normalFillTemplateId: "",
        accountType: "",
        tradeType: "",
        status: 1,
        remark: ""
      },
      mrules: {
        currencyId: [{
          required: true,
          message: "请选择币种",
          trigger: "change"
        }],
        // inOutType: [
        //   {
        //     required: true,
        //     message: "请选择进出类型",
        //     trigger: "change",
        //   },
        // ],
        amount: [{
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "请输入备注",
          trigger: "blur"
        }]
      },
      checkedCities: [],
      cities: [{
        id: 1,
        label: "国际"
      }, {
        id: 2,
        label: "国内"
      }],
      rules: {
        userId: [{
          required: true,
          message: "请选择用户",
          trigger: "change"
        }],
        traderConfigTemplateId: [{
          required: true,
          message: "请选择交易员综合配置模板",
          trigger: "change"
        }],
        exchangeFeeTemplateId: [{
          required: true,
          message: "请选择手续费模板",
          trigger: "change"
        }],
        traderRiskTemplateId: [{
          required: true,
          message: "请选择风控模板",
          trigger: "change"
        }],
        normalFillTemplateId: [{
          required: true,
          message: "请选择补仓模板",
          trigger: "change"
        }],
        accountType: [{
          required: true,
          message: "请选择账户类型",
          trigger: "change"
        }]
      }
    };
  },
  activated: function activated() {
    this.search();
    this.setAccountType();
  },
  watch: {
    "$route.query.type": function $routeQueryType(newType) {
      this.setAccountType();
      this.search();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.setAccountType();
    traderconfigtemplateGetOption().then(function (res) {
      console.log("交易员综合配置模版全列表", res);
      _this.traderConfigOptions = res;
    }).catch(function (err) {
      console.log(err);
    });
    exchangefeetemplateGetOption().then(function (res) {
      console.log("交易员手续费模板全列表", res);
      _this.exchangeFeeOptions = res;
    }).catch(function (err) {
      console.log(err);
    });
    traderrisktemplateGetOption().then(function (res) {
      console.log("交易员风控模板全列表", res);
      _this.traderriskOptions = res;
    }).catch(function (err) {
      console.log(err);
    });
    getOptionNormalfilltemplate().then(function (res) {
      console.log("补仓控模板全列表", res);
      _this.houseOptions = res;
    }).catch(function (err) {
      console.log(err);
    });
    datafieldGetOption({}).then(function (res) {
      console.log("数据场全列表", res);
      _this.options = res;
    }).catch(function (err) {
      console.log(err);
    });
    // this.getlist();

    //获取字典数据
    getDictData("yj_trader_type").then(function (res) {
      console.log("账户类型字典数据：", res);
      _this.accountTypeDict = res;
      // this.from.accountType = this.accountTypeDict[0].dictValue;

      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });
  },
  methods: {
    clearPosition: function clearPosition(row) {
      var _this2 = this;
      console.log("刷新持仓缓存！！！", row);
      positionUpdateStorage({
        traderAccountId: row.id
      }).then(function (res) {
        console.log("刷新持仓缓存", res);
        _this2.$message.success("刷新成功！");
        _this2.getlist();
      }).catch(function (err) {
        console.log(err);
      });
    },
    clearStorageH: function clearStorageH(row) {
      var _this3 = this;
      // traderaccountUpdateStorage
      // console.log("清空缓存！！！", row);

      traderaccountUpdateStorage({
        traderAccountId: row.id
      }).then(function (res) {
        console.log("刷新额度缓存", res);
        _this3.$message.success("刷新成功！");
        _this3.getlist();
      }).catch(function (err) {
        console.log(err);
      });
    },
    setAccountType: function setAccountType() {
      var query = this.$route.query;
      console.log("🚀🚀🚀 ~ setAccountType ~ query:", query);
      if (query.type) {
        this.from.accountType = query.type;
      }
    },
    selectDictType: function selectDictType(dictValue) {
      var face = this.accountTypeDict.find(function (res) {
        return res.dictValue == JSON.stringify(dictValue);
      });
      if (face) return face.dictLabel;
    },
    selectUser: function selectUser() {
      this.userVisible = true;
    },
    getTemplateRow: function getTemplateRow(row) {
      this.ruleForm.userId = row.uid;
      this.from.userId = row.uid;
    },
    userclose: function userclose() {
      this.ruleForm.userId = "";
      this.from.userId = "";
      this.userVisible = false;
    },
    usersubmit: function usersubmit() {
      this.userVisible = false;
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        dataFactoryId: "",
        userId: "",
        keywords: "",
        accountType: this.accountTypeDict[0].dictValue,
        tradeType: "",
        status: 1,
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this4 = this;
      this.loading = true;
      traderaccountList(this.from).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this4.loading = false;
      });
    },
    add: function add() {
      this.ruleForm = {
        id: "",
        userId: "",
        traderConfigTemplateId: "",
        exchangeFeeTemplateId: "",
        traderRiskTemplateId: "",
        accountType: "",
        tradeType: "",
        status: 1,
        remark: ""
      };
      this.dialogVisible = true;
    },
    close: function close() {
      this.ruleForm = {
        id: "",
        userId: "",
        traderConfigTemplateId: "",
        exchangeFeeTemplateId: "",
        traderRiskTemplateId: "",
        accountType: "",
        tradeType: "",
        status: 1,
        remark: ""
      };
      this.dialogVisible = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(e) {
      // 单选
      if (e.length === 1) {
        this.ruleForm.tradeType = this.checkedCities[0];
      } else {
        // 多选
        this.ruleForm.tradeType = 3;
      }
    },
    submit: function submit() {
      var _this5 = this;
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          if (_this5.ruleForm.id) {
            console.log("编辑", _this5.ruleForm);
            traderaccountUpdate(_this5.ruleForm).then(function (res) {
              console.log(res);
              _this5.close();
              _this5.$message.success("编辑成功");
              _this5.getlist();
            }).catch(function (err) {
              console.log(err);
              _this5.$message.error(err);
            });
          } else {
            console.log("新增", _this5.ruleForm);
            var data = {
              userId: _this5.ruleForm.userId,
              traderConfigTemplateId: _this5.ruleForm.traderConfigTemplateId,
              exchangeFeeTemplateId: _this5.ruleForm.exchangeFeeTemplateId,
              traderRiskTemplateId: _this5.ruleForm.traderRiskTemplateId,
              accountType: _this5.ruleForm.accountType,
              tradeType: _this5.ruleForm.tradeType,
              status: _this5.ruleForm.status,
              remark: _this5.ruleForm.remark
            };
            traderaccountSave(data).then(function (res) {
              console.log(res);
              _this5.close();
              _this5.$message.success("新增成功");
              _this5.getlist();
            }).catch(function (err) {
              console.log(err);
              _this5.$message.error(err);
            });
          }
        } else {
          _this5.$message.error("请填写完整信息");
        }
      });
    },
    // 选择
    onSelectTab: function onSelectTab(selection) {
      var _this6 = this;
      this.selection = [];
      selection.forEach(function (item) {
        _this6.selection.push(item.id);
      });
      console.log(this.selection);
    },
    allDel: function allDel() {
      var _this7 = this;
      if (this.selection.length == 0) {
        return this.$message.error("至少选择一项");
      }
      console.log(this.selection);
      this.$confirm("确认删除？", "提示").then(function (_) {
        traderaccountDelete(_this7.selection).then(function (res) {
          console.log(res);
          _this7.$message.success("删除成功");
          _this7.getlist();
        }).catch(function (err) {
          console.log(err);
          _this7.$message.error(err);
        });
      }).catch(function (_) {});
    },
    exportTable: function exportTable() {
      var _this8 = this;
      traderaccountExport(this.from).then(function (res) {
        console.log("导出表格", res);
        window.open(res.filename);
      }).catch(function (err) {
        console.log(err);
        _this8.$message.error(err);
      });
    },
    handleMoney: function handleMoney(row) {
      var _this9 = this;
      sys_dict_dataTypeCurrency().then(function (res) {
        console.log("交易员钱包额度变更币种11111", res);
        _this9.walletResponses = res;
        // console.log('11111111',this.walletResponses[0].dictValue)
        _this9.mform = {
          traderAccountId: row.id,
          inOutType: "",
          walletResponses: row.walletResponses,
          // currencyId: "",
          // 默认选择第一项
          currencyId: _this9.walletResponses[0].dictValue,
          amount: "",
          remark: ""
        };
        _this9.mVisible = true;
      }).catch(function (err) {
        console.log(err);
      });
      // this.mform = {
      //   traderAccountId: row.id,
      //   inOutType: "",
      //   walletResponses: row.walletResponses,
      //   // currencyId: "",
      //   // 默认选择第一项
      //   currencyId:this.walletResponses[0].dictValue,
      //   amount: "",
      // };
      // this.mVisible = true;
    },
    mClose: function mClose() {
      this.walletResponses = [];
      this.mform = {
        traderAccountId: "",
        inOutType: "",
        walletResponses: [],
        currencyId: "",
        amount: ""
      };
      this.mVisible = false;
    },
    mSubmit: function mSubmit(inOutType) {
      var _this10 = this;
      this.$refs["mform"].validate(function (valid) {
        if (valid) {
          console.log("额度变更信息", _this10.mform);
          var data = {
            traderAccountId: _this10.mform.traderAccountId,
            currencyId: _this10.mform.currencyId,
            inOutType: inOutType,
            amount: _this10.mform.amount,
            remark: _this10.mform.remark
          };
          traderaccountInOutType(data).then(function (res) {
            console.log(res);
            _this10.mClose();
            _this10.$message.success("成功");
            _this10.getlist();
          }).catch(function (err) {
            console.log(err);
            _this10.$message.error(err);
          });
        } else {
          _this10.$message.error("请填写完整信息");
        }
      });
    },
    handleEdit: function handleEdit(row) {
      var _this11 = this;
      console.log("编辑", row);
      traderaccountInfo(row.id).then(function (res) {
        console.log("详情", res);
        _this11.ruleForm = {
          id: res.id,
          userId: res.userId,
          nickName: res.nickName,
          traderConfigTemplateId: res.traderConfigTemplateId,
          exchangeFeeTemplateId: res.exchangeFeeTemplateId,
          traderRiskTemplateId: res.traderRiskTemplateId,
          normalFillTemplateId: res.normalFillTemplateId,
          accountType: JSON.stringify(res.accountType),
          tradeType: Number(res.tradeType),
          status: Number(res.status),
          remark: res.remark
        };
        if (res.tradeType == 1) {
          _this11.checkedCities = [1];
        } else if (res.tradeType == 2) {
          _this11.checkedCities = [2];
        } else {
          _this11.checkedCities = [1, 2];
        }
        _this11.dialogVisible = true;
      }).catch(function (err) {
        console.log(err);
        _this11.$message.error(err);
      });
    },
    handleDel: function handleDel(row) {
      var _this12 = this;
      this.$confirm("确认删除？", "提示").then(function (_) {
        console.log("删除", row);
        traderaccountDelete([row.id]).then(function (res) {
          console.log(res);
          _this12.$message.success("删除成功");
          _this12.getlist();
        }).catch(function (err) {
          console.log(err);
          _this12.$message.error(err);
        });
      }).catch(function (_) {});
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};