"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
var _systemSetting = require("@/api/systemSetting");
var _dataField = require("@/api/dataField");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'version',
  data: function data() {
    return {
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      tableFrom: {
        page: 1,
        limit: 15
      },
      loading: false,
      tableList: [],
      total: 0,
      dialogVisible: false,
      ruleForm: {
        id: '',
        version: '',
        // 版本号
        isNew: '1',
        // 是否为线上最新版本（1是 2否）
        platform: '1',
        // 平台类型（1安卓 2苹果 3PC）
        isForce: '1',
        // 是否强制强制更新（1强制  2非强制）
        url: '',
        // 下载地址
        info: '' // 升级信息（升级备注）
      },
      rules: {
        version: [{
          required: true,
          message: '请填写版本号',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '请填写下载地址',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getlist();
  },
  methods: {
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this = this;
      var formData = new FormData();

      // 平台类型，PC增加一个字段
      if (this.ruleForm.platform == 3) {
        var data = {
          ossProvider: 0,
          model: 'pc',
          pid: 1
        };
      } else if (this.ruleForm.platform == 7) {
        var data = {
          ossProvider: 0,
          model: 'pcAdmin',
          pid: 1
        };
      } else {
        var data = {
          model: 'app',
          pid: 1
        };
      }
      // const data = {
      //     model: '',
      //     pid: 1
      //   }

      formData.append('multipart', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _systemSetting.fileFileApi)(formData, data).then(function (res) {
        loading.close();
        // this.ruleForm.url = SettingMer.httpUrl + '/' + res.url
        _this.ruleForm.url = res.url;
        console.log('上传成功', _this.ruleForm.url);
        _this.$message.success('上传成功');
      }).catch(function (res) {
        loading.close();
      });
    },
    add: function add() {
      this.ruleForm = {
        id: '',
        version: '',
        // 版本号
        isNew: 1,
        // 是否为线上最新版本（1是 2否）
        platform: 1,
        // 平台类型（1安卓 2苹果 3PC）
        isForce: 1,
        // 是否强制（1强制  2非强制）
        url: '',
        // 下载地址
        info: '' // 升级信息（升级备注）
      };
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      var _this2 = this;
      console.log('编辑', row);
      (0, _dataField.appversionInfo)(row.id).then(function (res) {
        console.log('详情', res);
        _this2.ruleForm = {
          id: res.id,
          version: res.version,
          isNew: res.isNew,
          platform: res.platform,
          isForce: res.isForce,
          url: res.url,
          info: res.info
        };
        _this2.dialogVisible = true;
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err);
      });
    },
    handleDel: function handleDel(row) {
      var _this3 = this;
      this.$confirm('确认删除？', '提示').then(function (_) {
        console.log('删除', row);
        (0, _dataField.appversionDelete)([row.id]).then(function (res) {
          console.log(res);
          _this3.$message.success('删除成功');
          _this3.getlist();
        }).catch(function (err) {
          console.log(err);
          _this3.$message.error(err);
        });
      }).catch(function (_) {});
    },
    close: function close() {
      this.ruleForm = {
        id: '',
        version: '',
        // 版本号
        isNew: '1',
        // 是否为线上最新版本（1是 2否）
        platform: '1',
        // 平台类型（1安卓 2苹果 3PC）
        isForce: '1',
        // 是否强制（1强制  2非强制）
        url: '',
        // 下载地址
        info: '' // 升级信息（升级备注）
      };
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this4 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          if (_this4.ruleForm.id) {
            console.log('编辑', _this4.ruleForm);
            (0, _dataField.appversionUpdate)(_this4.ruleForm).then(function (res) {
              console.log(res);
              _this4.close();
              _this4.$message.success('编辑成功');
              _this4.getlist();
            }).catch(function (err) {
              console.log(err);
              _this4.$message.error(err);
            });
          } else {
            console.log('新增', _this4.ruleForm);
            var data = {
              version: _this4.ruleForm.version,
              isNew: _this4.ruleForm.isNew,
              platform: _this4.ruleForm.platform,
              isForce: _this4.ruleForm.isForce,
              url: _this4.ruleForm.url,
              info: _this4.ruleForm.info
            };
            (0, _dataField.appversionSave)(data).then(function (res) {
              console.log(res);
              _this4.close();
              _this4.$message.success('新增成功');
              _this4.getlist();
            }).catch(function (err) {
              console.log(err);
              _this4.$message.error(err);
            });
          }
        } else {
          _this4.$message.error('请填写完整信息');
        }
      });
    },
    // 版本信息列表
    getlist: function getlist() {
      var _this5 = this;
      this.loading = true;
      (0, _dataField.appversionList)(this.tableFrom).then(function (res) {
        console.log('APP版本列表', res);
        _this5.tableList = res.list;
        _this5.total = res.total;
        _this5.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this5.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getlist();
    }
  }
};