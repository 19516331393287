function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { reportTodayBalanceBill, reportTodayBalanceBillExport, getGiveAmount, getGiveAmountExport } from "@/api/reportQuery";
import { getDictData } from "@/api/transaction";
import UserSearchable from "@/components/UserSearchable";
export default {
  components: {
    UserSearchable: UserSearchable
  },
  data: function data() {
    return {
      labelPosition: "right",
      from: {
        // dataFactoryName:'',
        dataFactoryId: '',
        userName: "",
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      accountTypeDict: [],
      balanceBillType: []
    };
  },
  mounted: function mounted() {
    // this.getlist()

    //获取字典数据
    // getDictData("yj_trader_type")
    //   .then((res) => {
    //     console.log("账户类型字典数据：", res);
    //     this.accountTypeDict = res;
    //     // this.from.accountType = res[0].dictValue;
    //     this.getlist();
    //   })
    //   .catch((res) => {
    //     console.log(res);
    //   });

    this.getlist();
  },
  methods: {
    exportTable: function exportTable(full) {
      var _this = this;
      var from = _objectSpread({}, this.from);
      if (full) {
        from.limit = this.tableData.total;
      }
      console.log(from);
      getGiveAmountExport(from).then(function (res) {
        console.log("导出表格", res);
        window.open(res.filename);
      }).catch(function (err) {
        console.log(err);
        _this.$message.error(err);
      });
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        dataFactoryId: '',
        userName: "",
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this2 = this;
      console.log(this.from);
      this.loading = true;
      getGiveAmount(this.from).then(function (res) {
        console.log(res);
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.tableData.totalPage = res.totalPage;
        _this2.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this2.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};