"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var roleApi = _interopRequireWildcard(require("@/api/role.js"));
var agentApi = _interopRequireWildcard(require("@/api/agent.js"));
var systemAdminApi = _interopRequireWildcard(require("@/api/systemadmin.js"));
var _tenant = require("@/api/tenant");
var _validate = require("@/utils/validate");
var _userList = _interopRequireDefault(require("@/components/userList"));
var _UserSearchable = _interopRequireDefault(require("@/components/UserSearchable"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    userList: _userList.default,
    UserSearchable: _UserSearchable.default
  },
  props: {
    isCreate: {
      type: Number,
      required: true
    },
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    }
  },
  data: function data() {
    var _this = this,
      _rules;
    var validatePhone = function validatePhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('请填写手机号'));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('手机号格式不正确!'));
      } else {
        callback();
      }
    };
    var validatePass = function validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.pram.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      constants: this.$constants,
      pram: {
        account: null,
        level: null,
        pwd: null,
        repwd: null,
        realName: null,
        roles: [],
        status: null,
        id: null,
        phone: null,
        memberType: null,
        userId: null,
        name: null,
        principal: null,
        address: null,
        expireTime: null,
        brokerageTemplateId: null,
        remark: null,
        adminDataFactoryId: null,
        adminDataFactoryName: null,
        userName: null,
        userAccount: null,
        nickName: null,
        email: null
      },
      memberTypeList: [],
      brokerageTemplateIdList: [],
      tenantIdList: [],
      roleList: [],
      rules: (_rules = {
        adminAccount: [{
          required: true,
          message: '请填管理员账号',
          trigger: ['blur', 'change']
        }],
        pwd: [{
          required: true,
          message: '请填管理员密码',
          trigger: ['blur', 'change']
        }],
        repwd: [{
          required: true,
          message: '确认密码密码',
          validator: validatePass,
          trigger: ['blur', 'change']
        }],
        realName: [{
          required: true,
          message: '管理员姓名',
          trigger: ['blur', 'change']
        }]
      }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_rules, "realName", [{
        required: true,
        message: '管理员姓名',
        trigger: ['blur', 'change']
      }]), "nickName", [{
        required: true,
        message: '用户昵称',
        trigger: ['blur', 'change']
      }]), "email", [{
        required: true,
        message: '用户邮箱',
        trigger: ['blur', 'change']
      }]), "tenantId", [{
        required: true,
        message: '租户',
        trigger: ['blur', 'change']
      }]), "roles", [{
        required: true,
        message: '角色',
        trigger: ['blur', 'change']
      }]), "phone", [{
        required: true,
        message: '请输入手机号',
        trigger: ['blur', 'change']
      }]), "name", [{
        required: true,
        message: '数据场名称',
        trigger: ['blur', 'change']
      }]), "principal", [{
        required: true,
        message: '负责人',
        trigger: ['blur', 'change']
      }]), "address", [{
        required: true,
        message: '数据场地址',
        trigger: ['blur', 'change']
      }]), "expireTime", [{
        required: true,
        message: '数据场过期时间',
        trigger: ['blur', 'change']
      }]), _defineProperty(_rules, "memberType", [{
        required: true,
        message: '请选择管理员身份',
        trigger: 'change'
      }])),
      userVisible: false
    };
  },
  mounted: function mounted() {
    this.initEditData();
    this.handleGetRoleList();
    this.getbrokerageTemplateIdList();
    this.getTenantIdList();
  },
  methods: {
    selectUser: function selectUser() {
      this.userVisible = true;
    },
    getTemplateRow: function getTemplateRow(row) {
      this.pram.userId = row.uid;
    },
    userclose: function userclose() {
      this.pram.userId = '';
      this.userVisible = false;
    },
    usersubmit: function usersubmit() {
      this.userVisible = false;
    },
    getbrokerageTemplateIdList: function getbrokerageTemplateIdList() {
      var _this2 = this;
      agentApi.brokeragetemplateGetOption().then(function (res) {
        _this2.brokerageTemplateIdList = res;
      });
    },
    getTenantIdList: function getTenantIdList() {
      var _this3 = this;
      (0, _tenant.getTenantIdList)().then(function (res) {
        _this3.tenantIdList = res;
      });
    },
    close: function close() {
      this.$emit('hideEditDialog');
    },
    handleGetRoleList: function handleGetRoleList() {
      var _this4 = this;
      var _pram = {
        page: 1,
        limit: this.constants.page.limit[4],
        status: 1
      };
      roleApi.getRoleList(_pram).then(function (data) {
        _this4.roleList = data;
        var arr = [];
        data.list.forEach(function (item) {
          arr.push(item.id);
        });
        if (!arr.includes(Number.parseInt(_this4.pram.roles))) {
          _this4.$set(_this4.pram, 'roles', []);
        }
      });
    },
    initEditData: function initEditData() {
      if (this.isCreate !== 1) return;
      var _this$editData = this.editData,
        account = _this$editData.account,
        realName = _this$editData.realName,
        roles = _this$editData.roles,
        level = _this$editData.level,
        status = _this$editData.status,
        id = _this$editData.id,
        phone = _this$editData.phone,
        memberType = _this$editData.memberType,
        userId = _this$editData.userId,
        name = _this$editData.name,
        principal = _this$editData.principal,
        address = _this$editData.address,
        expireTime = _this$editData.expireTime,
        brokerageTemplateId = _this$editData.brokerageTemplateId,
        remark = _this$editData.remark,
        adminDataFactoryId = _this$editData.adminDataFactoryId,
        adminDataFactoryName = _this$editData.adminDataFactoryName,
        userName = _this$editData.userName;
      this.pram.account = account;
      this.pram.realName = realName;
      var _roles = [];
      if (roles.length > 0 && !roles.includes(',')) {
        //如果权限id集合有长度并且是只有一个，就将它Push进_roles这个数组
        _roles.push(Number.parseInt(roles));
      } else {
        //否则就将多个id集合解构以后push进roles并且转换为整型
        _roles.push.apply(_roles, _toConsumableArray(roles.split(',').map(function (item) {
          return Number.parseInt(item);
        })));
      }
      this.pram.roles = _roles;
      this.pram.status = status;
      this.pram.id = id;
      this.pram.phone = phone;
      this.rules.pwd = [];
      this.rules.repwd = [];
      this.pram.memberType = String(memberType);
      this.pram.userId = userId;
      this.pram.name = name;
      this.pram.principal = principal;
      this.pram.address = address;
      this.pram.expireTime = expireTime;
      this.pram.brokerageTemplateId = brokerageTemplateId;
      this.pram.remark = remark, this.pram.adminDataFactoryId = adminDataFactoryId, this.pram.adminDataFactoryName = adminDataFactoryName, this.pram.userName = userName;
    },
    handlerSubmit: (0, _validate.Debounce)(function (form) {
      var _this5 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (_this5.isCreate === 0) {
          _this5.handlerSave();
        } else {
          _this5.handlerEdit();
        }
      });
    }),
    handlerSave: function handlerSave() {
      var _this6 = this;
      console.log('添加数据：', this.pram);
      (0, _tenant.saveAdminTenant)(_objectSpread(_objectSpread({}, this.pram), {}, {
        roles: this.pram.roles.join(',')
      })).then(function (data) {
        _this6.$message.success('创建管理员成功');
        _this6.$emit('hideEditDialog');
      });
    },
    handlerEdit: function handlerEdit() {
      var _this7 = this;
      console.log('更新数据：', this.pram);
      systemAdminApi.adminUpdate(_objectSpread(_objectSpread({}, this.pram), {}, {
        roles: this.pram.roles.join(',')
      })).then(function (data) {
        _this7.$message.success('更新管理员成功');
        _this7.$emit('hideEditDialog');
      });
    },
    rulesSelect: function rulesSelect(selectKeys) {
      this.pram.rules = selectKeys;
    },
    handlerPwdInput: function handlerPwdInput(val) {
      var _this8 = this;
      if (!val) {
        this.rules.pwd = [];
        this.rules.repwd = [];
        return;
      }
      this.rules.pwd = [{
        required: true,
        message: '请填管理员密码',
        trigger: ['blur', 'change']
      }, {
        min: 6,
        max: 20,
        message: '长度6-20个字符',
        trigger: ['blur', 'change']
      }];
      this.rules.repwd = [{
        required: true,
        message: '两次输入密码不一致',
        validator: function validator(rule, value, callback) {
          if (value === '') {
            callback(new Error('两次输入密码不一致!'));
          } else if (value !== _this8.pram.pwd) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        },
        trigger: ['blur', 'change']
      }];
    }
  }
};