"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminGetOption = adminGetOption;
exports.annountCementAdd = annountCementAdd;
exports.annountCementDelete = annountCementDelete;
exports.annountCementDoReleaseData = annountCementDoReleaseData;
exports.annountCementEdit = annountCementEdit;
exports.annountCementList = annountCementList;
exports.annountCementQueryById = annountCementQueryById;
exports.appversionDelete = appversionDelete;
exports.appversionGetIsNewVersion = appversionGetIsNewVersion;
exports.appversionInfo = appversionInfo;
exports.appversionList = appversionList;
exports.appversionSave = appversionSave;
exports.appversionUpdate = appversionUpdate;
exports.bannerAdd = bannerAdd;
exports.bannerDelete = bannerDelete;
exports.bannerEdit = bannerEdit;
exports.bannerInfo = bannerInfo;
exports.bannerList = bannerList;
exports.datafieldDelete = datafieldDelete;
exports.datafieldExport = datafieldExport;
exports.datafieldGetOption = datafieldGetOption;
exports.datafieldInfo = datafieldInfo;
exports.datafieldList = datafieldList;
exports.datafieldSave = datafieldSave;
exports.datafieldTransfer = datafieldTransfer;
exports.datafieldUpdate = datafieldUpdate;
exports.getAnnountCementQueryById = getAnnountCementQueryById;
exports.indexCommodityList = indexCommodityList;
exports.indexIndexList = indexIndexList;
exports.reportGetReportUserTrade = reportGetReportUserTrade;
exports.reportStatistics = reportStatistics;
exports.updateRechargeFlowType = updateRechargeFlowType;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/*
 * 用户统计及交易统计
 */
function reportGetReportUserTrade(data) {
  return (0, _request.default)({
    url: "/report/getReportUserTrade",
    method: "post",
    data: data
  });
}
/*
 * 数据统计
 */
function reportStatistics(params) {
  return (0, _request.default)({
    url: "/report/statistics",
    method: "get",
    params: params
  });
}

/*
 * 获取管理员列表
 */
// 更改为post，传body
function adminGetOption(data) {
  return (0, _request.default)({
    url: "admin/system/admin/getOption",
    method: "post",
    data: data
  });
}

// 数据场管理
/*
 * 数据场全列表
 */
function datafieldGetOption(data) {
  return (0, _request.default)({
    url: "/datafield/getOption",
    method: "post",
    data: data
  });
}
/*
 * 数据场列表
 */
function datafieldList(params) {
  return (0, _request.default)({
    url: "/datafield/list",
    method: "get",
    params: params
  });
}
/*
 * 数据场新增
 */
function datafieldSave(data) {
  return (0, _request.default)({
    url: "/datafield/save",
    method: "post",
    data: data
  });
}
/*
 * 数据场详情
 */
function datafieldInfo(id) {
  return (0, _request.default)({
    url: "/datafield/info/".concat(id),
    method: "post"
  });
}
/*
 * 数据场编辑
 */
function datafieldUpdate(data) {
  return (0, _request.default)({
    url: "/datafield/update",
    method: "post",
    data: data
  });
}
/*
 * 数据场删除
 */
function datafieldDelete(data) {
  return (0, _request.default)({
    url: "/datafield/delete",
    method: "post",
    data: data
  });
}
/*
 * 导出数据场列表
 */
function datafieldExport(params) {
  return (0, _request.default)({
    url: "/datafield/export",
    method: "get",
    params: params
  });
}
/*
 * 数据场迁移
 */
function datafieldTransfer(data) {
  return (0, _request.default)({
    url: "/datafield/transfer",
    method: "post",
    data: data
  });
}

// 新首页统计
/*
 * 图形化统计
 */
function indexIndexList(data) {
  return (0, _request.default)({
    url: "/index/indexList",
    method: "post",
    data: data
  });
}
/*
 * 数据场列表统计
 */
function indexCommodityList(params) {
  return (0, _request.default)({
    url: "/index/commodityList",
    method: "get",
    params: params
  });
}

// 系统通告
/*
 * 系统通告列表
 */
function annountCementList(params) {
  return (0, _request.default)({
    url: "/annountCement/list",
    method: "get",
    params: params
  });
}
/*
 * 系统通告发布
 */
function annountCementDoReleaseData(data) {
  return (0, _request.default)({
    url: "/annountCement/doReleaseData",
    method: "post",
    data: data
  });
}
/*
 * 系统通告批量删除
 */
function annountCementDelete(ids) {
  return (0, _request.default)({
    url: "/annountCement/delete/".concat(ids),
    method: "post"
  });
}
/*
 * 系统通告详情
 */
function annountCementQueryById(data) {
  return (0, _request.default)({
    url: "/annountCement/queryById",
    method: "post",
    data: data
  });
}
/*
 * 系统通告详情页
 */
function getAnnountCementQueryById(ID) {
  return (0, _request.default)({
    url: "/annountCement/queryById/".concat(ID),
    method: "post"
  });
}
/*
 * 系统通告添加
 */
function annountCementAdd(data) {
  return (0, _request.default)({
    url: "/annountCement/add",
    method: "post",
    data: data
  });
}
/*
 * 系统通告编辑
 */
function annountCementEdit(data) {
  return (0, _request.default)({
    url: "/annountCement/edit",
    method: "post",
    data: data
  });
}

// APP版本管理
/*
 * APP版本列表
 */
function appversionList(params) {
  return (0, _request.default)({
    url: "/appversion/list",
    method: "get",
    params: params
  });
}
/*
 * APP版本新增
 */
function appversionSave(data) {
  return (0, _request.default)({
    url: "/appversion/save",
    method: "post",
    data: data
  });
}
/*
 * APP版本详情
 */
function appversionInfo(id) {
  return (0, _request.default)({
    url: "/appversion/info/".concat(id),
    method: "post"
  });
}
/*
 * APP版本编辑
 */
function appversionUpdate(data) {
  return (0, _request.default)({
    url: "/appversion/update",
    method: "post",
    data: data
  });
}
/*
 * APP版本删除
 */
function appversionDelete(data) {
  return (0, _request.default)({
    url: "/appversion/delete",
    method: "post",
    data: data
  });
}

/*
 * 三端安装包
 */
function appversionGetIsNewVersion(params) {
  return (0, _request.default)({
    url: "/appversion/getIsNewVersion",
    method: "get",
    params: params
  });
}

// 轮播图管理
/*
 * 轮播图列表
 */
function bannerList(data) {
  return (0, _request.default)({
    url: "/admin/system/group/data/carousel/list",
    method: "POST",
    data: data
  });
}
/*
 * 轮播图详情
 */
function bannerInfo(params) {
  return (0, _request.default)({
    url: "/admin/system/group/data/info",
    method: "get",
    params: params
  });
}
// 轮播图新增
function bannerAdd(data) {
  return (0, _request.default)({
    url: "/admin/system/group/data/carousel/saveCarousel",
    method: "POST",
    data: data
  });
}
// 轮播图编辑
function bannerEdit(data, params) {
  return (0, _request.default)({
    url: "/admin/system/group/data/updateCarousel",
    method: "POST",
    data: data,
    params: params
  });
}
// 轮播图删除
function bannerDelete(params) {
  return (0, _request.default)({
    url: "/admin/system/group/data/delete",
    method: "get",
    params: params
  });
}

/*
 * 变更入金审核流程
 */
function updateRechargeFlowType(data) {
  return (0, _request.default)({
    url: "/datafield/updateRechargeFlowType",
    method: "post",
    data: data
  });
}