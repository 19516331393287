//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { traderaccountList, tradersignalList, tradersignalSave, tradersignalInfo, tradersignalUpdate, tradersignalDelete, tradersignalExport } from "@/api/user";
export default {
  name: "signal",
  data: function data() {
    return {
      labelPosition: "right",
      from: {
        name: "",
        status: 1,
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      selection: [],
      dialogVisible: false,
      ruleForm: {
        id: "",
        traderAccountId: "",
        name: "",
        brokerage: "",
        status: 1,
        remark: ""
      },
      userVisible: false,
      userData: {
        data: [],
        total: 0
      },
      currentRow: null,
      userPage: 1,
      userLimit: 10,
      rules: {
        traderAccountId: [{
          required: true,
          message: "请选择交易员账户",
          trigger: "change"
        }],
        name: [{
          required: true,
          message: "请填写信号名称",
          trigger: "blur"
        }],
        brokerage: [{
          required: true,
          message: "请填写费用",
          trigger: "blur"
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getlist();
  },
  methods: {
    selectUser: function selectUser() {
      this.userPage = 1;
      this.userLimit = 10;
      this.currentRow = null;
      this.userData = {
        data: [],
        total: 0
      };
      this.userVisible = true;
      this.getUserList();
    },
    userclose: function userclose() {
      this.userPage = 1;
      this.userLimit = 10;
      this.currentRow = null;
      this.userData = {
        data: [],
        total: 0
      };
      this.ruleForm.traderAccountId = "";
      this.userVisible = false;
    },
    usersubmit: function usersubmit() {
      this.userPage = 1;
      this.userLimit = 10;
      this.currentRow = null;
      this.userData = {
        data: [],
        total: 0
      };
      this.userVisible = false;
    },
    onSelectUser: function onSelectUser(val) {
      this.currentRow = val;
      console.log("选择交易员账号", val);
      this.ruleForm.traderAccountId = val.id;
    },
    handleUserSizeChange: function handleUserSizeChange(val) {
      this.userLimit = val;
      this.getUserList();
    },
    userPageChange: function userPageChange(page) {
      this.userPage = page;
      this.getUserList();
    },
    getUserList: function getUserList() {
      var _this = this;
      var data = {
        page: this.userPage,
        limit: this.userLimit,
        status: 1
      };
      traderaccountList(data).then(function (res) {
        console.log("交易员列表", res);
        _this.userData.data = res.list;
        _this.userData.total = res.total;
      }).catch(function (err) {
        console.log(err);
      });
    },
    search: function search() {
      console.log(this.from);
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        name: "",
        status: 1,
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this2 = this;
      this.loading = true;
      tradersignalList(this.from).then(function (res) {
        console.log(res);
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this2.loading = false;
      });
    },
    add: function add() {
      this.ruleForm = {
        id: "",
        traderAccountId: "",
        name: "",
        brokerage: "",
        status: 1,
        remark: ""
      };
      this.dialogVisible = true;
    },
    close: function close() {
      this.ruleForm = {
        id: "",
        traderAccountId: "",
        name: "",
        brokerage: "",
        status: 1,
        remark: ""
      };
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          if (_this3.ruleForm.id) {
            console.log("编辑", _this3.ruleForm);
            tradersignalUpdate(_this3.ruleForm).then(function (res) {
              console.log(res);
              _this3.close();
              _this3.$message.success("编辑成功");
              _this3.getlist();
            }).catch(function (err) {
              console.log(err);
              _this3.$message.error(err);
            });
          } else {
            console.log("新增", _this3.ruleForm);
            var data = {
              traderAccountId: _this3.ruleForm.traderAccountId,
              name: _this3.ruleForm.name,
              brokerage: _this3.ruleForm.brokerage,
              status: _this3.ruleForm.status,
              remark: _this3.ruleForm.remark
            };
            tradersignalSave(data).then(function (res) {
              console.log(res);
              _this3.close();
              _this3.$message.success("新增成功");
              _this3.getlist();
            }).catch(function (err) {
              console.log(err);
              _this3.$message.error(err);
            });
          }
        } else {
          _this3.$message.error("请填写完整信息");
        }
      });
    },
    // 选择
    onSelectTab: function onSelectTab(selection) {
      var _this4 = this;
      this.selection = [];
      selection.forEach(function (item) {
        _this4.selection.push(item.id);
      });
      console.log(this.selection);
    },
    allDel: function allDel() {
      var _this5 = this;
      if (this.selection.length == 0) {
        return this.$message.error("至少选择一项");
      }
      console.log(this.selection);
      this.$confirm("确认删除？").then(function (_) {
        tradersignalDelete(_this5.selection).then(function (res) {
          console.log(res);
          _this5.$message.success("删除成功");
          _this5.getlist();
        }).catch(function (err) {
          console.log(err);
          _this5.$message.error(err);
        });
      }).catch(function (_) {});
    },
    exportTable: function exportTable() {
      var _this6 = this;
      tradersignalExport(this.from).then(function (res) {
        console.log("导出表格", res);
        window.open(res.filename);
      }).catch(function (err) {
        console.log(err);
        _this6.$message.error(err);
      });
    },
    handleEdit: function handleEdit(row) {
      var _this7 = this;
      console.log("编辑", row);
      tradersignalInfo(row.id).then(function (res) {
        console.log("详情", res);
        _this7.ruleForm = {
          id: res.id,
          traderAccountId: res.traderAccountId,
          name: res.name,
          brokerage: res.brokerage,
          status: Number(res.status),
          remark: res.remark
        };
        _this7.dialogVisible = true;
      }).catch(function (err) {
        console.log(err);
        _this7.$message.error(err);
      });
    },
    handleDel: function handleDel(row) {
      var _this8 = this;
      this.$confirm("确认删除？").then(function (_) {
        console.log("删除", row);
        tradersignalDelete([row.id]).then(function (res) {
          console.log(res);
          _this8.$message.success("删除成功");
          _this8.getlist();
        }).catch(function (err) {
          console.log(err);
          _this8.$message.error(err);
        });
      }).catch(function (_) {});
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};