//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { walletBillDataField } from "@/api/financial";
import userList from "@/components/userList";
import UserSearchable from "@/components/UserSearchable";
import { getDictData } from "@/api/transaction";
export default {
  components: {
    userList: userList,
    UserSearchable: UserSearchable
  },
  data: function data() {
    return {
      accountTypeDict: [],
      userVisible: false,
      cardLists: [],
      tableFrom: {
        dataFactoryId: "",
        userId: "",
        startTime: "",
        endTime: "",
        page: 1,
        limit: 10,
        dataFactoryFundType: ""
        // accountType: ""
      },
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      moneyOptions: []
    };
  },
  watch: {
    'tableFrom.dataFactoryFundType': function tableFromDataFactoryFundType(newVal) {
      if (newVal == 1) this.getlist();
    }
  },
  mounted: function mounted() {
    var _this = this;
    // this.getlist();

    //获取字典数据
    getDictData('yj_data_factory_fund_type').then(function (res) {
      console.log('资金字典数据：', res);
      _this.moneyOptions = res;
      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });

    // getDictData('yj_trader_type').then((res) => {
    //   console.log('账户类型字典数据：', res)
    //   this.accountTypeDict = res
    //   // console.log('字典类型数据：', res[0].dictValue)
    //   this.tableFrom.accountType = res[0].dictValue
    //   this.getlist();
    // }).catch((res) => {
    //   console.log(res)
    // })
  },
  methods: {
    selectUser: function selectUser() {
      this.userVisible = true;
    },
    getTemplateRow: function getTemplateRow(row) {
      this.tableFrom.userId = row.uid;
    },
    userclose: function userclose() {
      this.tableFrom.userId = "";
      this.userVisible = false;
    },
    usersubmit: function usersubmit() {
      this.userVisible = false;
    },
    search: function search() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 10;
      this.getlist();
    },
    reset: function reset() {
      this.tableFrom = {
        userId: "",
        startTime: "",
        endTime: "",
        page: 1,
        limit: 10,
        dataFactoryId: "",
        dataFactoryFundType: ""
        // accountType: this.accountTypeDict[0].dictValue
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this2 = this;
      this.listLoading = true;
      walletBillDataField(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getlist();
    }
  }
};