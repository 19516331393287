"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reportQuery = require("@/api/reportQuery");
var _transaction = require("@/api/transaction");
var _index = _interopRequireDefault(require("@/components/UserSearchable/index.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    UserSearchable: _index.default
  },
  data: function data() {
    return {
      billTypeDict: [],
      inOutTypeDict: [],
      labelPosition: 'right',
      from: {
        accountType: "",
        userName: '',
        billType: '',
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      accountTypeDict: [],
      balanceBillType: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    // this.getlist()
    //获取字典数据
    (0, _transaction.getDictData)('yj_trader_type').then(function (res) {
      console.log('账户类型字典数据：', res);
      _this.accountTypeDict = res;
      _this.from.accountType = res[0].dictValue;
      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });
    //获取字典数据
    (0, _transaction.getDictData)("balance_bill_type").then(function (res) {
      console.log("类型字典数据：", res);
      _this.balanceBillType = res;
      _this.getlist();
    }).catch(function (res) {
      console.log(res);
    });
    (0, _transaction.getDictData)('bill_type').then(function (res) {
      console.log('业务类型字典数据：', res);
      _this.billTypeDict = res;
    }).catch(function (res) {
      console.log(res);
    });
    (0, _transaction.getDictData)('in_out_type').then(function (res) {
      console.log('余额操作类型字典数据：', res);
      _this.inOutTypeDict = res;
    }).catch(function (res) {
      console.log(res);
    });
  },
  filters: {
    billTypeText: function billTypeText(type, billTypeDict) {
      return billTypeDict.filter(function (v) {
        return v.dictValue == type;
      })[0].dictLabel;
    },
    inOutTypeText: function inOutTypeText(type, dict) {
      return dict.filter(function (v) {
        return v.dictValue == type;
      })[0].dictLabel;
    }
  },
  methods: {
    exportTable: function exportTable() {
      var _this2 = this;
      (0, _reportQuery.reportChangeExport)(this.from).then(function (res) {
        console.log('导出表格', res);
        window.open(res.filename);
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err);
      });
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        accountType: this.accountTypeDict[0].dictValue,
        userName: '',
        billType: '',
        beginTime: '',
        endTime: '',
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this3 = this;
      this.loading = true;
      (0, _reportQuery.reportGetChange)(this.from).then(function (res) {
        console.log(res);
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};