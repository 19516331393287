"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelTradeOrderApi = cancelTradeOrderApi;
exports.getGiveAmount = getGiveAmount;
exports.getGiveAmountExport = getGiveAmountExport;
exports.reportChangeExport = reportChangeExport;
exports.reportClosingReport = reportClosingReport;
exports.reportClosingReportExport = reportClosingReportExport;
exports.reportGetChange = reportGetChange;
exports.reportGetTpsl = reportGetTpsl;
exports.reportGetUserCapital = reportGetUserCapital;
exports.reportGetUserOrder = reportGetUserOrder;
exports.reportGetUserPosition = reportGetUserPosition;
exports.reportGetUserReport = reportGetUserReport;
exports.reportGetUserTradeList = reportGetUserTradeList;
exports.reportReportExit = reportReportExit;
exports.reportTodayBalanceBill = reportTodayBalanceBill;
exports.reportTodayBalanceBillExport = reportTodayBalanceBillExport;
exports.reportTodayUserOrder = reportTodayUserOrder;
exports.reportTodayUserOrderExport = reportTodayUserOrderExport;
exports.reportTpslExport = reportTpslExport;
exports.reportUserCapitalExport = reportUserCapitalExport;
exports.reportUserOrderExport = reportUserOrderExport;
exports.reportUserPositionExport = reportUserPositionExport;
exports.reportUserReportExport = reportUserReportExport;
exports.reportUserTradeListExport = reportUserTradeListExport;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// 报表查询
/*
 * 账户统计
 */
function reportGetUserReport(params) {
  return (0, _request.default)({
    url: "/report/getUserReport",
    method: 'get',
    params: params
  });
}
/*
 * 账户统计导出
 */
function reportUserReportExport(params) {
  return (0, _request.default)({
    url: "/report/userReportExport",
    method: 'get',
    params: params
  });
}
/*
 * 资金统计
 */
function reportGetUserCapital(params) {
  return (0, _request.default)({
    url: "/report/getUserCapital",
    method: 'get',
    params: params
  });
}
/*
 * 资金统计导出
 */
function reportUserCapitalExport(params) {
  return (0, _request.default)({
    url: "/report/userCapitalExport",
    method: 'get',
    params: params
  });
}
/*
 *持仓列表
 */
function reportGetUserPosition(params) {
  return (0, _request.default)({
    url: "/report/getUserPosition",
    method: 'get',
    params: params
  });
}
/*
 *持仓列表导出
 */
function reportUserPositionExport(params) {
  return (0, _request.default)({
    url: "/report/userPositionExport",
    method: 'get',
    params: params
  });
}
/*
 *持仓平仓
 */
function reportReportExit(data) {
  return (0, _request.default)({
    url: "/report/reportExit",
    method: 'post',
    data: data
  });
}
/*
 *成交列表
 */
function reportGetUserTradeList(params) {
  return (0, _request.default)({
    url: "/report/getUserTradeList",
    method: 'get',
    params: params
  });
}
/*
 *成交列表导出
 */
function reportUserTradeListExport(params) {
  return (0, _request.default)({
    url: "/report/userTradeListExport",
    method: 'get',
    params: params
  });
}
/*
 *平盈列表
 */
function reportClosingReport(params) {
  return (0, _request.default)({
    url: "/report/closingReport",
    method: 'get',
    params: params
  });
}
/*
 *平盈列表导出
 */
function reportClosingReportExport(params) {
  return (0, _request.default)({
    url: "/report/closingReportExport",
    method: 'get',
    params: params
  });
}
/*
 *额度变动
 */
function reportTodayBalanceBill(params) {
  return (0, _request.default)({
    url: "/report/todayBalanceBill",
    method: 'get',
    params: params
  });
}
/*
 *额度变动导出
 */
function reportTodayBalanceBillExport(params) {
  return (0, _request.default)({
    url: "/report/todayBalanceBillExport",
    method: 'get',
    params: params
  });
}
/*
 *历史额度变动
 */
function reportGetChange(params) {
  return (0, _request.default)({
    url: "/report/getChange",
    method: 'get',
    params: params
  });
}
/*
 *历史额度变动导出
 */
function reportChangeExport(params) {
  return (0, _request.default)({
    url: "/report/changeExport",
    method: 'get',
    params: params
  });
}
/*
 *委托列表
 */
function reportTodayUserOrder(params) {
  return (0, _request.default)({
    url: "/report/todayUserOrder",
    method: 'get',
    params: params
  });
}
/*
 *委托列表导出
 */
function reportTodayUserOrderExport(params) {
  return (0, _request.default)({
    url: "/report/todayUserOrderExport",
    method: 'get',
    params: params
  });
}
/*
 *历史委托
 */
function reportGetUserOrder(params) {
  return (0, _request.default)({
    url: "/report/getUserOrder",
    method: 'get',
    params: params
  });
}
/*
 *历史委托导出
 */
function reportUserOrderExport(params) {
  return (0, _request.default)({
    url: "/report/userOrderExport",
    method: 'get',
    params: params
  });
}
/*
 *止盈止损
 */
function reportGetTpsl(params) {
  return (0, _request.default)({
    url: "/report/getTpsl",
    method: 'get',
    params: params
  });
}
/*
 *止盈止损导出
 */
function reportTpslExport(params) {
  return (0, _request.default)({
    url: "/report/tpslExport",
    method: 'get',
    params: params
  });
}

/*
 *委托列表撤单
 */
function cancelTradeOrderApi(data) {
  return (0, _request.default)({
    url: "/report/cancelTradeOrder",
    method: 'post',
    data: data
  });
}

/*
 *获取赠送额度列表
 */
function getGiveAmount(params) {
  return (0, _request.default)({
    url: "/report/giveAmount",
    method: 'get',
    params: params
  });
}

/*
 *赠送额度导出列表
 */
function getGiveAmountExport(params) {
  return (0, _request.default)({
    url: "/report/giveAmountExport",
    method: 'get',
    params: params
  });
}