export default {
  'list-type': function listType(h, conf, key) {
    var list = [];
    var config = conf.__config__;
    if (conf['list-type'] === 'picture-card') {
      list.push(h("i", {
        "class": 'el-icon-plus'
      }));
    } else {
      list.push(h("el-button", {
        "attrs": {
          "size": 'small',
          "type": 'primary',
          "icon": 'el-icon-upload'
        }
      }, [config.buttonText]));
    }
    if (config.showTip) {
      list.push(h("div", {
        "slot": 'tip',
        "class": 'el-upload__tip'
      }, ["\u53EA\u80FD\u4E0A\u4F20\u4E0D\u8D85\u8FC7 ", config.fileSize, config.sizeUnit, " \u7684", conf.accept, "\u6587\u4EF6"]));
    }
    return list;
  }
};