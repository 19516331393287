import Vue from 'vue';
export default {}.install = function (Vue) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  Vue.directive('loadmore', {
    inserted: function inserted(el, binding) {
      // 获取element-ui定义好的scroll盒子
      var SELECTDOWN_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
      SELECTDOWN_DOM.addEventListener('scroll', function () {
        var CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight;
        if (CONDITION) {
          binding.value();
        }
      });
    }
  });
};