var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "ivu-mt", attrs: { bordered: false, shadow: "never" } },
        [
          _c(
            "el-row",
            { staticClass: "mb20" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: { click: _vm.add },
                    },
                    [_vm._v("发布版本")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                border: "",
                data: _vm.tableList,
                "highlight-current-row": "",
                "no-userFrom-text": "暂无数据",
                "no-filtered-userFrom-text": "暂无筛选结果",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "版本号", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isNew == 1
                          ? _c("i", {
                              staticClass: "el-icon-s-promotion",
                              staticStyle: {
                                "font-size": "16px",
                                color: "red",
                              },
                            })
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(scope.row.version))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "平台类型", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.platform == 1
                          ? _c("span", [_vm._v("Android")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.platform == 2
                          ? _c("span", [_vm._v("IOS")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.platform == 3
                          ? _c("span", [_vm._v("PC")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.platform == 7
                          ? _c("span", [_vm._v("PCAdmin")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否强制", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.isForce == 1 ? "强制" : "非强制")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "下载地址", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.url))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "升级信息", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.info))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发布日期", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDel(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.ruleForm.id ? "编辑版本信息" : "新增版本信息",
            visible: _vm.dialogVisible,
            width: "700px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.ruleForm,
                "label-width": "180px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "版本号：", prop: "version" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "version", $$v)
                      },
                      expression: "ruleForm.version",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否为线上最新版本：", prop: "isNew" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.isNew,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isNew", $$v)
                        },
                        expression: "ruleForm.isNew",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "平台类型：", prop: "platform" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.platform,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "platform", $$v)
                        },
                        expression: "ruleForm.platform",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("Android"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("IOS")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("PC")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 7 } }, [
                        _vm._v("PCAdmin"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否强制更新：", prop: "isForce" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.isForce,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isForce", $$v)
                        },
                        expression: "ruleForm.isForce",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "上传安装包：" } }, [
                _c(
                  "div",
                  { staticClass: "upload-container" },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo mr10 mb15",
                        attrs: {
                          action: "",
                          "http-request": _vm.handleUploadForm,
                          headers: _vm.myHeaders,
                          "show-file-list": false,
                          multiple: "",
                        },
                      },
                      [
                        _vm.ruleForm.url
                          ? _c("div", { staticClass: "upLoadPicBox" }, [
                              _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass:
                                    "el-icon-document-checked cameraIconfont",
                                }),
                              ]),
                            ])
                          : _c(
                              "el-button",
                              { attrs: { size: "mini", type: "primary" } },
                              [_vm._v("点击上传")]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下载链接：", prop: "url" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "url", $$v)
                      },
                      expression: "ruleForm.url",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "升级信息：", prop: "info" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.ruleForm.info,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "info", $$v)
                      },
                      expression: "ruleForm.info",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }