//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { userLevelUpdateApi } from '@/api/user';
import { Debounce } from '@/utils/validate';
export default {
  props: {
    levelInfo: {
      type: Object,
      default: {}
    },
    levelList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      grade: '',
      levelStatus: false,
      ruleForm: {
        isSub: false,
        levelId: "",
        uid: this.levelInfo.uid
      }
    };
  },
  created: function created() {
    this.ruleForm.levelId = this.levelInfo.level ? Number(this.levelInfo.level) : '';
  },
  watch: {
    levelInfo: function levelInfo(val) {
      this.ruleForm.uid = val.uid || 0;
      this.ruleForm.levelId = this.levelInfo.level ? Number(this.levelInfo.level) : val.levelId;
    }
  },
  methods: {
    submitForm: Debounce(function (formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          userLevelUpdateApi(_this.ruleForm).then(function (res) {
            _this.$message.success('编辑成功');
            _this.$parent.$parent.getList();
            _this.$parent.$parent.levelVisible = false;
            _this.$refs[formName].resetFields();
            _this.grade = '';
          });
        } else {
          return false;
        }
      });
    }),
    currentSel: function currentSel() {
      var _this2 = this;
      this.levelList.forEach(function (item) {
        if (item.id == _this2.ruleForm.levelId) {
          _this2.grade = item.grade;
        }
      });
    },
    resetForm: function resetForm(formName) {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.$refs[formName].resetFields();
        _this3.grade = '';
      });
      this.$parent.$parent.levelVisible = false;
    }
  }
};