var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "welcome" }, [
    _vm.isProdEnv
      ? _c("img", {
          staticClass: "welcome-img",
          attrs: { src: require("@/assets/imgs/welcome2.jpg"), alt: "logo" },
        })
      : _c("img", {
          staticClass: "welcome-img",
          attrs: { src: require("@/assets/imgs/_welcome.jpg"), alt: "logo" },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }