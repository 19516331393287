var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "dow_index",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "con" }, [
        _c(
          "div",
          {
            staticClass: "con-item",
            on: {
              click: function ($event) {
                return _vm.handleto(_vm.android)
              },
            },
          },
          [
            _c("img", {
              staticClass: "con-img",
              attrs: { src: require("@/assets/imgs/and.png"), alt: "" },
            }),
            _vm._v(" "),
            _vm._m(1),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "con-item",
            on: {
              click: function ($event) {
                return _vm.handleto(_vm.pc)
              },
            },
          },
          [
            _c("img", {
              staticClass: "con-img",
              attrs: { src: require("@/assets/imgs/pclogo.png"), alt: "" },
            }),
            _vm._v(" "),
            _vm._m(2),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hea" }, [
      _c("img", {
        staticClass: "hea-img",
        attrs: { src: require("@/assets/imgs/Banner.jpg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "con-t" }, [
      _c("span", { staticClass: "con-t1" }, [_vm._v("安卓手机")]),
      _vm._v(" "),
      _c("span", { staticClass: "con-t2" }, [_vm._v("APP下载")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "con-t" }, [
      _c("span", { staticClass: "con-t1" }, [_vm._v("PC客户端")]),
      _vm._v(" "),
      _c("span", { staticClass: "con-t2" }, [_vm._v("下载")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }