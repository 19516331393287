"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UserSearchable = _interopRequireDefault(require("@/components/UserSearchable"));
var _dataField = require("@/api/dataField");
var _user = require("@/api/user");
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
var _auth = require("@/utils/auth");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'notice',
  components: {
    UserSearchable: _UserSearchable.default
  },
  data: function data() {
    var url = _settingMer.default.httpUrl + '/upload/image/0/file?ueditor=1&token=' + (0, _auth.getToken)();
    return {
      myConfig: {
        autoHeightEnabled: false,
        // 编辑器不自动被内容撑高
        initialFrameHeight: 500,
        // 初始容器高度
        initialFrameWidth: '100%',
        // 初始容器宽度
        elementPathEnabled: false,
        wordCount: false,
        enableAutoSave: false,
        UEDITOR_HOME_URL: '/UEditor/',
        'serverUrl': url,
        'imageUrl': url,
        'imageFieldName': 'file',
        imageUrlPrefix: '',
        'imageActionName': 'upfile',
        'imageMaxSize': 2048000,
        'imageAllowFiles': ['.png', '.jpg', '.jpeg', '.gif', '.bmp']
      },
      labelPosition: 'right',
      from: {
        title: '',
        sendStatus: '',
        page: 1,
        limit: 15
      },
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      selection: [],
      dialogVisible: false,
      ruleForm: {
        id: '',
        title: '',
        bannerTime: '',
        msgType: '',
        bannerFlag: 2,
        userIds: [],
        msgContent: '',
        tenantIds: [],
        userName: null
      },
      users: [],
      currentPage: 1,
      pageSize: 15,
      rules: {
        tenantIds: [{
          required: true,
          message: '请选择租户',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: '请填写标题',
          trigger: 'blur'
        }],
        priority: [{
          required: true,
          message: '请选择优先级',
          trigger: 'change'
        }],
        msgType: [{
          required: true,
          message: '请选择通告对象',
          trigger: 'change'
        }],
        msgContent: [{
          required: true,
          message: '请填写内容',
          trigger: 'blur'
        }]
      },
      detaildialogVisible: false,
      detailFrom: {
        id: '',
        title: '',
        msgType: '',
        userIds: [],
        bannerTime: '',
        bannerFlag: '',
        msgContent: ''
      }
    };
  },
  computed: {
    tenantId: function tenantId() {
      return this.$store.getters.tenantId;
    }
  },
  mounted: function mounted() {
    this.getlist();
  },
  methods: {
    handleDetail: function handleDetail(obj) {
      var that = this;
      that.detailFrom = obj;
      that.detaildialogVisible = true;
      console.log(obj);
    },
    // 添加自定义弹窗
    addCustomDialog: function addCustomDialog(editorId) {
      window.UE.registerUI('test-dialog', function (editor, uiName) {
        // 创建 dialog
        var dialog = new window.UE.ui.Dialog({
          // 指定弹出层中页面的路径，这里只能支持页面，路径参考常见问题 2
          iframeUrl: '/setting/uploadPicture?field=dialog',
          // 需要指定当前的编辑器实例
          editor: editor,
          // 指定 dialog 的名字
          name: uiName,
          // dialog 的标题
          title: '上传图片',
          // 指定 dialog 的外围样式
          cssRules: 'width:1000px;height:620px;padding:20px;'
        });
        this.dialog = dialog;
        var btn = new window.UE.ui.Button({
          name: 'dialog-button',
          title: '上传图片',
          cssRules: "background-image: url(@/assets/images/icons.png);background-position: -726px -77px;",
          onclick: function onclick() {
            // 渲染dialog
            dialog.render();
            dialog.open();
          }
        });
        return btn;
      }, 37);
    },
    loadUsers: function loadUsers(query) {
      console.log(query);
      if (query !== '') {
        this.currentPage = 1;
        this.users = [];
        this.getuserlist(query);
      } else {
        this.users = [];
      }
    },
    getuserlist: function getuserlist(query) {
      var _this = this;
      var data = {
        page: this.currentPage,
        limit: this.pageSize,
        keywords: query
      };
      (0, _user.userListApi)(data).then(function (res) {
        console.log('用户列表', res);
        _this.users = _this.users.concat(res.list);
      }).catch(function (err) {
        console.log(err);
      });
    },
    search: function search() {
      this.from.page = 1;
      this.from.limit = 15;
      this.getlist();
    },
    reset: function reset() {
      this.from = {
        title: '',
        sendStatus: '',
        page: 1,
        limit: 15
      };
      this.getlist();
    },
    getlist: function getlist() {
      var _this2 = this;
      this.loading = true;
      (0, _dataField.annountCementList)(this.from).then(function (res) {
        console.log(res);
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this2.loading = false;
      });
    },
    add: function add() {
      this.ruleForm = {
        id: '',
        title: '',
        msgType: '',
        priority: '',
        bannerTime: '',
        bannerFlag: 2,
        userIds: [],
        msgContent: ''
      };
      this.dialogVisible = true;
    },
    close: function close() {
      this.ruleForm = {
        id: '',
        title: '',
        msgType: '',
        priority: '',
        bannerTime: '',
        bannerFlag: 2,
        userIds: [],
        msgContent: ''
      };
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          if (_this3.ruleForm.id) {
            console.log('编辑', _this3.ruleForm);
            var userIds = _this3.ruleForm.userIds.join();
            var data = {
              id: _this3.ruleForm.id,
              title: _this3.ruleForm.title,
              msgType: _this3.ruleForm.msgType,
              bannerFlag: _this3.ruleForm.bannerFlag,
              bannerTime: _this3.ruleForm.bannerTime,
              priority: _this3.ruleForm.priority,
              userIds: userIds,
              msgContent: _this3.ruleForm.msgContent
              // tenantIds: this.ruleForm.tenantIds
            };
            (0, _dataField.annountCementEdit)(data).then(function (res) {
              console.log(res);
              _this3.close();
              _this3.$message.success('编辑成功');
              _this3.getlist();
            }).catch(function (err) {
              console.log(err);
              _this3.$message.error(err);
            });
          } else {
            console.log('新增', _this3.ruleForm.userIds);
            var _userIds = _this3.ruleForm.userIds.join();
            var _data = {
              title: _this3.ruleForm.title,
              msgType: _this3.ruleForm.msgType,
              priority: _this3.ruleForm.priority,
              bannerFlag: _this3.ruleForm.bannerFlag,
              bannerTime: _this3.ruleForm.bannerTime,
              userIds: _userIds,
              msgContent: _this3.ruleForm.msgContent,
              tenantIds: _this3.ruleForm.tenantIds
            };
            (0, _dataField.annountCementAdd)(_data).then(function (res) {
              console.log(res);
              _this3.close();
              _this3.$message.success('新增成功');
              _this3.getlist();
            }).catch(function (err) {
              console.log(err);
              _this3.$message.error(err);
            });
          }
        } else {
          _this3.$message.error('请填写完整信息');
        }
      });
    },
    // 选择
    onSelectTab: function onSelectTab(selection) {
      var _this4 = this;
      this.selection = [];
      selection.forEach(function (item) {
        _this4.selection.push(item.id);
      });
      console.log(this.selection);
    },
    batchPublish: function batchPublish() {
      var _this5 = this;
      var canPublish = true;
      this.tableData.data.forEach(function (v) {
        _this5.selection.forEach(function (o) {
          if (v.id == o) {
            if (v.sendStatus == 1) {
              canPublish = false;
            }
          }
        });
      });
      if (!canPublish) return this.$message.error('请勿勾选已发布的通知');
      this.$confirm('确认发布？', '提示').then(function (_) {
        (0, _dataField.annountCementDoReleaseData)(_this5.selection).then(function (res) {
          console.log(res);
          _this5.$message.success('发布成功');
          _this5.getlist();
        }).catch(function (err) {
          console.log(err);
          _this5.$message.error(err);
        });
      }).catch(function (_) {});
    },
    allDel: function allDel() {
      var _this6 = this;
      if (this.selection.length == 0) {
        return this.$message.error('至少选择一项');
      }
      console.log(this.selection);
      this.$confirm('确认删除？', '提示').then(function (_) {
        (0, _dataField.annountCementDelete)(_this6.selection).then(function (res) {
          console.log(res);
          _this6.$message.success('删除成功');
          _this6.getlist();
        }).catch(function (err) {
          console.log(err);
          _this6.$message.error(err);
        });
      }).catch(function (_) {});
    },
    handleEdit: function handleEdit(row) {
      var _this7 = this;
      // getAnnountCementQueryById
      console.log('当前点击：', row);
      (0, _dataField.getAnnountCementQueryById)(row.id).then(function (res) {
        console.log('系统通告详情', res);
        _this7.ruleForm = {
          id: res.id,
          title: res.title,
          msgType: res.msgType,
          priority: res.priority,
          userIds: res.userIds ? res.userIds.split(",") : [],
          msgContent: res.msgContent
        };
        _this7.users = res.userList;
        console.log(_this7.ruleForm, _this7.users);
        _this7.dialogVisible = true;
      }).catch(function (err) {
        console.log(err);
        _this7.$message.error(err);
      });
      return;
      (0, _dataField.annountCementQueryById)(row).then(function (res) {
        console.log('系统通告详情', res);
        _this7.ruleForm = {
          id: res.id,
          title: res.title,
          msgType: res.msgType,
          priority: res.priority,
          userIds: res.userIds ? res.userIds.split(",") : [],
          msgContent: res.msgContent
        };
        _this7.users = res.userList;
        console.log(_this7.ruleForm, _this7.users);
        _this7.dialogVisible = true;
      }).catch(function (err) {
        console.log(err);
        _this7.$message.error(err);
      });
    },
    handleRelease: function handleRelease(row) {
      var _this8 = this;
      this.$confirm('确认发布？', '提示').then(function (_) {
        console.log('发布', row);
        (0, _dataField.annountCementDoReleaseData)([row.id]).then(function (res) {
          console.log(res);
          _this8.$message.success('发布成功');
          _this8.getlist();
        }).catch(function (err) {
          console.log(err);
          _this8.$message.error(err);
        });
      }).catch(function (_) {});
    },
    handleDel: function handleDel(row) {
      var _this9 = this;
      this.$confirm('确认删除？', '提示').then(function (_) {
        console.log('删除', row);
        (0, _dataField.annountCementDelete)([row.id]).then(function (res) {
          console.log(res);
          _this9.$message.success('删除成功');
          _this9.getlist();
        }).catch(function (err) {
          console.log(err);
          _this9.$message.error(err);
        });
      }).catch(function (_) {});
    },
    pageChange: function pageChange(page) {
      this.from.page = page;
      this.getlist();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.from.limit = val;
      this.getlist();
    }
  }
};