export default {
  prepend: function prepend(h, conf, key) {
    return h("template", {
      "slot": 'prepend'
    }, [conf.__slot__[key]]);
  },
  append: function append(h, conf, key) {
    return h("template", {
      "slot": 'append'
    }, [conf.__slot__[key]]);
  }
};