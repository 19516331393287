//
//
//
//
//
//
//
//
//
//
//
//

import { auth, oAuth } from "@/libs/wechat";
import Cookies from 'js-cookie';
var WX_AUTH = "wx_auth";
export default {
  name: 'AuthSend',
  created: function created() {
    import('@/assets/js/media_750');
    // const hash = window.location.search.slice(1)
    // if (window.localStorage) {
    //   window.localStorage.setItem('x-admin-oauth-code', hash)
    //   window.close()
    // }
    Cookies.set(WX_AUTH, this.$route.query.code);
  },
  render: function render(h) {
    return h(); // avoid warning message
  },
  mounted: function mounted() {
    if (this.$route.query.code) location.replace("/login");
    // oAuth('/auth-send')
    //  if( Cookies.get(WX_AUTH)!==undefined) location.replace("/login");
  }
};