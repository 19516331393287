var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "divBox" },
      [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("span", [_vm._v("数据场：")]),
            _vm._v(" "),
            _c("UserSearchable", {
              attrs: { type: "datafieldList" },
              scopedSlots: _vm._u([
                {
                  key: "dropdownTitle",
                  fn: function () {
                    return [
                      _c("span", [_vm._v("数据场名称")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("负责人")]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "dropdown",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.principal))]),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.form.dataFactoryId,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "dataFactoryId", $$v)
                },
                expression: "form.dataFactoryId",
              },
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.GetReportStatistics },
              },
              [_vm._v("搜索")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          { staticClass: "baseInfo", attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
              [
                _c(
                  "el-card",
                  { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                  [
                    _c("div", { staticClass: "acea-row row-between-wrapper" }, [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("div", { staticClass: "main_badge" }, [
                          _c("span", { staticClass: "iconfont iconxiaoshoue" }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v("资管余额当日增加"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm.increaseBalance != ""
                        ? _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [
                              _vm._v(
                                _vm._s(
                                  parseFloat(_vm.increaseBalance)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                )
                              ),
                            ]
                          )
                        : _vm.increaseBalance == 0
                        ? _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v("0.00")]
                          )
                        : _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v("正在加载中...")]
                          ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
              [
                _c(
                  "el-card",
                  { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                  [
                    _c("div", { staticClass: "acea-row row-between-wrapper" }, [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("div", { staticClass: "main_badge" }, [
                          _c("span", { staticClass: "iconfont iconxiaoshoue" }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v("资管余额当日减少"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm.reduceBalance != ""
                        ? _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [
                              _vm._v(
                                _vm._s(
                                  parseFloat(_vm.reduceBalance)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                )
                              ),
                            ]
                          )
                        : _vm.reduceBalance == 0
                        ? _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v("0.00")]
                          )
                        : _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v("正在加载中...")]
                          ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
              [
                _c(
                  "el-card",
                  { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                  [
                    _c("div", { staticClass: "acea-row row-between-wrapper" }, [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("div", { staticClass: "main_badge" }, [
                          _c("span", { staticClass: "iconfont iconxiaoshoue" }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v("资管余额"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm.totalBalance != ""
                        ? _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [
                              _vm._v(
                                _vm._s(
                                  parseFloat(_vm.totalBalance)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                )
                              ),
                            ]
                          )
                        : _vm.totalBalance == 0
                        ? _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v("0.00")]
                          )
                        : _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v("正在加载中...")]
                          ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
              [
                _c(
                  "el-card",
                  { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                  [
                    _c("div", { staticClass: "acea-row row-between-wrapper" }, [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("div", { staticClass: "main_badge" }, [
                          _c("span", { staticClass: "iconfont iconxiaoshoue" }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v("概率宝余额"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm.glbTotalBalance != ""
                        ? _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [
                              _vm._v(
                                _vm._s(
                                  parseFloat(_vm.glbTotalBalance)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                )
                              ),
                            ]
                          )
                        : _vm.glbTotalBalance == 0
                        ? _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v("0.00")]
                          )
                        : _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v("正在加载中...")]
                          ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }